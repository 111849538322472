import React from "react"
import StatsRow from "../stats-row/stats-row"
import {useAppSelector} from "../../../core/hooks"
import {getPlayersStats} from "../../../core/store/players-stats-data/selectors"
import Stub from "../stub/stub"

function StatsTable() {
  const playersStats = useAppSelector(getPlayersStats)

  return (
    <div className="blocks__container blocks__container--start">
      <div className={`table-scroll table-scroll--border ${playersStats.length === 0 ? 'hidden' : ''}`}>
        <div className="table-border table-border--400">
          <table className="table">
            <thead className="table__thead">
            <tr className="table__row">
              <th className="table__th table__start table__auto">разряд</th>
              <th className="table__th table__121">матчи</th>
              <th className="table__th table__121">кпд игрока</th>
              <th className="table__th table__121">победы</th>
              <th className="table__th table__121">поражения</th>
            </tr>
            </thead>
            <tbody className="table__tbody">
            {playersStats.map((playerStats) => (
              <StatsRow playerStats={playerStats} key={playerStats.player_id}/>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <Stub type={playersStats.length === 0 ? "absencePlayer" : ""}/>
    </div>
  )
}

export default StatsTable

