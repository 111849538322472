import { Link } from "react-router-dom";
import React from "react";

function Proffer({
  onRemove,
  onAdd,
  disableRemove,
}: {
  onRemove: () => void;
  onAdd: () => void;
  disableRemove: boolean;
}) {
  return (
    <div className="proffer">
      {!disableRemove && (
        <Link to="#" className="proffer__link" onClick={() => onRemove()}>
          <p className="proffer__text">Удалить игрока</p>
          <svg className="proffer__icon" width="30" height="30">
            <use xlinkHref="images/sprite.svg#delete" />
          </svg>
        </Link>
      )}
      <Link to="#" className="proffer__link" onClick={() => onAdd()}>
        <p className="proffer__text">Добавить игрока</p>
        <svg className="proffer__icon" width="30" height="30">
          <use xlinkHref="images/sprite.svg#add" />
        </svg>
      </Link>
    </div>
  );
}

export default Proffer;
