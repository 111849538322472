export enum SelectPlaceholders {
  DesireRoles = "Выберите из списка желаемую роль",
  Cities = "Выберите город",
  Zones = "Выберите зону",
  Gender = "Выберите пол",
}

export enum SelectTypes {
  DesireRoles = "desireRole",
  Cities = "cities",
  Zones = "zone_id",
  Gender = "gender",
  Undefined = "undefined",
}
