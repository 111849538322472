import React, {useState} from "react";
import ButtonsItem from "../buttons-item/buttons-item";
import classNames from "classnames";
import {LinkType} from "../../../core/types/common";

function ButtonsList({buttonsData, className, parentPath}: {
  buttonsData: LinkType[],
  className?: string,
  parentPath?: string
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ul className={classNames(`buttons ${className || ""}`, {"buttons--open": isOpen})} onClick={handleButtonClick}>
      {buttonsData.map((buttonData) => (
        <ButtonsItem buttonData={buttonData} key={buttonData.url} parentPath={parentPath}/>
      ))}
    </ul>
  );
}

export default ButtonsList;
