import React, {useEffect} from 'react';
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import MatchesHead from "../../components/ordinary/matches-head/matches-head";
import GameList from "../../components/smart/game-list/game-list";
import {useParams} from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {fetchMatchSeries} from "../../core/store/api-actions";
import {
  getMatchSeries,
  getMatchSeriesErrorStatus,
  getMatchSeriesLoadingStatus
} from "../../core/store/match-series-data/selectors";
import {AppRoute} from "../../core/constants/routes";
import {
  ApiTournamentsIdRoundsGetTypeEnum,
  TournamentTypeEnum
} from "../../api";
import {filterRoundsByTypes} from "../../core/utils/array-prepare-helpers";
import Stub from "../../components/ordinary/stub/stub";
import {
  getMatchesErrorStatus,
  getMatchesLoadingStatus
} from "../../core/store/matches-data/selectors";

function MatchPage() {
  const dispatch = useAppDispatch();
  const {id: id} = useParams();
  const matchSeries = useAppSelector(getMatchSeries);
  const matchSeriesLoading = useAppSelector(getMatchSeriesLoadingStatus);
  const matchSeriesError = useAppSelector(getMatchSeriesErrorStatus);
  const matchesLoading = useAppSelector(getMatchesLoadingStatus);
  const matchesError = useAppSelector(getMatchesErrorStatus);
  const matchPageLoading = matchSeriesLoading || matchesLoading;
  const matchPageError = matchSeriesError || matchesError;
  const lapRounds  = filterRoundsByTypes(matchSeries?.tournament?.rounds, [ApiTournamentsIdRoundsGetTypeEnum.RoundRobinOne, ApiTournamentsIdRoundsGetTypeEnum.RoundRobinTwo]);

  const crumbsRoutes = [
    AppRoute.Main,
    matchSeries?.tournament?.type === TournamentTypeEnum.Commercial ? AppRoute.TournamentsPage : AppRoute.LeaguePage,
    {
      title: matchSeries?.tournament?.full_name ||'',
      url: `${lapRounds.length > 0 ? AppRoute.RatingPage.url : AppRoute.ChessPage.url}/${matchSeries?.tournament?.tournament_id}`
    },
    {
      title: `Матч ${matchSeries?.team1?.full_name}-${matchSeries?.team2?.full_name}`,
      url: '#'
    }
  ];

  useEffect(() => {
    dispatch(fetchMatchSeries({
      matchSeriesId: Number(id),
      _with: ["matches", "team1", "team2", "tournament"]
    }));
  }, [dispatch, id])

  return (
    <div className="container">
      <div className={`gap gap--25 ${matchPageLoading || matchPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes}/>
        <div className="gap gap--0">
          <MatchesHead series={matchSeries}/>
          <GameList/>
        </div>
      </div>
      <Stub
        type={matchPageError ? "error": ""}
        error={matchPageError ? "Произошла ошибка при загрузке протокола матча." : ""}
      />
    </div>
  );
}

export default MatchPage;
