import React, {
  useEffect,
  useState
} from "react";
import {Link} from "react-router-dom";
import ModalRegistrationList from "../modal-registration-list/modal-registration-list";
import {useFormik} from "formik";
import {registrationFormValidationSchema} from "../../../core/utils/form-validate-helpers";
import ReCAPTCHA from "react-google-recaptcha";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {registration} from "../../../core/store/api-actions";
import {
  sendErrorText,
  SendRequestStatus
} from "../../../core/constants/common";
import {
  getAuthorizationErrors,
  getAuthorizationSendStatus
} from "../../../core/store/authorization-data/selectors";

function ModalRegistration({hidden}: { hidden: boolean }) {
  const dispatch = useAppDispatch();
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const registrationErrors = useAppSelector(getAuthorizationErrors);
  const registrationSendStatus = useAppSelector(getAuthorizationSendStatus);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (registrationSendStatus === SendRequestStatus.UnSuccess) {
      setErrors((prevErrors) => [...prevErrors, sendErrorText])
    }
  }, [registrationSendStatus])

  useEffect(() => {
    if (registrationErrors?.email) {
      registrationErrors.email.map((error) => {
        setErrors((prevErrors) => [...prevErrors, error]);
      })
    }
    if (registrationErrors?.phone) {
      registrationErrors.phone.map((error) => {
        setErrors((prevErrors) => [...prevErrors, error]);
      })
    }
    if (registrationErrors?.password) {
      registrationErrors.password.map((error) => {
        setErrors((prevErrors) => [...prevErrors, error])
      })
    }
    if (registrationErrors?.reCaptcha) {
      registrationErrors.reCaptcha.map((error) => {
        setErrors((prevErrors) => [...prevErrors, error])
      })
    }
  }, [registrationErrors]);

  const handleReCaptchaChange = (value: string | null) => {
    formik.setFieldValue('reCaptcha', value);
  };

// eslint-disable-next-line
  const onSubmit = async (values: any) => {
    console.log('Форма регистрации отправлена:',values);
    dispatch(registration(values));
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      desireRole: '',
      password: '',
      passwordRepeat: '',
      reCaptcha: null
    },
    validationSchema: registrationFormValidationSchema,
    onSubmit
  });

  return (
    <div className={`modal__background ${hidden ? 'hidden' : ''}`}>
      <svg className="modal__logo" width="2020" height="865">
        <use xlinkHref="images/sprite.svg#background-logo"/>
      </svg>
      <div className="modal__column">
        <ul className="modal__errors">
          {errors.map((error) => (
            <li className="modal__error" key={error}>{error}</li>
          ))}
        </ul>
        <form className="modal__form" onSubmit={formik.handleSubmit}>
          <ModalRegistrationList formik={formik} setErrors={setErrors}/>
          <p className="modal__text">Нажимая кнопку “Зарегистрироваться”, Вы принимаете условия <Link to="#" className="modal__link">Пользовательского соглашения</Link> и <Link to="#" className="modal__link">Политики конфиденциальности</Link></p>
          <div className="gap gap--5">
            <p className={`form__help form__help--center ${formik.errors.reCaptcha ? '' : 'hidden'}`}>{formik.errors.reCaptcha}</p>
            <ReCAPTCHA
              sitekey={siteKey || ''}
              className="modal__recaptcha"
              onChange={handleReCaptchaChange}
              hl="ru"
            />
          </div>
          <button className="modal__button" type="submit">Зарегистрироваться</button>
        </form>
      </div>
    </div>
  );
}

export default ModalRegistration;
