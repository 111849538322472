import React from "react"
import AsideBanners from "../../components/simple/aside-banners/aside-banners"
import {BannerPositionEnum} from "../../api"
import BannerList from "../../components/ordinary/banner-list/banner-list"
import RatingWidgetTable from "../../components/ordinary/rating-widget-table/rating-widget-table"
import MatchesWidget from "../../components/simple/matches-widget/matches-widget"
import NewsWidget from "../../components/simple/news-widget/news-widget"

function MainPage() {
  return (
    <div className="main__container">
      <div className="container-y">
        <div className="gap gap--60">
          <MatchesWidget/>
          <NewsWidget/>
          <BannerList bannerPosition={BannerPositionEnum.MainIndexBottomPage}/>
          <RatingWidgetTable/>
        </div>
      </div>
      <AsideBanners bannerPosition={BannerPositionEnum.SidebarStaticPage}/>
    </div>
  )
}

export default MainPage
