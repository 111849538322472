import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppDispatch,
  State
} from "../types/state.js";
import {
  Player,
  Team,
  Album,
  Banner,
  Post,
  Video,
  Page,
  Tournament,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Social, DefaultApi,
  ApiAlbumsGetWithEnum,
  ApiAlbumsIdGetWithEnum,
  ApiTournamentsIdGetWithEnum,
  HdbkSeason,
  HdbkRegion,
  ApiTournamentsGetTypeEnum,
  ApiTournamentsGetWithEnum,
  HdbkFaq,
  HdbkMainProvision,
  ApiPagesAliasGetWithEnum,
  Round,
  ApiTournamentsIdRoundsGetWithEnum,
  ApiTournamentsIdRoundsGetTypeEnum,
  Match,
  MatchSeries,
  ApiSeriesGetWithEnum,
  ApiMatchesGetWithEnum,
  TeamPlayer,
  ApiUsersLoginPost200Response,
  User,
  ApiUsersRegisterPost200Response,
  ApiUsersDesireRolesGet200ResponseInner,
  RatingTeam,
  RatingPlayer,
  StatsPlayer,
  StatsTeam,
  HdbkCity,
  HdbkZone,
  ApiTeamsCreatePostIsDraftEnum,
  TournamentTemp,
  TeamTemp,
  TeamTempPlayerRequest,
  ApiTeamsCreatePost200Response,
  ApiTournamentsCreatePost200Response,
} from "../../api";
import { AxiosResponse } from "axios";
import { api } from "../api.js";
import {FilterOption} from "../types/common";

export const fetchPlayers = createAsyncThunk<
  Player[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("players/fetchPlayers", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Player[]> = await api.player.apiPlayersGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении игроков:", error);
    throw error;
  }
});

export const fetchPlayer = createAsyncThunk<
  Player,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("players/fetchPlayer", async (playerId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Player> = await api.player.apiPlayersIdGet(playerId);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении игрока с идентификатором ${playerId}`, error);
    throw error;
  }
});

export const fetchTeams = createAsyncThunk<
  Team[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("teams/fetchTeams", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Team[]> = await api.team.apiTeamsGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении команд:", error);
    throw error;
  }
});

export const fetchTeam = createAsyncThunk<
  Team,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("teams/fetchTeam", async (teamId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Team> = await api.team.apiTeamsIdGet(teamId);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении команды с идентификатором ${teamId}`, error);
    throw error;
  }
});

export const fetchTeamPlayers = createAsyncThunk<
  TeamPlayer[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("teamPlayers/fetchTeamPlayers", async (teamId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<TeamPlayer[]> = await api.team.apiTeamsIdPlayersGet(teamId);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении игроков команды с идентификатором ${teamId}`, error);
    throw error;
  }
});

export const fetchPosts = createAsyncThunk<
  Post[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("posts/fetchPosts", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Post[]> = await api.redaction.apiPostsGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении постов:", error);
    throw error;
  }
});

export const fetchPost = createAsyncThunk<
  Post,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("posts/fetchPost", async (postId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Post> = await api.redaction.apiPostsIdGet(
      postId
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении поста с идентификатором ${postId}:`,
      error
    );
    throw error;
  }
});

export const fetchAlbums = createAsyncThunk<
  Album[],
  void,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("albums/fetchAlbums", async (_arg, {extra: api}) => {
  try {
    const response: AxiosResponse<Album[]> = await api.redaction.apiAlbumsGet(
      undefined,
      undefined,
      [ApiAlbumsGetWithEnum.Cover, ApiAlbumsGetWithEnum.Photos]
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении альбомов:", error);
    throw error;
  }
});

export const fetchAlbum = createAsyncThunk<
  Album,
  { albumId: number; _with: ApiAlbumsIdGetWithEnum[] },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("albums/fetchAlbum", async ({ albumId, _with }, { extra: api }) => {
  try {
    const response: AxiosResponse<Album> = await api.redaction.apiAlbumsIdGet(
      albumId,
      _with
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении альбома с идентификатором ${albumId}:`,
      error
    );
    throw error;
  }
});

export const fetchVideos = createAsyncThunk<
  Video[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("videos/fetchVideos", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Video[]> = await api.redaction.apiVideosGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении видео:", error);
    throw error;
  }
});

export const fetchVideo = createAsyncThunk<
  Video,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("videos/fetchVideo", async (videoId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Video> = await api.redaction.apiVideosIdGet(
      videoId
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении видео с идентификатором ${videoId}:`,
      error
    );
    throw error;
  }
});

export const fetchPages = createAsyncThunk<
  Page[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("pages/fetchPages", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Page[]> = await api.redaction.apiPagesGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении страниц:", error);
    throw error;
  }
});

export const fetchPage = createAsyncThunk<
  Page,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("pages/fetchPage", async (pageId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Page> = await api.redaction.apiPagesIdGet(
      pageId
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении страницы с идентификатором ${pageId}:`,
      error
    );
    throw error;
  }
});
export const fetchPageAlias = createAsyncThunk<
  Page,
  {alias:string; _with:ApiPagesAliasGetWithEnum[]},
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("pages/fetchPageAlias", async ({alias, _with}, { extra: api }) => {
  try {
    const response: AxiosResponse<Page> = await api.redaction.apiPagesAliasGet(
      alias, _with
    );
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении страницы с алиасом ${alias}:`, error);
    throw error;
  }
});

export const fetchBanners = createAsyncThunk<
  Banner[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("banners/fetchBanners", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Banner[]> =
      await api.redaction.apiBannersGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении баннеров:", error);
    throw error;
  }
});

export const fetchBanner = createAsyncThunk<
  Banner,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("banners/fetchBanner", async (bannerId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Banner> = await api.redaction.apiBannersIdGet(
      bannerId
    );
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении баннера с идентификатором ${bannerId}:`,
      error
    );
    throw error;
  }
});
export const fetchTournaments = createAsyncThunk<
  Tournament[],
  void,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("tournaments/fetchTournaments", async (_, { extra: api }) => {
  try {
    const response: AxiosResponse<Tournament[]> =
      await api.tournament.apiTournamentsGet(
        undefined,
        undefined,
        ApiTournamentsGetTypeEnum.Commercial,
        [
          ApiTournamentsGetWithEnum.Season,
          ApiTournamentsGetWithEnum.Region,
          ApiTournamentsGetWithEnum.City,
          ApiTournamentsGetWithEnum.Rounds
        ]
      );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении турниров:", error);
    throw error;
  }
});

export const
  fetchTournament = createAsyncThunk<
  Tournament,
  { tournamentId: number; _with: ApiTournamentsIdGetWithEnum[] },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>(
  "tournaments/fetchTournament",
  async ({ tournamentId, _with }, { extra: api }) => {
    try {
      const response: AxiosResponse<Tournament> =
        await api.tournament.apiTournamentsIdGet(tournamentId, _with);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении турнира с идентификатором ${tournamentId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchLeagues = createAsyncThunk<
  Tournament[],
  void,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("tournaments/fetchLeagues", async (_, { extra: api }) => {
  try {
    const response: AxiosResponse<Tournament[]> =
      await api.tournament.apiTournamentsGet(
        undefined,
        undefined,
        ApiTournamentsGetTypeEnum.League,
        [
          ApiTournamentsGetWithEnum.Season,
          ApiTournamentsGetWithEnum.Region,
          ApiTournamentsGetWithEnum.Zone,
          ApiTournamentsGetWithEnum.Rounds
        ]
      );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении лиг:", error);
    throw error;
  }
});

export const fetchSeasons = createAsyncThunk<
  HdbkSeason[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("seasons/fetchSeasons", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkSeason[]> =
      await api.hdbk.apiSeasonsGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении сезонов:", error);
    throw error;
  }
});
export const fetchRegions = createAsyncThunk<
  HdbkRegion[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("seasons/fetchRegions", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkRegion[]> =
      await api.hdbkGeo.apiRegionsGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении регионов:", error);
    throw error;
  }
});

export const fetchFaqs = createAsyncThunk<
  HdbkFaq[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("faqs/fetchFaqs", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkFaq[]> =
      await api.hdbk.apiFaqGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении FAQ:", error);
    throw error;
  }
});

export const fetchProvisions = createAsyncThunk<
  HdbkMainProvision[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("faqs/fetchProvisions", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkMainProvision[]> =
      await api.hdbk.apiProvisionGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении основных положений:", error);
    throw error;
  }
});

// Нет на беке данных
export const fetchSocials = createAsyncThunk<
  Social[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("socials/fetchSocials", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<Social[]> = await api.apiSocialsSiteGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении данных о партнерах:", error);
    throw error;
  }
});

export const fetchSocial = createAsyncThunk<
  Social,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: DefaultApi;
  }
>("socials/fetchSocial", async (socialId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Social> = await api.apiSocialsIdGet(socialId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении данных о социальной сети с идентификатором ${socialId}:`,
      error
    );
    throw error;
  }
});

export const fetchRounds = createAsyncThunk<
  Round[],
  {
    tournamentId: number;
    _with?: ApiTournamentsIdRoundsGetWithEnum[];
    type?: ApiTournamentsIdRoundsGetTypeEnum
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>(
  "rounds/fetchRounds",
  async ({tournamentId, _with, type}, {extra: api}) => {
    try {
      const response: AxiosResponse<Round[]> =
        await api.tournament.apiTournamentsIdRoundsGet(tournamentId, _with, type);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении этапов турнира с идентификатором ${tournamentId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchMatches = createAsyncThunk<
  Match[],
  {
    tournamentId?: number,
    seriesId?: number,
    playerId?: number,
    teamId?: number,
    _with?: ApiMatchesGetWithEnum[]
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>(
  "matches/fetchMatches",
  async ({tournamentId, seriesId, playerId, teamId, _with}, {extra: api}) => {
    try {
      const response: AxiosResponse<Match[]> =
        await api.match.apiMatchesGet(tournamentId, seriesId, playerId, teamId, _with);
      return response.data;
    } catch (error) {
      console.error(
        'Ошибка при получении матчей:',
        error
      );
      throw error;
    }
  }
);

export const fetchMatchSeriesArray = createAsyncThunk<
  MatchSeries[],
  {
    tournamentId?: number,
    playerId?: number,
    teamId?: number,
    _with?: ApiSeriesGetWithEnum[]
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>(
  "matchSeries/fetchMatchSeriesArray",
  async ({tournamentId, playerId, teamId, _with}, {extra: api}) => {
    try {
      const response: AxiosResponse<MatchSeries[]> =
        await api.match.apiSeriesGet(tournamentId, playerId, teamId, _with);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении серий матчей турнира с идентификатором ${tournamentId}:`,
        error
      );
      throw error;
    }
  }
);

export const fetchMatchSeries = createAsyncThunk<
  MatchSeries,
  {
    matchSeriesId: number,
    _with?: ApiSeriesGetWithEnum[]
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>(
  "matchSeries/fetchMatchSeries",
  async ({matchSeriesId, _with}, {extra: api}) => {
    try {
      const response: AxiosResponse<MatchSeries> =
        await api.match.apiSeriesIdGet(matchSeriesId, _with);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении серий матчей с идентификатором ${matchSeriesId}:`,
        error
      );
      throw error;
    }
  }
);

export const login = createAsyncThunk<
  {
    response: ApiUsersLoginPost200Response,
    remember: number;
  },
  {
    email: string;
    password: string;
    remember: number;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("login/login", async (loginObject, { extra: api }) => {
  try {
    const response: AxiosResponse<ApiUsersLoginPost200Response> =
      await api.user.apiUsersLoginPost(loginObject.email, loginObject.password);
    return {
      response: response.data,
      remember: loginObject.remember
    };
  } catch (error) {
    console.error("Ошибка при авторизации:", error);
    throw error;
  }
});

export const registration = createAsyncThunk<
  ApiUsersRegisterPost200Response,
  {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    desireRole: FilterOption;
    password: string;
    passwordRepeat: string
    reCaptcha: string;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("registration/registration", async (registrationObject, { extra: api }) => {
  try {
    const response: AxiosResponse<ApiUsersRegisterPost200Response> =
      await api.user.apiUsersRegisterPost(
        registrationObject.firstName,
        registrationObject.lastName,
        registrationObject.email,
        registrationObject.phone,
        registrationObject.password,
        registrationObject.passwordRepeat,
        registrationObject.reCaptcha,
        Number(registrationObject.desireRole.value),
      );
    return response.data;
  } catch (error) {
    console.error("Ошибка при авторизации:", error);
    throw error;
  }
});

export const fetchUser = createAsyncThunk<
  User,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("users/fetchUser", async (userId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<Social> = await api.user.apiUsersIdGet(userId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении пользователя с идентификатором ${userId}:`,
      error
    );
    throw error;
  }
});

export const fetchDesireRoles = createAsyncThunk<
  ApiUsersDesireRolesGet200ResponseInner[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("desireRoles/fetchDesireRoles", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<ApiUsersDesireRolesGet200ResponseInner[]> = await api.user.apiUsersDesireRolesGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении желаемых ролей", error);
    throw error;
  }
});

export const fetchCities = createAsyncThunk<
  HdbkCity[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("cities/fetchCities", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkCity[]> =
      await api.hdbkGeo.apiCitiesGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении городов:", error);
    throw error;
  }
});

export const fetchZones = createAsyncThunk<
  HdbkZone[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("zones/fetchZones", async (_arg, { extra: api }) => {
  try {
    const response: AxiosResponse<HdbkZone[]> =
      await api.hdbkGeo.apiZonesGet();
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении зон:", error);
    throw error;
  }
});

export const createTeam = createAsyncThunk<
  ApiTeamsCreatePost200Response,
  {
    user_id: number;
    full_name: string;
    club: string;
    zone_id: FilterOption;
    club_address: string;
    city_id: FilterOption;
    court_coverage: string;
    phone?: string;
    email?: string;
    logo?: File;
    partner_logo?: File;
    is_draft?: ApiTeamsCreatePostIsDraftEnum;
    players?: TeamTempPlayerRequest[]
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("team/createTeam", async (createObject, { extra: api }) => {
  try {
    const response:AxiosResponse<ApiTeamsCreatePost200Response> =
      await api.team.apiTeamsCreatePost(
        createObject.user_id,
        createObject.full_name,
        createObject.club,
        createObject.club_address,
        Number(createObject.zone_id.value),
        createObject.court_coverage,
        Number(createObject.city_id.value),
        createObject.phone,
        createObject.email,
        createObject.logo,
        createObject.partner_logo,
        createObject.is_draft,
        createObject.players,
      );
    return response.data
  } catch (error) {
    console.error("Ошибка при подаче заявки на создание команды:", error);
    throw error;
  }
});

export const fetchTournamentsTempsMine = createAsyncThunk<
  TournamentTemp[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("tournaments/fetchTournamentsTempsMine", async (userId: number, { extra: api }) => {
  try {
    const response: AxiosResponse<TournamentTemp[]> = await api.tournament.apiTournamentsRequestsMineGet(userId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении заявок турниров для создателя с идентификатором ${userId}:`,
      error
    );
    throw error;
  }
});

export const createTournament = createAsyncThunk<
  ApiTournamentsCreatePost200Response,
  {
    user_id: number;
    full_name: string;
    short_name: string;
    club: string;
    club_address: string;
    start_dt: string;
    end_dt: string;
    city_id: FilterOption;
    zone_id?: FilterOption;
    court_coverage?: string;
    official_ball?: string;
    contact_info?: string;
    is_draft?: ApiTeamsCreatePostIsDraftEnum;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("tournaments/createTournament", async (createObject, { extra: api }) => {
  try {
    const response : AxiosResponse<ApiTournamentsCreatePost200Response> =
      await api.tournament.apiTournamentsCreatePost(
        createObject.user_id,
        createObject.full_name,
        createObject.short_name,
        createObject.club,
        createObject.club_address,
        createObject.start_dt,
        createObject.end_dt,
        Number(createObject.city_id.value),
        Number(createObject.zone_id?.value),
        createObject.court_coverage,
        createObject.official_ball,
        createObject.contact_info,
        createObject.is_draft,
      );
    return response.data;
  } catch (error) {
    console.error("Ошибка при подаче заявки на создание турнира:", error);
    throw error;
  }
});

export const fetchTeamsRequestsMine = createAsyncThunk<
  TeamTemp[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("teams/fetchTeamsRequestsMine", async (userId:number , { extra: api }) => {
  try {
    const response: AxiosResponse<TeamTemp[]> = await api.team.apiTeamsRequestsMineGet(userId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении заявок команд для создателя с идентификатором ${userId}:`,
      error
    );
    throw error;
  }
});

export const fetchTeamsRating = createAsyncThunk<
  RatingTeam[],
  {
    seasonId?: number,
    tournamentId?: number,
    teamId?: number,
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>(
  "teamsRating/fetchTeamsRating",
  async ({seasonId, tournamentId, teamId}, {extra: api}) => {
    try {
      const response: AxiosResponse<RatingTeam[]> =
        await api.stats.apiStatsRatingsTeamsGet(seasonId, tournamentId, teamId);
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при получении рейтинга команд:`,
        error
      );
      throw error;
    }
  }
);

export const fetchPlayersRating = createAsyncThunk<
  RatingPlayer[],
  {
    seasonId?: number
    tournamentId?: number
    teamId?: number
  },
  {
    dispatch: AppDispatch
    state: State
    extra: typeof api
  }
>(
  "playersRating/fetchPlayersRating",
  async ({seasonId, tournamentId, teamId}, {extra: api}) => {
    try {
      const response: AxiosResponse<RatingPlayer[]> =
        await api.stats.apiStatsRatingsPlayersGet(seasonId, tournamentId, teamId)
      return response.data
    } catch (error) {
      console.error(`Ошибка при получении рейтинга игроков: ${error}`)
      throw error
    }
  }
)

export const fetchPlayersStats = createAsyncThunk<
  StatsPlayer[],
  {
    seasonId?: number
    tournamentId?: number
    teamId?: number
    playerId?: number
  },
  {
    dispatch: AppDispatch
    state: State
    extra: typeof api
  }
>(
  "playersStats/fetchPlayersStats",
  async ({seasonId, tournamentId, teamId, playerId}, {extra: api}) => {
    try {
      const response: AxiosResponse<StatsPlayer[]> =
        await api.stats.apiStatsPlayersGet(seasonId, tournamentId, teamId, playerId)
      return response.data
    } catch (error) {
      console.error(`Ошибка при получении статистики игроков: ${error}`)
      throw error
    }
  }
)

export const fetchTeamsStats = createAsyncThunk<
  StatsTeam[],
  {
    seasonId?: number
    tournamentId?: number
    teamId?: number
  },
  {
    dispatch: AppDispatch
    state: State
    extra: typeof api
  }
>(
  "teamsStats/fetchTeamsStats",
  async ({seasonId, tournamentId, teamId}, {extra: api}) => {
    try {
      const response: AxiosResponse<StatsTeam[]> =
        await api.stats.apiStatsTeamsGet(seasonId, tournamentId, teamId)
      return response.data
    } catch (error) {
      console.log(`Ошибка при получении статистики команд: ${error}`)
      throw error
    }
  }
)

export const fetchPlayerTeams = createAsyncThunk<
  TeamPlayer[],
  {
    playerId: number
  },
  {
    dispatch: AppDispatch
    state: State
    extra: typeof api
  }
>(
  "playerTeams/fetchPlayerTeams",
  async ({playerId}, {extra: api}) => {
    try {
      const response: AxiosResponse<TeamPlayer[]> =
        await api.player.apiPlayersIdTeamsGet(playerId)
      return response.data
    } catch (error) {
      console.log(`Ошибка при получении команд игрока с идентификатором ${playerId}: ${error}`)
      throw error
    }
  }
)

export const fetchTeamsMine = createAsyncThunk<
  Team[],
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: typeof api;
  }
>("teams/fetchTeamsMine", async (userId:number , { extra: api }) => {
  try {
    const response: AxiosResponse<Team[]> = await api.team.apiTeamsMineGet(userId);
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении команд для создателя с идентификатором ${userId}:`,
      error
    );
    throw error;
  }
});