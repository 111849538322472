import React from "react";
import {Link, useLocation} from "react-router-dom";
import {NavigationItem} from "../../../core/types/navigation";

function NavItem({headerNavigation, onClick}: {
  headerNavigation: NavigationItem;
  onClick: () => void;
}) {

  const location = useLocation();
  const isActive = location.pathname === headerNavigation.url;

  return (
    <li className={`nav__item ${isActive ? "nav__item--active" : ""}`}>
      <Link className="nav__link" to={headerNavigation.url} onClick={onClick}>
        <p className="nav__text">{headerNavigation.title}</p>
      </Link>
    </li>
  );
}

export default NavItem;
