import React from 'react'
import SidebarItem from "../sidebar-item/sidebar-item"
import {
  Post,
  Video
} from "../../../api"

function SidebarList({contents, hidden}: {
  contents: Post[] | Video[],
  hidden: boolean
}) {
  return (
    <ul className={`sidebar__list ${hidden ? 'hidden' : ''}`}>
      {contents.map((content, index) => (
        <SidebarItem key={index} content={content}/>
      ))}
    </ul>
  )
}

export default SidebarList
