import React from "react";
import Button from "../../ui/button/button";
import {
  ApiTournamentsIdRoundsGetTypeEnum,
  Round,
  Tournament,
} from "../../../api";
import {AppRoute} from "../../../core/constants/routes";
import {filterRoundsByTypes} from "../../../core/utils/array-prepare-helpers";

function LeagueItem({league}: { league: Tournament }) {
  const hasZone = league.zone && league.zone.title;
  const showPlayOff =
    Array.isArray(league.rounds) &&
    league.rounds.some((round: Round) => round.type === "playoff-olympic");
  const lapRounds = filterRoundsByTypes(league.rounds, [
    ApiTournamentsIdRoundsGetTypeEnum.RoundRobinOne,
    ApiTournamentsIdRoundsGetTypeEnum.RoundRobinTwo,
  ]);
  const playoffRounds = filterRoundsByTypes(league.rounds, [ApiTournamentsIdRoundsGetTypeEnum.PlayoffOlympic])

  return (
    <li className="league__item">
      <div className="league__block">
        <div
          className={`league__texts league__green ${!hasZone ? "hidden" : ""}`}
        >
          <p className="league__region">Зона:</p>
          <p className="league__name">{league.zone?.title}</p>
        </div>
        <div className={`league__texts ${!hasZone ? "league__green" : ""}`}>
          <p className="league__region">Регион:</p>
          <p className="league__name">{league.region?.title}</p>
        </div>
        <p className={`league__play ${!showPlayOff ? "hidden" : ""}`}>
          плей-офф
        </p>
        <Button
          to={`${playoffRounds.length > 0 && lapRounds.length === 0 ? AppRoute.GridsPage.url : AppRoute.RatingPage.url}/${league.tournament_id}`}
          text="Подробнее"
        />
      </div>
      <svg className="league__background" width="444" height="175">
        <use xlinkHref="images/sprite.svg#background-logo"/>
      </svg>
    </li>
  );
}

export default LeagueItem;
