import {State} from "../../types/state"
import {RatingPlayer} from "../../../api"
import {NameSpace} from "../../constants/api-constants"
import {
  FilterOption,
  SorterOption
} from "../../types/common"

export const getPlayersRatingLoading = (state: State): boolean => state[NameSpace.PlayersRating].playersRatingLoading
export const getPlayersRatingError = (state: State): boolean => state[NameSpace.PlayersRating].playersRatingError
export const getPlayersRating = (state: State): RatingPlayer[] => state[NameSpace.PlayersRating].playersRating
export const getPlayersRatingFilterSeasonStatus = (state: State): FilterOption => state[NameSpace.PlayersRating].filterSeasonStatus
export const getPlayersRatingSearchString = (state: State): string => state[NameSpace.PlayersRating].searchString
export const getPlayersRatingSorterStatus = (state: State): SorterOption => state[NameSpace.PlayersRating].sorterStatus
