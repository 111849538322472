import React, {ChangeEvent} from "react"
import {ItemsForFilter} from "../../../core/constants/filters"
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks"
import {filteringPlayerMatchesByString} from "../../../core/store/player-matches-data/player-matches-data"
import {getPlayerMatchesSearchString} from "../../../core/store/player-matches-data/selectors"
import {getTeamsRatingSearchString} from "../../../core/store/teams-rating-data/selectors"
import {filteringTeamsRatingByString} from "../../../core/store/teams-rating-data/teams-rating-data"
import {getPlayersRatingSearchString} from "../../../core/store/players-rating-data/selectors"
import {filteringPlayersByString} from "../../../core/store/players-rating-data/players-rating-data"
import { filteringTournamentsByString } from '../../../core/store/tournaments-data/tournaments-data'
import { getTournamentsSearchString } from '../../../core/store/tournaments-data/selectors'

function Search({className, items}: {
  className?: string,
  items: string
}) {
  const dispatch = useAppDispatch()

  const handleInputChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    switch (items) {
      case ItemsForFilter.MatchSeriesArray:
        dispatch(filteringPlayerMatchesByString(changeEvent.target.value))
        break;
      case ItemsForFilter.PlayersRating:
        dispatch(filteringPlayersByString(changeEvent.target.value))
        break;
      case ItemsForFilter.TeamsRating:
        dispatch(filteringTeamsRatingByString(changeEvent.target.value))
        break;
      case ItemsForFilter.Tournaments:
        dispatch(filteringTournamentsByString(changeEvent.target.value))
        break;
      default:
        break;
    }
  }

  const createValue = (items: string) => {
    switch (items) {
      case ItemsForFilter.PlayersRating:
        return useAppSelector(getPlayersRatingSearchString)
      case ItemsForFilter.TeamsRating:
        return useAppSelector(getTeamsRatingSearchString)
      case ItemsForFilter.MatchSeriesArray:
        return useAppSelector(getPlayerMatchesSearchString)
      case ItemsForFilter.Tournaments:
        return useAppSelector(getTournamentsSearchString)
      default:
        return ''
    }
  }

  return (
    <form className={`search ${className || ""}`}>
      <svg className="search__icon" width="20" height="20">
        <use xlinkHref="images/sprite.svg#search"/>
      </svg>
      <input
        className="search__input"
        type="text"
        placeholder="введите запрос"
        onChange={(event) => handleInputChange(event)}
        value={createValue(items)}
      />
    </form>
  )
}

export default Search
