import React from "react"
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {changeMessageModal} from "../../../core/store/modals-data/modals-data"
import {getMessageModalStatus} from "../../../core/store/modals-data/selectors"

function ModalVerification() {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector(getMessageModalStatus)

  const handleModalClose = () => {
    dispatch(changeMessageModal({messageModal: false}))
  }

  return (
    <div className={`modal modal--verification ${isModalOpen ? '' : 'modal--close'}`}>
      <div className="modal__container">
        <div className="modal__scroll">
          <div className="background-form">
            <div className="verification">
              <p className="verification__text">Данные отправлены на проверку.</p>
              <p className="verification__text">После подтверждения</p>
              <p className="verification__text">администратором вы сможете внести оплату.</p>
            </div>
          </div>
        </div>
        <a className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="45" height="45">
            <use xlinkHref="images/sprite.svg#close"/>
          </svg>
        </a>
      </div>
    </div>
  )
}

export default ModalVerification
