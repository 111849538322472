import {ModalsState} from "../../types/state"
import {
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit"
import {NameSpace} from "../../constants/api-constants"

const initialState: ModalsState = {
  authorizationModal: false,
  messageModal: false,
}

export const modalsData = createSlice({
  name: NameSpace.Modals,
  initialState,
  reducers: {
    changeAuthorizationModal: (
      state,
      action: PayloadAction<{authorizationModal: boolean}>
    ) => {
      const {authorizationModal} = action.payload
      state.authorizationModal = authorizationModal
    },
    changeMessageModal: (
      state,
      action: PayloadAction<{messageModal: boolean}>
    ) => {
      const {messageModal} = action.payload
      state.messageModal = messageModal
    },
  }
})

export const {
  changeAuthorizationModal,
  changeMessageModal,
} = modalsData.actions