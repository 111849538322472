import React, {useEffect} from 'react'
import AsideMedia from "../../components/simple/aside-media/aside-media"
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import Title from "../../components/ui/title/title"
import Author from "../../components/ordinary/author/author"
import Images from "../../components/ordinary/images/images"
import RedactorStyles from "../../components/ordinary/redactor-styles/redactor-styles"
import {useParams} from 'react-router-dom'
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks'
import {
  getCurrentVideo,
  getVideoErrorStatus,
  getVideoLoadingStatus,
  getVideos,
  getVideosErrorStatus,
  getVideosLoadingStatus
} from '../../core/store/videos-data/selectors'
import {AppRoute} from '../../core/constants/routes'
import {
  fetchVideo,
  fetchVideos
} from '../../core/store/api-actions'
import Stub from "../../components/ordinary/stub/stub"
import {
  excludeVideoById,
  getTopItemsByPublishedDt
} from "../../core/utils/array-prepare-helpers"

function VideoPage() {
  const {id: videoId} = useParams()
  const dispatch = useAppDispatch()
  const video = useAppSelector(getCurrentVideo)!
  const videoError = useAppSelector(getVideoErrorStatus)
  const videoLoading = useAppSelector(getVideoLoadingStatus)
  const hidden = video === null || videoError || videoLoading
  const videos = useAppSelector(getVideos)
  const videosWithoutCurrent = excludeVideoById(videos, videoId)
  const topVideos = getTopItemsByPublishedDt(videosWithoutCurrent, 3)
  const videosError = useAppSelector(getVideosErrorStatus)
  const videosLoading = useAppSelector(getVideosLoadingStatus)
  const currentURL = window.location.href

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.VideosPage,
    {title: video?.title || '', url: currentURL}
  ]

  useEffect(() => {
    dispatch(fetchVideo(Number(videoId)))
    dispatch(fetchVideos())
  }, [videoId])

  return (
    <div className="main__container">
      <div className="container container--1080">
        <div className="gap gap--0">
          <div className={`gap gap--20 ${hidden ? 'hidden' : ''}`}>
            <CrumbsList routes={crumbsRoutes}/>
            <Title title={video?.title}/>
            <Author name={video?.author} date={video?.published_dt}/>
            <div className="gap gap--25">
              <Images data={video}/>
              <RedactorStyles string={video?.description || ''}/>
            </div>
          </div>
          <Stub
            type={
              videoError
                ? "error"
                : video === null
                  ? "absence"
                  : ""
            }
            error={
              videoError ? `Произошла ошибка при загрузке видео с идентификатором ${videoId}.` : ""
            }
          />
        </div>
      </div>
      <AsideMedia
        contents={topVideos}
        contentsLoading={videosLoading}
        contentsError={videosError}
      />
    </div>
  )
}

export default VideoPage
