import {MatchesState} from "../../types/state"
import {createSlice} from "@reduxjs/toolkit"
import {NameSpace} from "../../constants/api-constants"
import {fetchMatches} from "../api-actions"

const initialState: MatchesState = {
  isMatchesLoading: false,
  isMatchesError: false,
  matches: [],
}

export const matchesData = createSlice({
  name: NameSpace.Matches,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMatches.pending, (state) => {
        state.isMatchesLoading = true
      })
      .addCase(fetchMatches.fulfilled, (state, action) => {
        state.matches = action.payload
        state.isMatchesLoading = false
      })
      .addCase(fetchMatches.rejected, (state) => {
        state.isMatchesError = true
        state.isMatchesLoading = false
      })
  }
})
