import React, {useEffect} from "react"
import RatingWidgetRow from "../rating-widget-row/rating-widget-row"
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks"
import {
  fetchTeamsRating,
  fetchTeamsStats
} from "../../../core/store/api-actions"
import {
  getTeamsRating,
  getTeamsRatingError,
  getTeamsRatingLoading
} from "../../../core/store/teams-rating-data/selectors"
import {
  getTeamsStats,
  getTeamsStatsError,
  getTeamsStatsLoading
} from "../../../core/store/teams-stats-data/selectors"
import Stub from "../stub/stub"
import {findTeamStatsById} from "../../../core/utils/array-prepare-helpers"

function RatingWidgetTable() {
  const dispatch = useAppDispatch()
  const teamsRating = useAppSelector(getTeamsRating)
  const teamsRatingError = useAppSelector(getTeamsRatingError)
  const teamsRatingLoading = useAppSelector(getTeamsRatingLoading)
  const teamsStats = useAppSelector(getTeamsStats)
  const teamsStatsLoading = useAppSelector(getTeamsStatsLoading)
  const teamsStatsError = useAppSelector(getTeamsStatsError)
  const widgetError = teamsRatingError || teamsStatsError
  const widgetLoading = teamsRatingLoading || teamsStatsLoading
  const hidden = teamsRating.length === 0 || widgetError || widgetLoading

  useEffect(() => {
    dispatch(fetchTeamsRating({}))
    dispatch(fetchTeamsStats({}))
  }, [dispatch])

  return (
    <>
      <div className={`table-scroll ${hidden ? 'hidden' : ''}`}>
        <table className="table table-widget">
          <thead className="table__thead">
          <tr className="table__row">
            <th className="table-widget__th" colSpan={5}>Рейтинг</th>
          </tr>
          </thead>
          <tbody className="table__tbody">
          {teamsRating.map((teamRating, index) => (
            <RatingWidgetRow
              key={teamRating.team_id}
              index={index}
              teamRating={teamRating}
              teamStats={findTeamStatsById(teamsStats, teamRating.team_id)}
            />
          ))}
          </tbody>
        </table>
      </div>
      <Stub
        type={widgetError ? "error" : ""}
        error={widgetError ? "Произошла ошибка при загрузке рейтинга." : ""}
      />
    </>
  )
}

export default RatingWidgetTable
