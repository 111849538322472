import React from "react"
import {useAppDispatch} from "../../../core/hooks"
import {ItemsForFilter} from "../../../core/constants/filters"
import {resetLeaguesFilters} from "../../../core/store/leagues-data/leagues-data"
import {resetTournamentsFilters} from "../../../core/store/tournaments-data/tournaments-data"
import {resetTeamsRatingFilters} from "../../../core/store/teams-rating-data/teams-rating-data"
import {resetPlayersFilters} from "../../../core/store/players-rating-data/players-rating-data"

function Reset({items}: { items: string }) {
  const dispatch = useAppDispatch()

  const handleReset = () => {
    switch (items) {
      case ItemsForFilter.Leagues:
        dispatch(resetLeaguesFilters())
        break
      case ItemsForFilter.Tournaments:
        dispatch(resetTournamentsFilters())
        break
      case ItemsForFilter.PlayersRating:
        dispatch(resetPlayersFilters())
        break
      case ItemsForFilter.TeamsRating:
        dispatch(resetTeamsRatingFilters())
        break
      default:
        break
    }
  }
  return (
    <button className="button reset" onClick={handleReset}>
      <svg className="reset__icon" width="24" height="24">
        <use xlinkHref="images/sprite.svg#reset"/>
      </svg>
    </button>
  )
}

export default Reset
