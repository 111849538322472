import React from "react"
import {RoundTable} from "../../../api"
import ChessTeamsRow from "../chess-teams-row/chess-teams-row"

const ChessTeams = React.forwardRef<
  HTMLTableElement,
  { roundTables: RoundTable[] | undefined }
>(({roundTables}, ref) => {
  return (
    <table ref={ref} className="table table-team">
      <thead className="table__thead">
      <tr className="table__row">
        <th className="table__th table__number">№</th>
        <th className="table__th table__start">Команда</th>
        <th className="table__th table__127">Встречи</th>
      </tr>
      </thead>
      <tbody className="table__tbody">
      {roundTables?.map((roundTable, index) => (
        <ChessTeamsRow roundTable={roundTable} index={index} key={roundTable.team_id}/>
      ))}
      </tbody>
    </table>
  )
})

ChessTeams.displayName = "ChessTeams"
export default ChessTeams
