import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getPosts } from "../../../core/store/posts-data/selectors";
import { getTopItemsByPublishedDt } from "../../../core/utils/array-prepare-helpers";
import React, { useEffect } from "react";
import { fetchPosts } from "../../../core/store/api-actions";
import Title from "../../ui/title/title";
import Button from "../../ui/button/button";
import { AppRoute } from "../../../core/constants/routes";
import ContentList from "../../ordinary/content-list/content-list";

function NewsWidget() {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(getPosts);
  const topPosts = getTopItemsByPublishedDt(posts, 3);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  return (
    <div className={`gap gap--30 ${topPosts.length === 0 ? "hidden" : ""}`}>
      <div className="container-row">
        <div className="gap gap--row-2">
          <Title className="title--uppercase" title="Новости" />
          <Button
            className={`button--full show-580 ${posts.length > topPosts.length ? "" : "hidden"}`}
            text="Все новости"
            to={AppRoute.PostsPage.url}
          />
        </div>
      </div>
      <div className="gap gap--0">
        <ContentList
          items={topPosts}
          className="content--three content--main"
        />
        <Button
          className={`button--full hide-580 ${posts.length > topPosts.length ? "" : "hidden"}`}
          text="Все новости"
          to={AppRoute.PostsPage.url}
        />
      </div>
    </div>
  );
}

export default NewsWidget;
