import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import FormSelect from "../../smart/select/select";
import { SelectTypes } from "../../../core/constants/selects";
import { fetchCities, fetchZones } from "../../../core/store/api-actions";
import { getCities } from "../../../core/store/cities-data/selectors";
import {
  transformCitiesToOptions,
  transformZonesToOptions,
} from "../../../core/utils/array-prepare-helpers";
import { getZones } from "../../../core/store/zones-data/selectors";

function CommandsForm({
  formik,
}: {
  // eslint-disable-next-line
  formik: any;
}) {
  const dispatch = useAppDispatch();
  const cities = useAppSelector(getCities);
  const zones = useAppSelector(getZones);
  const citiesOptions = transformCitiesToOptions(cities);
  const zonesOptions = transformZonesToOptions(zones);
  const [playerCountError, setPlayerCountError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  useEffect(() => {
    dispatch(fetchCities());
    dispatch(fetchZones());
  }, [dispatch]);

  useEffect(() => {
    if (formik.errors.players) {
      const playerCount = formik.values.players.length;
      if (playerCount < 4 || playerCount > 6) {
        setPlayerCountError("В команде должно быть от 4 до 15 игроков");
      } else {
        setPlayerCountError(null);
      }
    }
  }, [formik.errors.players, formik.values.players]);

  return (
    <div className="fields">
      <div className={`form__help ${!playerCountError ? "hidden" : ""}`}>
        {playerCountError}
      </div>
      <div className="fields__item">
        <label className="form">
          <p className="form__text">
            Название команды{" "}
            <span
              className={`form__help ${
                formik.errors.full_name ? "" : "hidden"
              }`}
            >
              {formik.errors.full_name}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.full_name ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="full_name"
            value={formik.values.full_name}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">Город<span
              className={`form__help ${formik.errors.city_id ? "" : "hidden"}`}
            >
              {formik.errors.city_id}
            </span></p>
          <FormSelect
            options={citiesOptions}
            type={SelectTypes.Cities}
            formik={formik}
            value={formik.values.city_id}
            name="city_id"
          />
        </label>
        <label className="form">
          <p className="form__text">
            Название клуба
            <span
              className={`form__help ${formik.errors.club ? "" : "hidden"}`}
            >
              {formik.errors.club}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.club ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="club"
            value={formik.values.club}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">
            Адрес клуба
            <span
              className={`form__help ${
                formik.errors.club_address ? "" : "hidden"
              }`}
            >
              {formik.errors.club_address}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.club_address ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="club_address"
            value={formik.values.club_address}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">
            Покрытие корта
            <span
              className={`form__help ${
                formik.errors.court_coverage ? "" : "hidden"
              }`}
            >
              {formik.errors.court_coverage}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.court_coverage ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите тип"
            name="court_coverage"
            value={formik.values.court_coverage}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">Желаемая региональная зона <span
              className={`form__help ${formik.errors.zone_id ? "" : "hidden"}`}
            >
              {formik.errors.zone_id}
            </span></p>
          <FormSelect
            options={zonesOptions}
            type={SelectTypes.Zones}
            formik={formik}
            value={formik.values.zone_id}
            name="zone_id"
          />
        </label>
        <label className="form">
          <p className="form__text">Название титульного партнёра</p>
          <input
            className="form__input"
            type="text"
            placeholder="Введите название"
          />
        </label>
        <label className="form">
          <p className="form__text">Ссылка на титульного партнёра</p>
          <input
            className="form__input"
            type="text"
            placeholder="Введите название"
          />
        </label>
      </div>
    </div>
  );
}

export default CommandsForm;
