import React from "react";
import CalendarMonthList from "../calendar-month-list/calendar-month-list";
import CalendarYear from "../calendar-year/calendar-year";

function CalendarHeader({currentDate, onChangeDate}: {
  currentDate: Date;
  onChangeDate: (type: string, date?: Date) => void
}) {
  const currentYear = currentDate.getFullYear();
  const monthsOfYear = [];
  for (let month = 0; month < 12; month++) {
    monthsOfYear.push(new Date(currentYear, month, 1));
  }

  return (
    <div className="calendar__top">
      <CalendarYear
        currentYear={currentYear}
        onChangeDate={onChangeDate}
      />
      <CalendarMonthList
        currentYear={currentYear}
        onChangeDate={onChangeDate}
        currentDate={currentDate}
      />
    </div>
  );
}

export default CalendarHeader;