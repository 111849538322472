import React, {
  useEffect,
  useState
} from 'react';
import Title from "../../components/ui/title/title";
import Pagination from "../../components/ordinary/pagination/pagination";
import Match from "../../components/ordinary/match/match";
import Stub from "../../components/ordinary/stub/stub";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import {useParams} from "react-router-dom";
import ButtonsList from "../../components/ui/buttons-list/buttons-list";
import {generateTournamentButtonsArray} from "../../core/utils/common-helpers";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getCurrentTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus
} from "../../core/store/tournaments-data/selectors";
import {
  fetchMatchSeriesArray,
  fetchTournament
} from "../../core/store/api-actions";
import {TournamentTypeEnum} from "../../api";
import {AppRoute} from "../../core/constants/routes";
import {
  getMatchSeriesArray,
  getMatchSeriesArrayErrorStatus,
  getMatchSeriesArrayLoadingStatus
} from "../../core/store/match-series-data/selectors";
import {MATCH_SERIES_PER_PAGE} from "../../core/constants/common";

function ProtocolsPage() {
  const dispatch = useAppDispatch();
  const {id: tournamentId} = useParams();
  const tournament = useAppSelector(getCurrentTournament);
  const tournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const tournamentError = useAppSelector(getTournamentErrorStatus);
  const matchSeries = useAppSelector(getMatchSeriesArray);
  const matchSeriesLoading = useAppSelector(getMatchSeriesArrayLoadingStatus);
  const matchSeriesError = useAppSelector(getMatchSeriesArrayErrorStatus);
  const protocolPageLoading = tournamentLoading || matchSeriesLoading;
  const protocolPageError = tournamentError || matchSeriesError;
  const buttonsArray = generateTournamentButtonsArray(tournament);

  const [currentPage, setCurrentPage] = useState(0);
  const matchSeriesPerPage = MATCH_SERIES_PER_PAGE;
  const pageCount = Math.ceil((matchSeries?.length || 0) / matchSeriesPerPage);
  const indexOfLastMatchSeries = (currentPage + 1) * matchSeriesPerPage;
  const indexOfFirstMatchSeries = indexOfLastMatchSeries - matchSeriesPerPage;
  const currentMatchSeries = matchSeries ? matchSeries.slice(indexOfFirstMatchSeries, indexOfLastMatchSeries) : [];

  const crumbsRoutes = [
    AppRoute.Main,
    tournament?.type === TournamentTypeEnum.Commercial ? AppRoute.TournamentsPage : AppRoute.LeaguePage,
    {
      title: `${tournament?.full_name}/${AppRoute.ProtocolsPage.title}`,
      url: `#`
    }
  ];

  useEffect(() => {
    dispatch(fetchTournament({
      tournamentId: Number(tournamentId),
      _with: ["documents"]
    }));
    dispatch(fetchMatchSeriesArray({
      tournamentId: Number(tournamentId),
      _with: ["team1", "team2"],
    }));
  }, [dispatch, tournamentId]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <div className="container">
      <div className={`gap gap--20 ${protocolPageLoading || protocolPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes}/>
        <div className="gap gap--20 gap--row">
          <Title className="title--uppercase" title={tournament?.type === TournamentTypeEnum.Commercial ? 'Турнир ' : 'Лига '} titleGreen={tournament?.full_name}/>
          <ButtonsList buttonsData={buttonsArray}/>
        </div>
        <div className="gap gap--40">
          <Match seriesArray={currentMatchSeries}/>
          <Pagination pageCount={pageCount} forcePage={currentPage} onPageClick={handlePageClick}/>
        </div>
      </div>
      <Stub
        type={protocolPageError ? "error" : matchSeries.length === 0 ? "absence" : ""}
        error={protocolPageError ? "Произошла ошибка при загрузке протоколов." : ""}
      />
    </div>
  );
}

export default ProtocolsPage;
