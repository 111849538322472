import {createSlice} from '@reduxjs/toolkit';
import {fetchFaqs} from '../api-actions';
import {FaqsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: FaqsState = {
  isFaqsLoading: false,
  isFaqsError: false,
  faqs: [],
};

export const faqsData = createSlice({
  name: NameSpace.Faqs,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFaqs.pending, (state) => {
        state.isFaqsLoading = true;
      })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.faqs = action.payload;
        state.isFaqsLoading = false;
      })
      .addCase(fetchFaqs.rejected, (state) => {
        state.isFaqsError = true;
        state.isFaqsLoading = false;
      })
  }
});
