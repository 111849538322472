import React from 'react';
import TranslationItem from "../translation-item/translation-item";

function TranslationList() {
  return (
    <ul className="translation">
      {Array.from({length: 2}, (_, index) =>(
        <TranslationItem key={index}/>
      ))}
    </ul>
  );
}

export default TranslationList;
