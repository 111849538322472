import { LeaguesState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { Tournament } from "../../../api";
import { FilterOption } from "../../types/common";

export const getLeaguesLoadingStatus = (state: State): boolean =>
  state[NameSpace.Leagues].isLeaguesLoading;
export const getLeagueLoadingStatus = (state: State): boolean =>
  state[NameSpace.Leagues].isLeagueLoading;
export const getLeaguesErrorStatus = (
  state: State
): LeaguesState["isLeaguesError"] => state[NameSpace.Leagues].isLeaguesError;
export const getLeagueErrorStatus = (
  state: State
): LeaguesState["isLeagueError"] => state[NameSpace.Leagues].isLeagueError;
export const getLeagues = (state: State): Tournament[] =>
  state[NameSpace.Leagues].leagues;
export const getCurrentLeague = (state: State): Tournament | null =>
  state[NameSpace.Leagues].currentLeague;
export const getLeaguesFilterSeasonStatus = (state: State): FilterOption =>
  state[NameSpace.Leagues].filterSeasonStatus;
export const getLeaguesFilterRegionStatus = (state: State): FilterOption =>
  state[NameSpace.Leagues].filterRegionStatus;
export const getLeaguesFilterStageStatus = (state: State): FilterOption =>
  state[NameSpace.Leagues].filterStageStatus;
