import React from "react";

function ButtonForm({className, title, onClick}: {
  className?: string,
  title: string,
  onClick?:()=> void
}) {
  return (
    <button type="submit" className={`save ${className || ""}`} onClick={onClick}>{title}</button>
  )
}

export default ButtonForm;
