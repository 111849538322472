import {PlayersStatsState} from "../../types/state"
import {createSlice} from "@reduxjs/toolkit"
import {NameSpace} from "../../constants/api-constants"
import {fetchPlayersStats} from "../api-actions"

const initialState: PlayersStatsState = {
  playersStatsLoading: false,
  playersStatsError: false,
  playersStats: []
}

export const playersStatsData = createSlice({
  name: NameSpace.PlayersStats,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayersStats.pending, (state) => {
        state.playersStatsLoading = true
      })
      .addCase(fetchPlayersStats.fulfilled, (state, action) => {
        state.playersStats = action.payload
        state.playersStatsLoading = false
      })
      .addCase(fetchPlayersStats.rejected, (state) => {
        state.playersStatsError = true
        state.playersStatsLoading = false
      })
  }
})
