import React, { useEffect } from "react";
import AsideBanners from "../../components/simple/aside-banners/aside-banners";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import AccordionList from "../../components/ordinary/accordion-list/accordionList";
import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getFaqs,
  getFaqsLoadingStatus,
} from "../../core/store/faq-data/selectors";
import { fetchFaqs } from "../../core/store/api-actions";
import { sortItemsByRank } from "../../core/utils/array-prepare-helpers";
import { BannerPositionEnum } from "../../api";

function FaqPage() {
  const faqs = useAppSelector(getFaqs);
  const sortedFaqs = sortItemsByRank(faqs);
  const faqsLoading = useAppSelector(getFaqsLoadingStatus);
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.FaqPage];

  useEffect(() => {
    dispatch(fetchFaqs());
  }, [dispatch]);

  return (
    <div className="main__container">
      <div className="container-left">
        <div className={`gap gap--20 ${faqsLoading ? "hidden" : ""}`}>
          <CrumbsList routes={crumbsRoutes} />
          <Title className="title--uppercase" title="Faq" />
          <AccordionList items={sortedFaqs} />
        </div>
      </div>
      <AsideBanners bannerPosition={BannerPositionEnum.SidebarFaq} />
    </div>
  );
}

export default FaqPage;
