import React, {
  ReactElement,
  useEffect
} from "react"
import Footer from "../../simple/footer/footer"
import Header from "../../simple/header/header"
import MainWrapper from "../../simple/main-wrapper/main-wrapper"
import Modal from "../../ordinary/modal/modal"
import ModalVerification from "../../ordinary/modal-verification/modal-verification"
import {isSafari} from "../../../core/utils/common-helpers"
import {useAppSelector} from "../../../core/hooks"
import {
  getAuthorizationModalStatus,
  getMessageModalStatus
} from "../../../core/store/modals-data/selectors"

function Layout({children}: { children: ReactElement }) {
  const isAuthorizationModalOpen = useAppSelector(getAuthorizationModalStatus)
  const isMessageModalOpen = useAppSelector(getMessageModalStatus)

  useEffect(() => {
    const isModalOpen = isAuthorizationModalOpen || isMessageModalOpen
    const addClass = () => {
      if (isSafari()) {
        document.body.classList.toggle("fixed-safari", isModalOpen)
        document.documentElement.classList.toggle("fixed-safari", isModalOpen)
      } else {
        document.body.classList.toggle("fixed", isModalOpen)
      }
    }
    addClass()
    return () => {
      addClass()
    };
  }, [isMessageModalOpen, isAuthorizationModalOpen])

  return (
    <>
      <div className="body-container">
        <Header/>
        <MainWrapper>{children}</MainWrapper>
        <Footer/>
      </div>
      <Modal/>
      <ModalVerification/>
    </>
  )
}

export default Layout
