import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks"
import {useParams} from "react-router-dom"
import {getMatchSeriesArray} from "../../../core/store/match-series-data/selectors"
import React, {
  useEffect,
  useMemo,
  useState
} from "react"
import {COMMAND_MATCH_SERIES_PER_PAGE} from "../../../core/constants/common"
import {fetchMatchSeriesArray} from "../../../core/store/api-actions"
import Match from "../match/match"
import Pagination from "../pagination/pagination"
import Stub from "../stub/stub"
import FilterTeamMatches from "../../simple/filter-team-matches/filter-team-matches"
import {getTeamMatchSeriesSeasonFilter} from "../../../core/store/team-match-series-data/selectors"
import {filterItemsWithTournamentBySeasonId} from "../../../core/utils/array-prepare-helpers"

function CommandMatches() {
  const dispatch = useAppDispatch()
  const {id: teamId} = useParams()
  const matchSeriesArray = useAppSelector(getMatchSeriesArray)
  const filterSeasonStatus = useAppSelector(getTeamMatchSeriesSeasonFilter)

  const createProcessedMatchSeriesArray = useMemo(() => {
    let processedMatchSeriesArray = matchSeriesArray;
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      processedMatchSeriesArray = filterItemsWithTournamentBySeasonId(processedMatchSeriesArray, filterSeasonStatus.value);
    }
    return processedMatchSeriesArray;
  }, [filterSeasonStatus, matchSeriesArray]);

  const [currentPage, setCurrentPage] = useState(0)
  const matchSeriesPerPage = COMMAND_MATCH_SERIES_PER_PAGE
  const pageCount = Math.ceil((matchSeriesArray?.length || 0) / matchSeriesPerPage)
  const indexOfLastMatchSeries = (currentPage + 1) * matchSeriesPerPage
  const indexOfFirstMatchSeries = indexOfLastMatchSeries - matchSeriesPerPage
  const currentMatchSeriesArray = matchSeriesArray ? createProcessedMatchSeriesArray.slice(indexOfFirstMatchSeries, indexOfLastMatchSeries) : []

  useEffect(() => {
    dispatch(fetchMatchSeriesArray({
      teamId: Number(teamId),
      _with: ["team1", "team2", "tournament"],
    }))
  }, [dispatch])

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected)
  }

  return (
    <div className="blocks__container blocks__container--start">
      <div className="gap gap--20 gap--start">
        <FilterTeamMatches/>
        <div className={`gap gap--20 ${createProcessedMatchSeriesArray.length === 0 ? 'hidden' : ''}`}>
          <Match className="match--command" seriesArray={currentMatchSeriesArray}/>
          <Pagination pageCount={pageCount} forcePage={currentPage} onPageClick={handlePageClick}/>
        </div>
      </div>
      <Stub
        type={
          matchSeriesArray.length > 0 && createProcessedMatchSeriesArray.length === 0
            ? "searchError"
            : matchSeriesArray.length === 0
              ? "absence"
              : ""
        }
      />
    </div>
  )
}

export default CommandMatches
