import React from 'react';
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function RedactorStyles({string}: {string: string}) {

  return (
    <div className="redactor-styles">
      <HtmlRenderer htmlString={string} />
    </div>
  );
}

export default RedactorStyles;
