import $ from 'jquery';

$.playoff = new function() {
  let cssClasses = {
    highlight: 'playoff__team--highlighted',
    highlightUpper: 'playoff__line-part--highlighted-upper',
    highlightLower: 'playoff__line-part--highlighted-lower',
    active: 'playoff__active'
  };

  let classes = {
    playoff: 'js-playoff',
    cont: 'js-playoff-cont',
    match: 'js-playoff-match',
    nav: 'js-playoff-navigation',
    active: 'js-playoff-active',
    step: 'js-playoff-step',
    member: 'js-playoff-member',
    line: 'js-playoff-line',
    arrowPrev: 'js-playoff-prev',
    arrowNext: 'js-playoff-next',
    stepItemActive: 'playoff__step-item--active'  // Новый класс для родительского элемента
  };

  let selectors = {
    playoff: '.' + classes.playoff,
    cont: '.' + classes.cont,
    match: '.' + classes.match,
    nav: '.' + classes.nav,
    active: '.' + classes.active,
    step: '.' + classes.step,
    member: '.' + classes.member,
    line: '.' + classes.line,
    arrowPrev: '.' + classes.arrowPrev,
    arrowNext: '.' + classes.arrowNext
  };

  let steps = {};

  this.init = function() {
    $(selectors.playoff).each(function(k) {
      let $playoff = $(this),
        $navigation = $playoff.find(selectors.nav),
        $current = $navigation.find(selectors.active);

      if ($current.length) {
        steps[k] = {};
        $navigation.find(selectors.step).each(function() {
          if (!steps[k][$(this).data('id')]) {
            steps[k][$(this).data('id')] = {};
          }
          steps[k][$(this).data('id')][$(this).data('step')] = false;
          $(this).click(function() {
            $.playoff.go($playoff, k, $(this).data('id'), $(this).data('step'));
          });
        });
        steps[k][$current.data('id')][$current.data('step')] = true;

        $navigation.find(selectors.arrowPrev).click(function() {
          let prev = $.playoff.getPrev(k);
          $.playoff.go($playoff, k, prev.id, prev.step);
        });

        $navigation.find(selectors.arrowNext).click(function() {
          let next = $.playoff.getNext(k);
          $.playoff.go($playoff, k, next.id, next.step);
        });
      }

      $playoff.find(selectors.member).hover(function(event) {
        const memberId = $(event.target).data('memberId');
        $(event.target).parents(selectors.cont).find(selectors.member + '[data-member-id="' + memberId + '"]').each(function() {
          let highlighted = '';
          $(this).parent().find('.js-playoff-member').each(function(i) {
            if ($(this).data('memberId') == memberId) {
              highlighted += (i == 0 ? cssClasses.highlightUpper : cssClasses.highlightLower);
            }
          });

          if (event.type == 'mouseenter') {
            $(this).addClass(cssClasses.highlight);
            $(this).parents(selectors.match).find(selectors.line).addClass(highlighted);
          } else {
            $(this).removeClass(cssClasses.highlight);
            $(this).parents(selectors.match).find(selectors.line).removeClass(highlighted);
          }
        });
      });

      // Добавляем наблюдатель за изменениями классов
      $playoff.find('.playoff__step-container').each(function() {
        const $container = $(this);
        const $stepItem = $container.closest('.playoff__step-item');

        const observer = new MutationObserver(() => {
          if ($container.hasClass(cssClasses.active)) {
            $stepItem.addClass(classes.stepItemActive);
          } else {
            $stepItem.removeClass(classes.stepItemActive);
          }
        });

        observer.observe(this, { attributes: true, attributeFilter: ['class'] });

        // Инициализация начального состояния
        if ($container.hasClass(cssClasses.active)) {
          $stepItem.addClass(classes.stepItemActive);
        }
      });
    });
  };

  this.destroy = function() {
    // Очищаем события и состояние
    $(selectors.playoff).each(function() {
      let $playoff = $(this),
        $navigation = $playoff.find(selectors.nav);

      $navigation.find(selectors.step).off('click');
      $navigation.find(selectors.arrowPrev).off('click');
      $navigation.find(selectors.arrowNext).off('click');
      $playoff.find(selectors.member).off('hover');
    });
    steps = {};
  };

  this.getCurrent = function(k) {
    for (let id in steps[k]) {
      for (let step in steps[k][id]) {
        if (steps[k][id][step]) {
          return { 'id': id, 'step': step };
        }
      }
    }
    return false;
  };

  this.getFirst = function(k) {
    let idKey = Object.keys(steps[k])[0],
      stepKey = Object.keys(steps[k][idKey])[0];

    return { 'id': idKey, 'step': stepKey }
  };

  this.getPrev = function(k) {
    let prev;
    for (let id in steps[k]) {
      for (let step in steps[k][id]) {
        if (steps[k][id][step]) {
          return prev;
        }
        prev = { 'id': id, 'step': step };
      }
    }
    return $.playoff.getFirst(k);
  };

  this.getNext = function(k) {
    let next = false;
    for (let id in steps[k]) {
      for (let step in steps[k][id]) {
        if (next) {
          return { 'id': id, 'step': step }
        }
        next = steps[k][id][step];
      }
    }
    return $.playoff.getLast();
  };

  this.getLast = function(k) {
    let idKeys = Object.keys(steps[k]), idKey = Object.keys(steps[k])[idKeys.length - 1],
      stepKeys = Object.keys(steps[k][idKey]), stepKey = Object.keys(steps[k][idKey])[stepKeys.length - 1];

    return { 'id': idKey, 'step': stepKey }
  };

  this.go = function($playoff, k, id, step) {
    let $navigation = $playoff.find(selectors.nav),
      current = $.playoff.getCurrent(k), first = $.playoff.getFirst(k), last = $.playoff.getLast(k);

    steps[k][current.id][current.step] = false;
    steps[k][id][step] = true;

    if (id == first.id && step == first.step) {
      $navigation.find(selectors.arrowPrev).prop('disabled', true).css('opacity', 0.1);
    } else {
      $navigation.find(selectors.arrowPrev).prop('disabled', false).css('opacity', 1);
    }

    if (id == last.id && step == last.step) {
      $navigation.find(selectors.arrowNext).prop('disabled', true).css('opacity', 0.1);
    } else {
      $navigation.find(selectors.arrowNext).prop('disabled', false).css('opacity', 1);
    }
    $navigation.find(selectors.active).removeClass(classes.active).removeClass(cssClasses.active);

    let $step = $navigation.find(selectors.step + '[data-id="' + id + '"][data-step="' + step + '"]'),
      playoffId = $step.data('playoff');

    $step.addClass(classes.active).addClass(cssClasses.active);
    $playoff.find(selectors.cont).hide().filter('[data-playoff="' + playoffId + '"]').show();
  };
};
