import {Link} from "react-router-dom"
import React from "react"
import {
  RatingTeam,
  StatsTeam
} from "../../../api"
import {AppRoute} from "../../../core/constants/routes"

function RatingWidgetRow({index, teamRating, teamStats}: {
  index: number,
  teamRating: RatingTeam,
  teamStats?: StatsTeam
}) {
  return (
    <tr className="table__row">
      <td className="table__cell table__number">№{index + 1}</td>
      <td className="table__cell">
        <Link
          className="table__link table__start"
          to={`${AppRoute.CommandCalendarPage.url}/${teamRating.team_id}`}
        >
          <img
            className="table__img"
            src={
              teamRating.team?.avatar.includes("logo_placeholder_100x100.svg")
                ? "/images/default/team.svg"
                : teamRating.team?.avatar
            }
            width="40"
            height="40"
            alt={teamRating.team?.full_name}
          />
          <p className="table__name">{teamRating.team?.full_name}</p>
        </Link>
      </td>
      <td className="table__cell table__common table__big">{teamRating.points}</td>
      <td className="table__cell show-580">
        <div className="table-widget__wrap">
          <p className="table-widget__label">победы:</p>
          <p className="table-widget__value">{teamStats?.wins || 0}</p>
        </div>
      </td>
      <td className="table__cell show-580">
        <div className="table-widget__wrap">
          <p className="table-widget__label">поражения:</p>
          <p className="table-widget__value">{teamStats?.losses || 0}</p>
        </div>
      </td>
    </tr>
  )
}

export default RatingWidgetRow
