import { State, TournamentsTempsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { TournamentTemp } from '../../../api'

export const getTournamentTempsLoadingStatus = (state: State): boolean =>
  state[NameSpace.TournamentsTemps].isTournamentsTempsLoading;
export const getTournamentTempsErrorStatus = (
  state: State
): TournamentsTempsState["isTournamentsTempsError"] => state[NameSpace.TournamentsTemps].isTournamentsTempsError;
export const getTournamentTemps = (state: State): TournamentTemp[] =>
  state[NameSpace.TournamentsTemps].tournamentsTemps;