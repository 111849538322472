import React from "react";
import FilterRatingPlayersItem from "../../smart/filter-rating-item/filter-rating-players-item";

function FilterRatingPlayersList() {
  return (
    <ul className="filter filter--players">
      <FilterRatingPlayersItem/>
    </ul>
  );
}

export default FilterRatingPlayersList;
