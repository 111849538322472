import { createSlice } from "@reduxjs/toolkit";
import { fetchPage, fetchPageAlias, fetchPages } from "../api-actions";
import { PagesState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";

const initialState: PagesState = {
  isPagesLoading: false,
  isPageLoading: false,
  isPagesError: false,
  isPageError: false,
  pages: [],
  currentPage: null,
};

export const pagesData = createSlice({
  name: NameSpace.Pages,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPages.pending, (state) => {
        state.isPagesLoading = true;
      })
      .addCase(fetchPages.fulfilled, (state, action) => {
        state.pages = action.payload;
        state.isPagesLoading = false;
      })
      .addCase(fetchPages.rejected, (state) => {
        state.isPagesError = true;
        state.isPagesLoading = false;
      })
      .addCase(fetchPage.pending, (state) => {
        state.isPageLoading = true;
      })
      .addCase(fetchPage.fulfilled, (state, action) => {
        state.currentPage = action.payload;
        state.isPageLoading = false;
      })
      .addCase(fetchPage.rejected, (state) => {
        state.isPageError = true;
        state.isPageLoading = false;
      })
      .addCase(fetchPageAlias.pending, (state) => {
        state.isPageLoading = true;
      })
      .addCase(fetchPageAlias.fulfilled, (state, action) => {
        state.currentPage = action.payload;
        state.isPageLoading = false;
      })
      .addCase(fetchPageAlias.rejected, (state) => {
        state.isPageError = true;
        state.isPageLoading = false;
      });
  },
});
