import React from "react";

const ChessIndicator = React.forwardRef<HTMLDivElement>(({}, ref) => {
  return (
    <div className="chess__line">
      <div className="chess__indicator" ref={ref}></div>
    </div>
  );
});

ChessIndicator.displayName = 'ChessIndicator';
export default ChessIndicator;