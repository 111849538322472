import {
  HdbkRegion,
  HdbkSeason,
  Player,
  Round,
  Tournament,
  TeamPlayer,
  HdbkCity,
  HdbkZone,
  Banner,
  BannerPositionEnum,
  MatchSeries,
  RoundTypeEnum,
  Match,
  RoundTableInner,
  RatingPlayer,
  StatsPlayer,
  RatingTeam,
  Post,
  Video,
  StatsTeam
} from "../../api";
import {SorterOption} from "../types/common";

export function filterRegularLeagues(
  leagues: Tournament[] | undefined,
): Tournament[] {
  if (!Array.isArray(leagues)) {
    throw new Error("Входные данные не являются массивом турниров");
  }
  return leagues.filter((league) => {
    const hasRound =
      Array.isArray(league.rounds) &&
      league.rounds.some(
        (round) => round?.round_id !== undefined && round?.round_id !== null,
      );
    const hasRegion =
      league.region_id !== undefined && league.region_id !== null;
    const hasZone = league.zone_id !== undefined && league.zone_id !== null;

    return hasRound || hasRegion || hasZone;
  });
}

export function filterLeaguesByZone(leagues: Tournament[]): Tournament[] {
  if (!Array.isArray(leagues)) {
    throw new Error("Входные данные не являются массивом турниров");
  }
  return leagues.filter(
    (league) => league.type === "league" && league.zone_id !== null,
  );
}

export function filterItemsByDateRange<T extends { published_dt?: string }>(
  items: T[] | null | undefined,
  startDate: Date | null,
  endDate: Date | null,
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items
    .map((item) => ({
      ...item,
      publishedDate: item.published_dt ? new Date(item.published_dt) : null,
    }))
    .filter((item) => {
      const {publishedDate} = item;
      if (!publishedDate) {
        return false;
      }
      return (
        (!startDate || (publishedDate && publishedDate >= startDate)) &&
        (!endDate || (publishedDate && publishedDate <= endDate))
      );
    });
}

export function sortItemsByPublicationDate<T extends { published_dt?: string }>(
  items: T[] | null | undefined,
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items
    .map((item) => ({
      ...item,
      publishedDate: item.published_dt ? new Date(item.published_dt) : null,
    }))
    .sort((a, b) => {
      if (!b.publishedDate && !a.publishedDate) {
        return 0;
      } else if (!a.publishedDate) {
        return 1;
      } else if (!b.publishedDate) {
        return -1;
      } else {
        return b.publishedDate.getTime() - a.publishedDate.getTime();
      }
    });
}

export function sortItemsByStartDate<T extends { start_dt?: string }>(
  items: T[] | null | undefined,
): T[] {
  if (!Array.isArray(items)) {
    console.error("Входные данные не являются массивом объектов");
    return [];
  }
  return items
    .map((item) => ({
      ...item,
      startDate: item.start_dt ? new Date(item.start_dt) : null,
    }))
    .sort((a, b) => {
      if (!b.startDate && !a.startDate) {
        return 0;
      } else if (!a.startDate) {
        return 1;
      } else if (!b.startDate) {
        return -1;
      } else {
        return b.startDate.getTime() - a.startDate.getTime();
      }
    });
}

export const transformSeasonsToOptions = (
  seasons: HdbkSeason[] | null | undefined,
): (
  | {
  label: string;
  value: string;
}
  | { label: string; value: number }
  )[] => {
  if (!seasons || !Array.isArray(seasons)) {
    throw new Error("Ошибка: неверный ввод, сезоны должны быть массивом");
  }
  const allSeasonsOption: { label: string; value: string } = {
    value: "all",
    label: "все сезоны",
  };
  const transformedOptions = seasons.map((season) => {
    if (!season || typeof season !== "object") {
      throw new Error(
        "Ошибка: неверный ввод, каждый элемент в сезонах должен быть объектом",
      );
    }
    const {season_id, title} = season;
    return {
      value: season_id,
      label: title,
    };
  });
  return [allSeasonsOption, ...transformedOptions];
};

export const transformRegionsToOptions = (
  regions: HdbkRegion[] | null | undefined,
): (
  | {
  label: string;
  value: string;
}
  | { label: string; value: number }
  )[] => {
  if (!regions || !Array.isArray(regions)) {
    throw new Error("Ошибка: неверный ввод, регионы должны быть массивом");
  }
  const allRegionsOption: { label: string; value: string } = {
    value: "all",
    label: "все регионы",
  };
  const transformedOptions = regions.map((region) => {
    if (!region || typeof region !== "object") {
      throw new Error(
        "Ошибка: неверный ввод, каждый элемент в регионах должен быть объектом",
      );
    }
    const {region_id, title} = region;
    return {
      value: region_id,
      label: title,
    };
  });
  return [allRegionsOption, ...transformedOptions];
};

export const filterItemsBySeason = <T extends { season?: { title: string } }>(
  items: T[] | null | undefined,
  seasonTitle: string | undefined,
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }
  if (!seasonTitle?.trim()) {
    throw new Error("Неверный ввод: seasonTitle должен быть непустой строкой");
  }
  return items.filter((item) => {
    if (!item || !item.season || !item.season.title) {
      throw new Error(
        "Неверный ввод: каждый элемент должен содержать объект сезона с свойством title типа string",
      );
    }
    return item?.season?.title === seasonTitle;
  });
};

export const filterItemsByRegion = <T extends { region?: { title: string } }>(
  items: T[] | null | undefined,
  regionTitle: string | undefined,
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }
  if (!regionTitle?.trim()) {
    throw new Error("Неверный ввод: regionTitle должен быть непустой строкой");
  }
  return items.filter((item) => {
    return item?.region?.title === regionTitle;
  });
};

export const filterItemsByRegionId = <T extends { region_id?: number }>(
  items: T[] | null | undefined,
  regionId: number | undefined,
): T[] => {
  if (!items || !Array.isArray(items)) {
    console.error("Неверный ввод: items должен быть массивом");
    return [];
  }
  return items.filter((item) => {
    return item?.region_id === regionId;
  });
};

export const filterTeamsRatingByRegionId = (
  teamsRating: RatingTeam[],
  regionId: number | undefined
): RatingTeam[] => {
  if (!teamsRating || !Array.isArray(teamsRating)) {
    console.error("Неверный ввод: teamsRating должен быть массивом")
    return []
  }
  return teamsRating.filter((teamRating) => {
    return teamRating.team?.region_id === regionId
  })
}

export const filterItemsWithTournamentBySeasonId = <T extends { tournament?: { season_id?: number | string } }>(
  items: T[] | null | undefined,
  seasonId: string | number | undefined,
): T[] => {
  if (!items || !Array.isArray(items)) {
    console.error("Неверный ввод: items должен быть массивом");
    return [];
  }
  return items.filter((item) => {
    const itemSeasonId = item.tournament?.season_id;
    if (itemSeasonId === undefined) {
      console.warn("Отсутствует season_id у турнира:", item);
      return false;
    }
    return itemSeasonId === seasonId;
  });
};

export function filterPlayersRatingByString(
  ratingPlayers: RatingPlayer[],
  searchString: string,
): RatingPlayer[] {
  if (!ratingPlayers || !searchString) {
    console.error("Не переданы игроки или строка для поиска");
    return [];
  }
  const filteredPlayers: RatingPlayer[] = [];
  const lowerSearchString = searchString.toLowerCase();
  for (const ratingPlayer of ratingPlayers) {
    if (!ratingPlayer || !ratingPlayer.player?.first_name || !ratingPlayer.player?.last_name) {
      console.error("Ошибка в структуре данных игрока:", ratingPlayer);
      continue;
    }
    const fullName = `${ratingPlayer.player?.first_name} ${ratingPlayer.player?.last_name}`;
    if (
      fullName.toLowerCase().includes(lowerSearchString) ||
      ratingPlayer.player?.last_name.toLowerCase().includes(lowerSearchString)
    ) {
      filteredPlayers.push(ratingPlayer);
    }
  }
  return filteredPlayers;
}

export function filterTeamsRatingByString(
  teamsRating: RatingTeam[],
  searchString: string
): RatingTeam[] {
  if (!teamsRating || !searchString) {
    console.error("Не переданы команды или строка для поиска")
    return []
  }
  const filteredTeamsRating: RatingTeam[] = []
  const lowerSearchString = searchString.toLowerCase()
  for (const teamRating of teamsRating) {
    if (!teamRating || !teamRating.team?.full_name) {
      console.error("Ошибка в структуре данных рейтинга команды:", teamRating)
      continue
    }
    const fullName = teamRating.team.full_name.toLowerCase();
    if (fullName.includes(lowerSearchString)) {
      filteredTeamsRating.push(teamRating)
    }
  }
  return filteredTeamsRating
}

export function filterMatchesByString(
  matches: Match[],
  searchString: string,
): Match[] {
  if (!matches || !searchString) {
    console.error("Не переданы матчи или строка для поиска")
    return []
  }
  const lowerSearchString = searchString.toLowerCase()
  return matches.filter((match) => {
    if (!match) {
      console.error("Ошибка в структуре данных матча:", match)
      return false
    }
    const tournamentName = match.tournament?.full_name?.toLowerCase() || ""
    if (tournamentName.includes(lowerSearchString)) {
      return true
    }
    const players = match.players || []
    for (const player of players) {
      if (!player || !player.first_name || !player.last_name) {
        console.error("Ошибка в структуре данных игрока:", player)
        continue
      }
      const fullName = `${player.first_name} ${player.last_name}`.toLowerCase()
      if (
        fullName.includes(lowerSearchString) ||
        player.last_name.toLowerCase().includes(lowerSearchString)
      ) {
        return true
      }
    }
    return false
  });
}

export const filterItemsByStage = (
  tournaments: Tournament[] | null | undefined,
  roundsType: string | undefined,
): Tournament[] => {
  if (!tournaments || !Array.isArray(tournaments)) {
    throw new Error("Неверный ввод: tournaments должен быть массивом");
  }

  if (!roundsType?.trim()) {
    throw new Error("Неверный ввод: roundsType должен быть непустой строкой");
  }

  return tournaments.filter((tournament) => {
    if (
      !tournament ||
      !tournament.rounds ||
      !Array.isArray(tournament.rounds)
    ) {
      throw new Error(
        "Неверный ввод: Каждый турнир должен иметь массив раундов",
      );
    }

    if (roundsType === "региональный плей-офф") {
      return tournament.region_id !== null && tournament.rounds.length > 0;
    }

    if (roundsType === "всероссийский плей-офф") {
      return Array.isArray(tournament.rounds) && tournament.region_id == null;
    }

    if (roundsType === "региональный этап") {
      return Array.isArray(tournament.rounds) && tournament.zone_id !== null;
    }
  });
};

export const filterMediaBySeason = <T extends { published_dt?: string }>(
  items: T[] | null | undefined,
  targetYear: string | undefined,
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }

  if (!targetYear?.trim()) {
    throw new Error("Неверный ввод: targetYear должен быть непустой строкой");
  }

  return items.filter((item) => {
    if (!item || typeof item.published_dt !== "string") {
      throw new Error(
        "Неверный ввод: каждый item должен содержать свойство published_dt типа string",
      );
    }

    const publishedYear = new Date(item.published_dt).getFullYear().toString();
    return publishedYear === targetYear;
  });
};

export function sortItemsByRank<T extends { rank?: number }>(
  items: T[] | null | undefined,
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом");
  }

  return [...items].sort((a, b) => {
    const rankA = a.rank ?? Number.MIN_SAFE_INTEGER;
    const rankB = b.rank ?? Number.MIN_SAFE_INTEGER;

    return rankB - rankA;
  });
}

export function filterRoundsByTypes(
  rounds: Round[] | undefined,
  types: string[],
): Round[] {
  if (!rounds || !Array.isArray(rounds)) {
    console.error("Предоставлен неверный массив этапов.");
    return [];
  }
  if (!types || !Array.isArray(types) || types.length === 0) {
    console.error("Предоставлен неверный массив типов.");
    return [];
  }
  const filteredRounds = rounds.filter((round) => types.includes(round.type));
  if (filteredRounds.length === 0) {
    console.warn(`Этапов с указанными типами не найдено.`);
  }
  return filteredRounds;
}

export const getCaptain = (teamPlayers: TeamPlayer[]): Player | null => {
  if (!Array.isArray(teamPlayers)) {
    console.error("teamPlayers должен быть массивом");
    return null;
  }
  for (const teamPlayer of teamPlayers) {
    if (!teamPlayer || !teamPlayer.player) {
      console.error("Некорректная структура teamPlayers");
      continue;
    }
    if (teamPlayer.captain === 1) {
      return teamPlayer.player;
    }
  }
  console.error("Капитан не найден");
  return null;
};

export const transformCitiesToOptions = (
  cities: HdbkCity[] | null | undefined
): (
  | {
      label: string;
      value: string;
    }
  | { label: string; value: number }
)[] => {
  if (!cities || !Array.isArray(cities)) {
    throw new Error("Ошибка: неверный ввод, города должны быть массивом");
  }
  const transformedOptions = cities.map((city) => {
    if (!city || typeof city !== "object") {
      throw new Error(
        "Ошибка: неверный ввод, каждый элемент в сезонах должен быть объектом"
      );
    }
    const { city_id, title } = city;
    return {
      value: city_id,
      label: title,
    };
  });
  return [...transformedOptions];
};

export const transformZonesToOptions = (
  zones: HdbkZone[] | null | undefined
): (
  | {
      label: string;
      value: string;
    }
  | { label: string; value: number }
)[] => {
  if (!zones || !Array.isArray(zones)) {
    throw new Error("Ошибка: неверный ввод, зоны должны быть массивом");
  }
  const transformedOptions = zones.map((zone) => {
    if (!zone || typeof zone !== "object") {
      throw new Error(
        "Ошибка: неверный ввод, каждый элемент в зонах должен быть объектом"
      );
    }
    const { zone_id, title } = zone;
    return {
      value: zone_id,
      label: title,
    };
  });
  return [...transformedOptions];
}

export function filterItemsByMain<T extends { on_main?: number }>(
  items: T[] | null | undefined,
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items.filter((item) => item && item.on_main);
}

export function getTopItemsByPublishedDt<T extends { published_dt?: string }>(
  items: T[] | null | undefined,
  count: number,
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  if (count <= 0) {
    throw new Error("Количество новостей должно быть положительным числом");
  }
  const sortedItems = sortItemsByPublicationDate(items);
  return sortedItems.slice(0, count);
}

export function getTopItemsByStartDt<T extends { start_dt?: string }>(
  items: T[] | null | undefined,
  count: number,
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  if (count <= 0) {
    throw new Error("Количество новостей должно быть положительным числом");
  }
  const sortedItems = sortItemsByStartDate(items);
  return sortedItems.slice(0, count);
}

export function filterBannersByPosition(
  banners: Banner[] | null | undefined,
  targetPosition: BannerPositionEnum,
): Banner[] {
  if (!Array.isArray(banners)) {
    throw new Error("Входные данные не являются массивом баннеров");
  }
  return banners.filter(
    (banner) => banner && banner.position === targetPosition,
  );
}

export function filterMatchSeriesArrayByTournamentType(
  series: MatchSeries[],
  tournamentType: string | number | undefined,
): MatchSeries[] {
  if (!Array.isArray(series) || series.length === 0) {
    console.error("Массив серий пуст или не является массивом.");
  }
  if (!["league", "commercial"].includes(<string>tournamentType)) {
    console.error("Недопустимый тип турнира.");
  }
  const filteredSeries = series.filter(
    (series) => series.tournament && series.tournament.type === tournamentType,
  );
  if (filteredSeries.length === 0) {
    console.error("Не найдено серий для указанного типа турнира.");
  }
  return filteredSeries;
}

export const filterMatchSeriesArrayByStage = (
  matchSeriesArray: MatchSeries[] | null | undefined,
  roundsType: string | undefined,
): MatchSeries[] => {
  if (!matchSeriesArray || !Array.isArray(matchSeriesArray)) {
    console.error("Неверный ввод: matchSeriesArray должен быть массивом");
    return [];
  }
  if (!roundsType?.trim()) {
    console.error("Неверный ввод: roundsType должен быть непустой строкой");
  }
  return matchSeriesArray.filter((matchSeries) => {
    if (roundsType === "региональный плей-офф") {
      return (
        matchSeries.round?.type === RoundTypeEnum.PlayoffOlympic &&
        matchSeries.tournament?.region_id !== null
      );
    }
    if (roundsType === "всероссийский плей-офф") {
      return (
        matchSeries.round?.type === RoundTypeEnum.PlayoffOlympic &&
        matchSeries.tournament?.region_id == null
      );
    }
    if (roundsType === "региональный этап") {
      return matchSeries.tournament?.zone_id !== null;
    }
  });
};

export function sortRoundTablesInner(
  roundTablesInner: RoundTableInner[] | undefined,
  sorterOption: SorterOption
): RoundTableInner[] {
  if (!Array.isArray(roundTablesInner)) {
    console.error("Неверный ввод: roundTablesInner должен быть массивом");
    return [];
  }
  if (sorterOption.direction === 0 || !sorterOption.key) {
    return roundTablesInner;
  }
  return roundTablesInner.sort((a, b) => {
    let valueA: string | number = '';
    let valueB: string | number = '';
    switch (sorterOption.key) {
      case 'команда':
        valueA = a.team?.full_name || '';
        valueB = b.team?.full_name || '';
        break;
      case 'победы':
        valueA = a.wins || 0;
        valueB = b.wins || 0;
        break;
      case 'поражений':
        valueA = a.losses || 0;
        valueB = b.losses || 0;
        break;
      case 'очки':
        valueA = a.points || 0;
        valueB = b.points || 0;
        break;
      default:
        throw new Error(`Недопустимый ключ сортировки: ${sorterOption.key}`);
    }
    if (sorterOption.direction === 1) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }
    } else if (sorterOption.direction === 2) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueB - valueA;
      }
    } else {
      throw new Error('Недопустимый параметр направления. Используйте 0 для отсутствия сортировки, 1 для сортировки по возрастанию или 2 для сортировки по убыванию.');
    }
    return 0;
  });
}
export function sortPlayersRating (
  playersRating: RatingPlayer[] | undefined,
  playersStats: StatsPlayer[] | undefined,
  sorterOption: SorterOption
): RatingPlayer[] {
  if (!Array.isArray(playersRating)) {
    console.error("Неверный ввод: playersRating должен быть массивом");
    return [];
  }
  if (sorterOption.direction === 0 || !sorterOption.key) {
    return playersRating;
  }
  return playersRating.sort((a, b) => {
    const playerStatsA = findPlayerStatsById(playersStats, a.player_id)
    const playerStatsB = findPlayerStatsById(playersStats, b.player_id)
    let valueA: string | number = '';
    let valueB: string | number = '';
    switch (sorterOption.key) {
      case 'игроки':
        valueA = `${a.player?.first_name} ${a.player?.last_name}` || '';
        valueB = `${b.player?.first_name} ${b.player?.last_name}` || '';
        break;
      case 'КПД игроков':
        valueA = a.points || 0;
        valueB = b.points || 0;
        break;
      case 'победы':
        valueA = playerStatsA?.wins || 0;
        valueB = playerStatsB?.wins || 0;
        break;
      case 'поражений':
        valueA = playerStatsA?.losses || 0;
        valueB = playerStatsB?.losses || 0;
        break;
      default:
        throw new Error(`Недопустимый ключ сортировки: ${sorterOption.key}`);
    }
    if (sorterOption.direction === 1) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }
    } else if (sorterOption.direction === 2) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueB - valueA;
      }
    } else {
      throw new Error('Недопустимый параметр направления. Используйте 0 для отсутствия сортировки, 1 для сортировки по возрастанию или 2 для сортировки по убыванию.');
    }
    return 0;
  });
}

export function sortTeamsRating (
  teamsRating: RatingTeam[] | undefined,
  teamsStats: StatsTeam[] | undefined,
  sorterOption: SorterOption
): RatingTeam[] {
  if (!Array.isArray(teamsRating)) {
    console.error("Неверный ввод: teamsRating должен быть массивом");
    return [];
  }
  if (sorterOption.direction === 0 || !sorterOption.key) {
    return teamsRating;
  }
  return teamsRating.sort((a, b) => {
    const teamStatsA = findTeamStatsById(teamsStats, a.team_id)
    const teamStatsB = findTeamStatsById(teamsStats, b.team_id)
    let valueA: string | number = '';
    let valueB: string | number = '';
    switch (sorterOption.key) {
      case 'команды':
        valueA = a.team?.full_name || '';
        valueB = b.team?.full_name || '';
        break;
      case 'сыграно матчей':
        valueA = teamStatsA?.games || 0;
        valueB = teamStatsA?.games || 0;
        break;
      case 'победы':
        valueA = teamStatsA?.wins || 0;
        valueB = teamStatsB?.wins || 0;
        break;
      case 'поражений':
        valueA = teamStatsA?.losses || 0;
        valueB = teamStatsB?.losses || 0;
        break;
      case 'рейтинг':
        valueA = a.points || 0;
        valueB = b.points || 0;
        break;
      default:
        throw new Error(`Недопустимый ключ сортировки: ${sorterOption.key}`);
    }
    if (sorterOption.direction === 1) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }
    } else if (sorterOption.direction === 2) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueB - valueA;
      }
    } else {
      throw new Error('Недопустимый параметр направления. Используйте 0 для отсутствия сортировки, 1 для сортировки по возрастанию или 2 для сортировки по убыванию.');
    }
    return 0;
  });
}

export function sortMatches(
  matches: Match[] | undefined,
  sorterOption: SorterOption
): Match[] {
  if (!Array.isArray(matches)) {
    console.error("Неверный ввод: matches должен быть массивом")
    return []
  }
  if (sorterOption.direction === 0 || !sorterOption.key) {
    return matches
  }
  return matches.sort((a, b) => {
    let valueA: string | number | Date = ''
    let valueB: string | number | Date = ''
    switch (sorterOption.key) {
      case 'дата':
        valueA = a.start_dt ? new Date(a.start_dt) : new Date(0)
        valueB = b.start_dt ? new Date(b.start_dt) : new Date(0)
        break
      case 'турнир':
        valueA = a.tournament?.full_name || ''
        valueB = b.tournament?.full_name || ''
        break
      default:
        throw new Error(`Недопустимый ключ сортировки: ${sorterOption.key}`)
    }
    if (sorterOption.direction === 1) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB)
      } else if (valueA instanceof Date && valueB instanceof Date) {
        return valueA.getTime() - valueB.getTime()
      }
    } else if (sorterOption.direction === 2) {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA)
      } else if (valueA instanceof Date && valueB instanceof Date) {
        return valueB.getTime() - valueA.getTime()
      }
    } else {
      throw new Error('Недопустимый параметр направления. Используйте 0 для отсутствия сортировки, 1 для сортировки по возрастанию или 2 для сортировки по убыванию.')
    }
    return 0
  })
}

export function findPlayerStatsById(playersStats: StatsPlayer[] | undefined, playerId: number): StatsPlayer | undefined {
  if (!Array.isArray(playersStats)) {
    console.error("Ошибка: Первый аргумент должен быть массивом статистикой игроков.")
    return undefined
  }
  const foundPlayerStats = playersStats.find((playerStats) => playerStats.player_id === playerId);
  if (!foundPlayerStats) {
    console.error(`Ошибка: Статистика игрока с идентификатором ${playerId} не найдена.`)
    return undefined;
  }
  return foundPlayerStats
}

export function excludePostById(
  posts: Post[],
  postId: string | number | undefined
): Post[] {
  if (!Array.isArray(posts)) {
    console.error("Ошибка: posts должен быть массивом.")
    return []
  }
  const postIdNumber = Number(postId)
  if (isNaN(postIdNumber)) {
    console.error("Ошибка: postId не является числом.")
    return []
  }
  if (posts.some((post) => post.post_id === undefined)) {
    console.error("Ошибка: некоторые посты не содержат post_id.")
    return []
  }
  return posts.filter((post) => post.post_id !== postIdNumber)
}

export function excludeVideoById(
  videos: Video[],
  videoId: string | number | undefined
): Video[] {
  if (!Array.isArray(videos)) {
    console.error("Ошибка: videos должен быть массивом.")
    return []
  }
  const videoIdNumber = Number(videoId)
  if (isNaN(videoIdNumber)) {
    console.error("Ошибка: videoId не является числом.")
    return []
  }
  if (videos.some((video) => video.video_id === undefined)) {
    console.error("Ошибка: некоторые видео не содержат video_id.")
    return []
  }
  return videos.filter((video) => video.video_id !== videoIdNumber)
}

export function findTeamStatsById(teamsStats: StatsTeam[] | undefined, teamId: number): StatsTeam | undefined {
  if (!Array.isArray(teamsStats)) {
    console.error("Ошибка: Первый аргумент должен быть массивом статистикой команд.")
    return undefined
  }
  const foundTeamStats = teamsStats.find((teamStats) => teamStats.team_id === teamId)
  if (!foundTeamStats) {
    console.error(`Ошибка: Статистика команды с идентификатором ${teamId} не найдена.`)
    return undefined;
  }
  return foundTeamStats
}

export function filterTournamentsByString(
  tournaments: Tournament[],
  searchString: string,
): Tournament[] {
  if (!tournaments || !searchString) {
    console.error("Не переданы турниры или строка для поиска");
    return [];
  }
  const filteredTournaments: Tournament[] = [];
  const lowerSearchString = searchString.toLowerCase();
  for (const tournament of tournaments) {
    if (!tournament || !tournament.full_name || !tournament.short_name) {
      console.error("Ошибка в структуре данных турнира:", tournament);
      continue;
    }
    const fullName = `${tournament.full_name} ${tournament.short_name}`;
    if (
      fullName.toLowerCase().includes(lowerSearchString) ||
      tournament.short_name.toLowerCase().includes(lowerSearchString)
    ) {
      filteredTournaments.push(tournament);
    }
  }
  return filteredTournaments;
}