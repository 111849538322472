import React from "react";
import Dropdown from "../../smart/dropdown/dropdown";
import { DropdownTypes, ItemsForFilter } from "../../../core/constants/filters";
import { useAppSelector } from "../../../core/hooks";
import { getSeasons } from "../../../core/store/seasons-data/selectors";
import { transformSeasonsToOptions } from "../../../core/utils/array-prepare-helpers";
import PayButton from "../pay-button/pay-button";
import { Team } from '../../../api'

function PaymentForm({ role, team }: { role: number, team: Team }) {
  const seasons = useAppSelector(getSeasons);
  const seasonsOptions = transformSeasonsToOptions(seasons);
  if (!team || !team.product) {
    return null;
  }

  return (
    <div className="payment">
      <div className={`payment__item ${role === 34 ? "" : "hidden"}`}>
        <div className="payment__content">
          <p className="payment__title">Регистрация команды</p>
        </div>
        <div className="payment__pay">
          <p className="payment__price">{team.product.price} руб.</p>
          <PayButton title="Оплатить" className="save--pay" />
        </div>
      </div>
      <div className={`payment__item ${role === 66 ? "" : "hidden"}`}>
        <div className="payment__content">
          <p className="payment__title">Регистрация турнира</p>
          <label className="form">
            <p className="form__text">Название турнира</p>
            <Dropdown
              className="select--registration"
              options={seasonsOptions}
              type={DropdownTypes.Season}
              items={ItemsForFilter.Tournaments}
            />
          </label>
        </div>
        <div className="payment__pay">
          <p className="payment__price">21 000 руб.</p>
          <PayButton title="Оплатить" className="save--pay" />
        </div>
      </div>
      <div className={`payment__item ${role === 34 ? "" : "hidden"}`}>
        <div className="payment__content">
          <p className="payment__title">Оплата за матч</p>
          <label className="form">
            <p className="form__text">Матч</p>
            <Dropdown
              className="select--registration"
              options={seasonsOptions}
              type={DropdownTypes.Season}
              items={ItemsForFilter.Tournaments}
            />
          </label>
        </div>
        <div className="payment__pay">
          <p className="payment__price">2 000 руб.</p>
          <PayButton title="Оплатить" className="save--pay" />
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
