import React from "react";

function PartnersItem({icon, width, height}: {icon: string, width: string, height: string}) {
  return (
    <li className="partners__item">
      <img className="partners__img" src={`/images/${icon}`} width={width} height={height} alt="Логотип партнера"/>
    </li>
  );
}

export default PartnersItem;
