import React from "react";
import CommandsCompoundForm from "../../ordinary/commands-compound-form/commands-compound-form";
import PlayerPhoto from "../../ordinary/player-photo/player-photo";
import { TeamTempPlayer, TeamTempPlayerRequest } from '../../../api'

function CompoundCommandsItem({
  formik,
  player,
  index,
  onRemove,
  onAdd,
  disableRemove,

}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  player: TeamTempPlayer | TeamTempPlayerRequest;
  index: number;
  onRemove: () => void;
  onAdd: () => void;
  disableRemove:boolean;
}) {
  return (
    <li className="compound__item">
      <p className="compound__number">{index + 1}.</p>
      <PlayerPhoto
        profile={player.photo || "player.svg"}
        onFileChange={(file) => {
          formik.setFieldValue(`players[${index}].photo`, file);
        }} index={index}
      />
      <CommandsCompoundForm
        formik={formik}
        player={player}
        index={index}
        onRemove={onRemove}
        onAdd={onAdd}
        disableRemove={disableRemove}
      />
    </li>
  );
}

export default CompoundCommandsItem;
