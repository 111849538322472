import React from 'react';
import {Link} from "react-router-dom";

function CommandItem({type, name, text}: {
  name: string,
  text: string,
  type?: string;
}) {
  return (
    <li className="command__item">
      <p className="command__name">{name}</p>
      {!type && (
        <p className="command__text">{text}</p>
      )}
      {type === "link" && (
        <Link className="command__link" to="#">{text}</Link>
      )}
    </li>
  );
}

export default CommandItem;
