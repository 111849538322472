import {createSlice} from '@reduxjs/toolkit';
import {fetchZones} from '../api-actions';
import {ZonesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: ZonesState = {
  isZonesLoading: false,
  isZonesError: false,
  zones: [],
};

export const zonesData = createSlice({
  name: NameSpace.Zones,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchZones.pending, (state) => {
        state.isZonesLoading = true;
      })
      .addCase(fetchZones.fulfilled, (state, action) => {
        state.zones = action.payload;
        state.isZonesLoading = false;
      })
      .addCase(fetchZones.rejected, (state) => {
        state.isZonesError = true;
        state.isZonesLoading = false;
      })
  }
});
