import React from "react";
import DocumentsItem from "../documents-item/documents-item";
import Title from "../../ui/title/title";
import {HdbkDocument} from "../../../api";

function DocumentsList({text, className, documents}: {
  text?: string;
  className?: string;
  documents?: HdbkDocument[];
}) {
  return (
    <div className={`documents ${className || ""} ${documents?.length === 0 ? 'hidden' : ''}`}>
      {text && <Title className="title--center" title={text}/>}
      <ul className="documents__list">
        {documents?.map((document) => (
          <DocumentsItem key={document.document_id} document={document}/>
        ))}
      </ul>
    </div>
  );
}

export default DocumentsList;
