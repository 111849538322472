import React, {
  useState,
  useEffect
} from "react";
import {useLocation} from "react-router-dom";
import {isSafari} from "../../../core/utils/common-helpers";

function Burger() {
  const [isBurgerOpen, setBurgerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      setBurgerOpen(false);
      if (isSafari()) {
        document.body.classList.remove("fixed-safari");
        document.documentElement.classList.remove("fixed-safari");
      } else {
        document.body.classList.remove("fixed");
      }
    };

    handleRouteChange();
  }, [location]);

  const handleBurgerClick = () => {
    setBurgerOpen(!isBurgerOpen);
    if (isSafari()) {
      document.body.classList.toggle("fixed-safari");
      document.documentElement.classList.toggle("fixed-safari");
    } else {
      document.body.classList.toggle("fixed");
    }
  };

  return (
    <button className={`burger ${isBurgerOpen ? "burger--open" : ""}`} onClick={handleBurgerClick}>
      <div className="burger__container">
        <span></span>
      </div>
    </button>
  );
}

export default Burger;
