import {TeamsState} from "../../types/state";
import {createSlice} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {
  fetchTeam,
  fetchTeams,
  fetchTeamsMine
} from "../api-actions";

const initialState: TeamsState = {
  isTeamsLoading: false,
  isTeamsError: false,
  teams: [],
  isTeamLoading: false,
  isTeamError: false,
  team: null
};

export const teamsData = createSlice({
  name: NameSpace.Teams,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.isTeamsLoading = true;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.isTeamsLoading = false;
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.isTeamsError = true;
        state.isTeamsLoading = false;
      })
      .addCase(fetchTeam.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(fetchTeam.fulfilled, (state, action) => {
        state.team = action.payload;
        state.isTeamLoading = false;
      })
      .addCase(fetchTeam.rejected, (state) => {
        state.isTeamError = true;
        state.isTeamLoading = false;
      })
      .addCase(fetchTeamsMine.pending, (state) => {
        state.isTeamsLoading = true;
      })
      .addCase(fetchTeamsMine.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.isTeamsLoading = false;
      })
      .addCase(fetchTeamsMine.rejected, (state) => {
        state.isTeamsError = true;
        state.isTeamsLoading = false;
      });
  }
});
