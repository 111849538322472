import React from "react";
import { Link } from "react-router-dom";
import {RoundTableInner} from "../../../api";
import {AppRoute} from "../../../core/constants/routes";

function RatingRow({roundTableInner, index}: { roundTableInner: RoundTableInner, index: number  }) {
  return (
    <tr className="table__row">
      <td className="table__cell table__number">{index + 1}</td>
      <td className="table__cell">
        <Link
          className="table__link table__start"
          to={`${AppRoute.CommandCalendarPage.url}/${roundTableInner.team?.team_id}`}
        >
          <img
            className="table__img"
            src={
              roundTableInner.team?.avatar.includes("logo_placeholder_100x100.svg")
                ? "/images/default/team.svg"
                : roundTableInner.team?.avatar
            }
            width="25"
            height="25"
            alt="Логотип команды"
          />
          <p className="table__name">{roundTableInner.team?.full_name}</p>
        </Link>
      </td>
      <td className="table__cell">{roundTableInner.wins}</td>
      <td className="table__cell">{roundTableInner.losses}</td>
      <td className="table__cell">{roundTableInner.points}</td>
    </tr>
  );
}

export default RatingRow;
