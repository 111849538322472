import React, {
  useEffect,
  useState
} from "react"
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import Title from "../../components/ui/title/title"
import {AppRoute} from "../../core/constants/routes"
import ButtonsList from "../../components/ui/buttons-list/buttons-list"
import TournamentForm from "../../components/ordinary/tournament-form/tournament-form"
import ButtonForm from "../../components/ui/button-form/button-form"
import {
  useAppDispatch,
  useAppSelector,
  useFilteredButtons,
} from "../../core/hooks"
import {getAuthorizationUserId} from "../../core/store/authorization-data/selectors"
import {getTournamentTemps} from "../../core/store/tournaments-temps-data/selectors"
import {
  createTournament,
  fetchTournamentsTempsMine,
} from "../../core/store/api-actions"
import {
  FormikProvider,
  useFormik
} from "formik"
import {registrationTournamentFormValidationSchema} from "../../core/utils/form-validate-helpers"
import RequestApplicationList from "../../components/ordinary/request-application-list/request-application-list"
import {changeMessageModal} from "../../core/store/modals-data/modals-data"
import { getCreateTournamentStatus } from '../../core/store/create-tournament-data/selectors'

function RegistrationTournamentPage() {
  const dispatch = useAppDispatch()
  const crumbsRoutes = [AppRoute.Main, AppRoute.RegistrationTournamentPage]
  const filteredButtons = useFilteredButtons()
  const userId = useAppSelector(getAuthorizationUserId)
  const tournamentTemps = useAppSelector(getTournamentTemps)
  const createTournamentSuccess = useAppSelector(getCreateTournamentStatus)
  const [showForm, setShowForm] = useState<boolean>(false)

  const initialValues = {
    user_id: userId || "",
    full_name: "",
    short_name: "",
    club: "",
    club_address: "",
    start_dt: "",
    end_dt: "",
    city_id: "",
    zone_id: "",
    court_coverage: "",
    official_ball: "",
    contact_info: "",
    is_draft: 0,
  }
  const validationSchema = registrationTournamentFormValidationSchema

  useEffect(() => {
    if (userId) {
      dispatch(fetchTournamentsTempsMine(Number(userId)))
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (createTournamentSuccess) {
      dispatch(changeMessageModal({ messageModal: true }));
    }
  }, [createTournamentSuccess, dispatch]);

  const handleSaveDraft = () => {
    formik.setFieldValue("is_draft", 1)
    formik.handleSubmit()
  }

  const handleSaveAndSubmit = async () => {
    formik.setFieldValue("is_draft", 0)
    await formik.validateForm()
    if (Object.keys(formik.errors).length === 0) {
      formik.submitForm()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    dispatch(createTournament(values))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const handleCreateApplication = () => {
    setShowForm(true)
  }

  const renderForm = () => (
    <FormikProvider value={formik}>
      <form className="gap gap-40" onSubmit={formik.handleSubmit}>
        <div className="background-form">
          <TournamentForm formik={formik}/>
        </div>
      </form>
      <div className="saves">
        <ButtonForm
          className="save--green"
          title="Сохранить"
          onClick={handleSaveDraft}
        />
        <ButtonForm
          title="Сохранить и отправить на проверку"
          onClick={handleSaveAndSubmit}
        />
      </div>
    </FormikProvider>
  )

  return (
    <div className="container">
      <div className="gap gap--20">
        <CrumbsList routes={crumbsRoutes}/>
        <div className="gap gap--20">
          <div className="gap gap--row gap--15">
            <div className="gap gap--50 gap--row-2">
              <Title title="Регистрация турнира" className="title--uppercase"/>
            </div>
            <ButtonsList buttonsData={filteredButtons}/>
          </div>
        </div>
        {tournamentTemps.length === 0 && !showForm ? renderForm() : null}
        {tournamentTemps.length > 0 && !showForm ? (
          <RequestApplicationList temps={tournamentTemps} type="tournament"/>
        ) : null}
          <div className={!showForm ? "saves" : "hidden"}>
            <ButtonForm
              className={tournamentTemps.length > 0 ? "save" : "hidden"}
              title="Создать заявку"
              onClick={handleCreateApplication}
            />
          </div>
        {showForm && renderForm()}
      </div>
    </div>
  )
}

export default RegistrationTournamentPage
