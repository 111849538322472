import React, {
  useEffect,
  useState
} from "react"
import Sorting from "../sorting/sorting"
import RatingPlayersRow from "../rating-players-row/rating-players-row"
import {
  RatingPlayer,
  StatsPlayer
} from "../../../api"
import {
  findPlayerStatsById,
  sortPlayersRating
} from "../../../core/utils/array-prepare-helpers"
import {playersRatingSorters} from "../../../core/constants/sorters"
import {ItemsForSort} from "../../../core/constants/filters"
import {useAppSelector} from "../../../core/hooks"
import {getPlayersRatingSorterStatus} from "../../../core/store/players-rating-data/selectors"

function RatingPlayersTable({playersRating, playersStats}: {
  playersRating: RatingPlayer[],
  playersStats: StatsPlayer[]
}) {
  const sorterStatus = useAppSelector(getPlayersRatingSorterStatus)
  const [sortedPlayersRating, setSortedPlayersRating] = useState<RatingPlayer[]>(playersRating || [])

  useEffect(() => {
    setSortedPlayersRating(sortPlayersRating([...playersRating || []], playersStats, sorterStatus));
  }, [sorterStatus])

  useEffect(() => {
    setSortedPlayersRating([...playersRating || []])
  }, [playersRating])

  return (
    <div className={`table-scroll ${playersRating.length === 0 ? 'hidden' : ''}`}>
      <div className="table-border">
        <table className="table">
          <thead className="table__thead">
          <tr className="table__row">
            <th className="table__th table__number">
              <p>№</p>
            </th>
            {playersRatingSorters.map((playerRatingSorter) => (
              <th className="table__th" key={playerRatingSorter}>
                <Sorting
                  title={playerRatingSorter}
                  items={ItemsForSort.PlayersRating}
                />
              </th>
            ))}
          </tr>
          </thead>
          <tbody className="table__tbody">
          {sortedPlayersRating.map((playerRating, index) => (
            <RatingPlayersRow
              key={playerRating.player_id}
              playerRating={playerRating}
              playerStats={findPlayerStatsById(playersStats, playerRating.player_id)}
              index={index}
            />
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RatingPlayersTable

