import React from "react";
import FilterRatingCommandsItem from "../../smart/filter-rating-commands-item/filter-rating-commands-item";

function FilterRatingCommandsList() {

  return (
    <ul className="filter filter--commands">
      <FilterRatingCommandsItem/>
    </ul>
  );
}

export default FilterRatingCommandsList;
