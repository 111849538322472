import React from "react";
import {Link} from "react-router-dom";

function SocialItem({social}: {social: string}) {
  return (
    <li className="social__item">
      <Link className="social__link" to="#">
        <svg className="social__icon" width="15" height="15">
          <use xlinkHref={`images/sprite.svg#${social}`} />
        </svg>
      </Link>
    </li>
  );
}

export default SocialItem;
