import React from "react";
import { Link } from "react-router-dom";
import Title from "../../ui/title/title";
import { Page } from "../../../api";

function Contact({ page }: { page?: Page | null }) {
  return (
    <div
      className={`contact ${!page?.address && !page?.email ? "hidden" : ""}`}
    >
      <div className="contact__block">
        <div className="contact__texts">
          <Title className="title--uppercase" title="контакты" />
          <p className="contact__subtitle">Центральный офис</p>
        </div>
        <ul className="contact__list">
          <li className={`contact__item ${!page?.address ? "hidden" : ""}`}>
            <p className="contact__data">Адрес:</p>
            <p className="contact__text">{page?.address}</p>
          </li>
          <li className={`contact__item ${!page?.email ? "hidden" : ""}`}>
            <p className="contact__data">E-mail:</p>
            <Link className="contact__text" to={`mailto:${page?.email}`}>
              {page?.email}
            </Link>
          </li>
        </ul>
      </div>
      <img
        className={`contact__map ${!page?.address ? "hidden" : ""}`}
        src="/images/map.png"
        width="600"
        height="330"
        alt="Карта"
      />
    </div>
  );
}

export default Contact;
