import React, {ReactElement} from 'react';
import Promo from "../promo/promo";

function MainWrapper({children}: { children: ReactElement }) {

  return (
    <main className="main">
      <Promo/>
      {children}
    </main>
  );
}

export default MainWrapper;
