import React, { useEffect, useMemo, useState } from "react";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import Pagination from "../../components/ordinary/pagination/pagination";
import ContentList from "../../components/ordinary/content-list/content-list";

import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getAlbums,
  getAlbumsErrorStatus,
  getAlbumsFilterSeasonStatus,
  getAlbumsLoadingStatus,
} from "../../core/store/albums-data/selectors";
import {
  filterMediaBySeason,
  sortItemsByPublicationDate,
} from "../../core/utils/array-prepare-helpers";
import { CONTENT_PER_PAGE } from "../../core/constants/common";
import FilterPagesList from "../../components/simple/filter-pages-list/filter-pages-list";
import { fetchAlbums } from "../../core/store/api-actions";
import { getSeasonsLoadingStatus } from "../../core/store/seasons-data/selectors";
import Stub from "../../components/ordinary/stub/stub";

function AlbumsPage() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.AlbumsPage];
  const albums = useAppSelector(getAlbums);
  const albumsErrorStatus = useAppSelector(getAlbumsErrorStatus);
  const albumsLoadingStatus = useAppSelector(getAlbumsLoadingStatus);
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus);
  const filterSeasonStatus = useAppSelector(getAlbumsFilterSeasonStatus);

  useEffect(() => {
    dispatch(fetchAlbums());
  }, [dispatch]);

  const createProcessedAlbums = useMemo(() => {
    let processedAlbums = albums;
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      processedAlbums = filterMediaBySeason(
        processedAlbums,
        filterSeasonStatus.label
      );
    }
    return sortItemsByPublicationDate(processedAlbums);
  }, [albums, filterSeasonStatus]);

  const [currentPage, setCurrentPage] = useState(0);
  const albumsPerPage = CONTENT_PER_PAGE;
  const pageCount = Math.ceil(
    (createProcessedAlbums?.length || 0) / albumsPerPage
  );
  const indexOfLastAlbum = (currentPage + 1) * albumsPerPage;
  const indexOfFirstAlbum = indexOfLastAlbum - albumsPerPage;
  const currentAlbums = albums
    ? createProcessedAlbums.slice(indexOfFirstAlbum, indexOfLastAlbum)
    : [];

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const albumsPageLoading = albumsLoadingStatus || seasonsLoading;

  return (
    <div className="container-y">
      <div className={`gap gap--20 ${albumsPageLoading ? "hidden" : ""}`}>
        <div className="container-x">
          <div className="gap gap--20">
            <CrumbsList routes={crumbsRoutes} />
            <div className="gap gap--row">
              <Title title="Фотоальбомы" />
              <FilterPagesList />
            </div>
          </div>
        </div>
        <Stub
          type={
            albumsErrorStatus
              ? "error"
              : albums.length > 0 && createProcessedAlbums.length === 0
              ? "searchError"
              : albums.length === 0
              ? "absence"
              : ""
          }
          error={
            albumsErrorStatus ? "Произошла ошибка при загрузке контента." : ""
          }
        />
        <div className="gap gap--40">
          <ContentList items={currentAlbums} />
          {pageCount > 1 && (
            <Pagination
              pageCount={pageCount}
              forcePage={currentPage}
              onPageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AlbumsPage;
