import { BannersState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { Banner } from "../../../api";

export const getBannersLoadingStatus = (state: State): boolean =>
  state[NameSpace.Banners].isBannersLoading;
export const getBannerLoadingStatus = (state: State): boolean =>
  state[NameSpace.Banners].isBannerLoading;
export const getBannersErrorStatus = (
  state: State
): BannersState["isBannersError"] => state[NameSpace.Banners].isBannersError;
export const getBannerErrorStatus = (
  state: State
): BannersState["isBannerError"] => state[NameSpace.Banners].isBannerError;
export const getBanners = (state: State): Banner[] =>
  state[NameSpace.Banners].banners;
export const getCurrentBanner = (state: State): Banner | null =>
  state[NameSpace.Banners].currentBanner;
