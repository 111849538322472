import React from "react"
import Sorting from "../sorting/sorting"
import {ItemsForSort} from "../../../core/constants/filters"

function MeetingStatsTable() {
  return (
    <div className="meet__grid meet__grid--green">
      <div className="meet__parent">
        <p className="meet__title"></p>
        <Sorting title="дата" items={ItemsForSort.PlayersMatches}/>
        <Sorting title="турнир" items={ItemsForSort.PlayersMatches}/>
        <p className="meet__title">счет</p>
      </div>
    </div>
  )
}

export default MeetingStatsTable
