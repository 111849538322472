import React, {
  useEffect,
  useState
} from "react"
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks"
import {
  getAuthorizationStatus,
  getAuthorizationUserId,
} from "../../../core/store/authorization-data/selectors"
import {fetchUser} from "../../../core/store/api-actions"
import {getUser} from "../../../core/store/users-data/selectors"
import {removeLogin} from "../../../core/store/authorization-data/authorization-data"
import {resetUser} from "../../../core/store/users-data/users-data"
import {Link} from "react-router-dom"
import {AppRoute} from "../../../core/constants/routes"
import {changeAuthorizationModal} from "../../../core/store/modals-data/modals-data"

function Profile() {
  const dispatch = useAppDispatch()
  const userId = useAppSelector(getAuthorizationUserId)
  const user = useAppSelector(getUser)
  const loginStatus = useAppSelector(getAuthorizationStatus)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(fetchUser(Number(userId)))
    }
  }, [dispatch, userId])

  const handleRemoveAuthClick = () => {
    dispatch(removeLogin())
    dispatch(resetUser())
    setMenuOpen(!menuOpen)
  }

  const getLinkTo = () => {
    if (!user || !user.confirmed_at || user.blocked_at !== null) {
      return AppRoute.PersonalPage.url;
    }
    switch (user.role) {
      case 66:
        return AppRoute.RegistrationTournamentPage.url;
      case 34:
        return AppRoute.RegistrationCommandsPage.url;
      default:
        return AppRoute.PersonalPage.url;
    }
  }

  const handleModalOpen = () => {
    dispatch(changeAuthorizationModal({authorizationModal: true}))
  }

  return (
    <div className={`profile ${menuOpen ? "profile--open" : ""}`}>
      <div className={`profile__parent ${loginStatus ? "" : "hidden"}`}>
        <a className="profile__link" onClick={() => setMenuOpen(!menuOpen)}>
          <p className="profile__name hide-1080">
            {user?.profile?.first_name} {user?.profile?.last_name}
          </p>
          {/*нужно доработать*/}
          <img
            className="profile__img"
            width="45"
            height="45"
            src="/images/default/profile.svg"
            alt={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
          />
        </a>
        <ul className="profile__list">
          <li className="profile__item">
            <Link className="profile__unit" to={getLinkTo()}>
              Личный кабинет
            </Link>
          </li>
          <li className="profile__item">
            <a className="profile__unit" onClick={handleRemoveAuthClick}>
              Выход из профиля
            </a>
          </li>
        </ul>
      </div>
      <div className={`profile__parent ${loginStatus ? "hidden" : ""}`}>
        <a className="profile__link" onClick={handleModalOpen}>
          <p className="profile__author">
            войти <span className="profile__author hide-1080"> в профиль</span>
          </p>
          <img
            className="profile__img hide-1080"
            width="45"
            height="45"
            src="/images/default/profile.svg"
            alt="Заглушка профиля"
          />
        </a>
      </div>
    </div>
  )
}

export default Profile
