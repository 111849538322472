/* tslint:disable */
/* eslint-disable */
/**
 * Документация Amatour.api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface Album
 */
export interface Album {
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'album_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'cover_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'on_main'?: number;
    /**
     *
     * @type {AlbumPhoto}
     * @memberof Album
     */
    'cover'?: AlbumPhoto;
    /**
     * Database fields:
     * @type {Array<AlbumPhoto>}
     * @memberof Album
     */
    'photos'?: Array<AlbumPhoto>;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Album
     */
    'tags'?: Array<Tag>;
    /**
     *
     * @type {AlbumLinks}
     * @memberof Album
     */
    '_links'?: AlbumLinks;
}
/**
 * Database fields:
 * @export
 * @interface AlbumLinks
 */
export interface AlbumLinks {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof AlbumLinks
     */
    'admin'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof AlbumLinks
     */
    'public'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {AlbumLinksCovers}
     * @memberof AlbumLinks
     */
    'covers'?: AlbumLinksCovers;
}
/**
 * Database fields:
 * @export
 * @interface AlbumLinksCovers
 */
export interface AlbumLinksCovers {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof AlbumLinksCovers
     */
    'image750x424'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof AlbumLinksCovers
     */
    'image'?: HdbkDocumentLinksSelf;
}
/**
 *
 * @export
 * @interface AlbumPhoto
 */
export interface AlbumPhoto {
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'photo_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'album_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof AlbumPhoto
     */
    'image': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof AlbumPhoto
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'updated_by'?: number;
    /**
     *
     * @type {AlbumLinksCovers}
     * @memberof AlbumPhoto
     */
    '_links'?: AlbumLinksCovers;
}
/**
 *
 * @export
 * @interface ApiTeamsCreatePost200Response
 */
export interface ApiTeamsCreatePost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiTeamsCreatePost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiTeamsCreatePost200Response
     */
    'errors'?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiTeamsCreatePost200Response
     */
    'duplicates'?: Array<object>;
}
/**
 *
 * @export
 * @interface ApiTeamsCreatePost400Response
 */
export interface ApiTeamsCreatePost400Response {
    /**
     *
     * @type {string}
     * @memberof ApiTeamsCreatePost400Response
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiTeamsCreatePost400Response
     */
    'message'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiTeamsCreatePost400Response
     */
    'code'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiTeamsCreatePost400Response
     */
    'status'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiTeamsCreatePost400Response
     */
    'type'?: string;
}
/**
 *
 * @export
 * @interface ApiTournamentsCreatePost200Response
 */
export interface ApiTournamentsCreatePost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiTournamentsCreatePost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiTournamentsCreatePost200Response
     */
    'errors'?: Array<object>;
    /**
     *
     * @type {TournamentTemp}
     * @memberof ApiTournamentsCreatePost200Response
     */
    'tournament'?: TournamentTemp;
}
/**
 *
 * @export
 * @interface ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response
 */
export interface ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response {
    /**
     *
     * @type {string}
     * @memberof ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response
     */
    'css'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response
     */
    'js'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response
     */
    'html'?: string;
}
/**
 *
 * @export
 * @interface ApiUsersConfirmIdCodeGet200Response
 */
export interface ApiUsersConfirmIdCodeGet200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiUsersConfirmIdCodeGet200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiUsersConfirmIdCodeGet200Response
     */
    'text'?: string;
}
/**
 *
 * @export
 * @interface ApiUsersDesireRolesGet200ResponseInner
 */
export interface ApiUsersDesireRolesGet200ResponseInner {
    /**
     *
     * @type {number}
     * @memberof ApiUsersDesireRolesGet200ResponseInner
     */
    'value'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiUsersDesireRolesGet200ResponseInner
     */
    'label'?: string;
}
/**
 *
 * @export
 * @interface ApiUsersForgotPost200Response
 */
export interface ApiUsersForgotPost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiUsersForgotPost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiUsersForgotPost200Response
     */
    'errors'?: Array<object>;
}
/**
 *
 * @export
 * @interface ApiUsersLoginPost200Response
 */
export interface ApiUsersLoginPost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiUsersLoginPost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiUsersLoginPost200Response
     */
    'token'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiUsersLoginPost200Response
     */
    'user_id'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiUsersLoginPost200Response
     */
    'role'?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiUsersLoginPost200Response
     */
    'errors'?: Errors;
}
/**
 *
 * @export
 * @interface Errors
 */
export interface Errors {
  /**
   *
   * @type {string[]}
   * @memberof Errors
   */
  'password'?: string[];
  /**
   *
   * @type {string[]}
   * @memberof Errors
   */
  'email'?: string[];
  /**
   *
   * @type {string[]}
   * @memberof Errors
   */
  'reCaptcha'?: string[];
  /**
   *
   * @type {string[]}
   * @memberof Errors
   */
  'phone'?: string[];
}
/**
 *
 * @export
 * @interface ApiUsersRecoverIdCodePost200Response
 */
export interface ApiUsersRecoverIdCodePost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiUsersRecoverIdCodePost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiUsersRecoverIdCodePost200Response
     */
    'token'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiUsersRecoverIdCodePost200Response
     */
    'text'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiUsersRecoverIdCodePost200Response
     */
    'errors'?: Array<object>;
}
/**
 *
 * @export
 * @interface ApiUsersRegisterPost200Response
 */
export interface ApiUsersRegisterPost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiUsersRegisterPost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiUsersRegisterPost200Response
     */
    'user_id'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiUsersRegisterPost200Response
     */
    'role'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiUsersRegisterPost200Response
     */
    'token'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiUsersRegisterPost200Response
     */
    'text'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiUsersRegisterPost200Response
     */
    'errors'?: Errors;
}
/**
 *
 * @export
 * @interface ApiVersionGet200Response
 */
export interface ApiVersionGet200Response {
    /**
     *
     * @type {string}
     * @memberof ApiVersionGet200Response
     */
    'version'?: string;
}
/**
 *
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'banner_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'link': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'image_tablet'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'image_mobile'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'size': BannerSizeEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'position': BannerPositionEnum;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'blank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'on_mobile'?: number;
}

export const BannerSizeEnum = {
    Main: 'main',
    Sidebar: 'sidebar'
} as const;

export type BannerSizeEnum = typeof BannerSizeEnum[keyof typeof BannerSizeEnum];
export const BannerPositionEnum = {
    MainIndexBottomPage: 'main-index-bottom-page',
    StaticPageBottom: 'static-page-bottom',
    TournamentsBottom: 'tournaments-bottom',
    SidebarRating: 'sidebar-rating',
    SidebarNewsfeed: 'sidebar-newsfeed',
    SidebarFaq: 'sidebar-faq',
    SidebarStaticPage: 'sidebar-static-page'
} as const;

export type BannerPositionEnum = typeof BannerPositionEnum[keyof typeof BannerPositionEnum];

/**
 *
 * @export
 * @interface HdbkCity
 */
export interface HdbkCity {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkCity
     */
    'city_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkCity
     */
    'title': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkCity
     */
    'rank'?: number;
}
/**
 *
 * @export
 * @interface HdbkDocument
 */
export interface HdbkDocument {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'document_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'module': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'item_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'file'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'created_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'updated_at'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'updated_by'?: number;
    /**
     *
     * @type {HdbkDocumentLinks}
     * @memberof HdbkDocument
     */
    '_links'?: HdbkDocumentLinks;
}
/**
 * Database fields:
 * @export
 * @interface HdbkDocumentLinks
 */
export interface HdbkDocumentLinks {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof HdbkDocumentLinks
     */
    'self'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof HdbkDocumentLinks
     */
    'file'?: HdbkDocumentLinksSelf;
}
/**
 * Database fields:
 * @export
 * @interface HdbkDocumentLinksSelf
 */
export interface HdbkDocumentLinksSelf {
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocumentLinksSelf
     */
    'href'?: string;
}
/**
 *
 * @export
 * @interface HdbkFaq
 */
export interface HdbkFaq {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkFaq
     */
    'faq_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkFaq
     */
    'question': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkFaq
     */
    'answer': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkFaq
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkFaq
     */
    'is_public'?: number;
}
/**
 *
 * @export
 * @interface HdbkMainProvision
 */
export interface HdbkMainProvision {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkMainProvision
     */
    'provision_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkMainProvision
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkMainProvision
     */
    'text': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkMainProvision
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkMainProvision
     */
    'is_public'?: number;
}
/**
 *
 * @export
 * @interface HdbkPartner
 */
export interface HdbkPartner {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'partner_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'logo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'url'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'on_main'?: number;
}
/**
 *
 * @export
 * @interface HdbkRegion
 */
export interface HdbkRegion {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkRegion
     */
    'region_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkRegion
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkRegion
     */
    'title_en'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkRegion
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkRegion
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkRegion
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkRegion
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkRegion
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface HdbkSeason
 */
export interface HdbkSeason {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSeason
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSeason
     */
    'start_dt': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSeason
     */
    'end_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface HdbkZone
 */
export interface HdbkZone {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkZone
     */
    'region_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkZone
     */
    'zone_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkZone
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkZone
     */
    'title_en'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkZone
     */
    'rank'?: number;
}
/**
 *
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'invoice_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'product_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'tournament_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'team_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'match_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Invoice
     */
    'purpose': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Invoice
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'amount': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Invoice
     */
    'date': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Invoice
     */
    'status': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Invoice
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface League
 */
export interface League {
    /**
     * Database fields:
     * @type {number}
     * @memberof League
     */
    'league_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof League
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof League
     */
    'short_name': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof League
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof League
     */
    'city_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof League
     */
    'logo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof League
     */
    'contact_info'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof League
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof League
     */
    'is_published': number;
}
/**
 *
 * @export
 * @interface Match
 */
export interface Match {
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'match_id': number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'tournament_id': number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'round_id': number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'team1_id'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'team2_id'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'tour_id'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'series_id': number;
    /**
     *
     * @type {string}
     * @memberof Match
     */
    'type'?: MatchTypeEnum;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'number'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'is_final'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'gf'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'ga'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'overtime'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'technical'?: number;
    /**
     *
     * @type {string}
     * @memberof Match
     */
    'start_dt'?: string;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'created_by'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'updated_by'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'created_at'?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    'updated_at'?: number;
    /**
     *
     * @type {Tournament}
     * @memberof Match
     */
    'tournament'?: Tournament;
    /**
     *
     * @type {Round}
     * @memberof Match
     */
    'round'?: Round;
    /**
     *
     * @type {Team}
     * @memberof Match
     */
    'team1'?: Team;
    /**
     *
     * @type {Team}
     * @memberof Match
     */
    'team2'?: Team;
    /**
     *
     * @type {RoundTour}
     * @memberof Match
     */
    'tour'?: RoundTour;
    /**
     *
     * @type {MatchSeries}
     * @memberof Match
     */
    'series'?: MatchSeries;
    /**
     *
     * @type {Array<Player>}
     * @memberof Match
     */
    'players'?: Array<Player>;
    /**
     *
     * @type {Array<Player>}
     * @memberof Match
     */
    'teamPlayers'?: Array<Player>;
    /**
     *
     * @type {MatchProtocol}
     * @memberof Match
     */
    'protocol'?: MatchProtocol;
    /**
     *
     * @type {Array<MatchPlayer>}
     * @memberof Match
     */
    'matchPlayers'?: Array<MatchPlayer>;
    /**
     *
     * @type {Array<MatchPeriod>}
     * @memberof Match
     */
    'periods'?: Array<MatchPeriod>;
    /**
     *
     * @type {MatchReferee}
     * @memberof Match
     */
    'matchReferee'?: MatchReferee;
    /**
     *
     * @type {Referee}
     * @memberof Match
     */
    'referee'?: Referee;
    /**
     *
     * @type {Array<MatchPlayer>}
     * @memberof Match
     */
    'matchPlayers1'?: Array<MatchPlayer>;
    /**
     *
     * @type {Array<MatchPlayer>}
     * @memberof Match
     */
    'matchPlayers2'?: Array<MatchPlayer>;
}

export const MatchTypeEnum = {
    Free: 'free',
    SingleMan: 'single-man',
    SingleWoman: 'single-woman',
    SingleMix: 'single-mix',
    PairMan: 'pair-man',
    PairWoman: 'pair-woman',
    PairMix: 'pair-mix'
} as const;

export type MatchTypeEnum = typeof MatchTypeEnum[keyof typeof MatchTypeEnum];

/**
 *
 * @export
 * @interface MatchPeriod
 */
export interface MatchPeriod {
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPeriod
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPeriod
     */
    'match_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPeriod
     */
    'number'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPeriod
     */
    'gf'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPeriod
     */
    'ga'?: number;
}
/**
 *
 * @export
 * @interface MatchPlayer
 */
export interface MatchPlayer {
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'match_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'player_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPlayer
     */
    'status'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'captain'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPlayer
     */
    'updated_at'?: number;
    /**
     *
     * @type {Player}
     * @memberof MatchPlayer
     */
    'player'?: Player;
}
/**
 *
 * @export
 * @interface MatchProtocol
 */
export interface MatchProtocol {
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'protocol_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'match_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'attendance'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'best_player_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'best_player_team_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'best_team1_player_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'best_team2_player_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchProtocol
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface MatchReferee
 */
export interface MatchReferee {
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchReferee
     */
    'match_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchReferee
     */
    'referee_id': number;
}
/**
 *
 * @export
 * @interface MatchSeries
 */
export interface MatchSeries {
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'series_id': number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'tournament_id': number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'round_id': number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'team1_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'team2_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'tree_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'tour_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'next_series_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'loose_series_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'left_series_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'right_series_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'games'?: number;
    /**
     *
     * @type {boolean}
     * @memberof MatchSeries
     */
    'is_complete'?: boolean;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'winner_team_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'score1'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'score2'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'points1'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'points2'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'technical'?: number;
    /**
     *
     * @type {string}
     * @memberof MatchSeries
     */
    'start_dt'?: string;
    /**
     *
     * @type {string}
     * @memberof MatchSeries
     */
    'club'?: string;
    /**
     *
     * @type {string}
     * @memberof MatchSeries
     */
    'club_address'?: string;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'created_by'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'updated_by'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'created_at'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchSeries
     */
    'updated_at'?: number;
    /**
     *
     * @type {Tournament}
     * @memberof MatchSeries
     */
    'tournament'?: Tournament;
    /**
     *
     * @type {Round}
     * @memberof MatchSeries
     */
    'round'?: Round;
    /**
     *
     * @type {Team}
     * @memberof MatchSeries
     */
    'team1'?: Team;
    /**
     *
     * @type {Team}
     * @memberof MatchSeries
     */
    'team2'?: Team;
    /**
     *
     * @type {RoundTour}
     * @memberof MatchSeries
     */
    'tour'?: RoundTour;
    /**
     *
     * @type {RoundTree}
     * @memberof MatchSeries
     */
    'tree'?: RoundTree;
    /**
     *
     * @type {MatchSeries}
     * @memberof MatchSeries
     */
    'nextSeries'?: MatchSeries;
    /**
     *
     * @type {MatchSeries}
     * @memberof MatchSeries
     */
    'leftSeries'?: MatchSeries;
    /**
     *
     * @type {MatchSeries}
     * @memberof MatchSeries
     */
    'rightSeries'?: MatchSeries;
    /**
     *
     * @type {MatchSeries}
     * @memberof MatchSeries
     */
    'looseSeries'?: MatchSeries;
    /**
     *
     * @type {Array<MatchSeries>}
     * @memberof MatchSeries
     */
    'parentSeries'?: Array<MatchSeries>;
    /**
     *
     * @type {Array<Match>}
     * @memberof MatchSeries
     */
    'matches'?: Array<Match>;
    /**
     *
     * @type {Array<Match>}
     * @memberof MatchSeries
     */
    'playedMatches'?: Array<Match>;
    /**
     *
     * @type {Array<Match>}
     * @memberof MatchSeries
     */
    'notPlayedMatches'?: Array<Match>;
    /**
     *
     * @type {Match}
     * @memberof MatchSeries
     */
    'finalMatch'?: Match;
    /**
     *
     * @type {Invoice}
     * @memberof MatchSeries
     */
    'invoice'?: Invoice;
    /**
     *
     * @type {Team}
     * @memberof MatchSeries
     */
    'winner'?: Team;
    /**
     *
     * @type {Team}
     * @memberof MatchSeries
     */
    'looser'?: Team;
}
/**
 *
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'page_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'text': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'alias': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'is_default'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'need_image'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'need_address'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'zone_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'address'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'latitude'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'longitude'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'transport'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'phone'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'email'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'rank'?: number;
    /**
     *
     * @type {PageLinks}
     * @memberof Page
     */
    '_links'?: PageLinks;
}
/**
 * Database fields:
 * @export
 * @interface PageLinks
 */
export interface PageLinks {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PageLinks
     */
    'image1140x644'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PageLinks
     */
    'image460x260'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PageLinks
     */
    'image'?: HdbkDocumentLinksSelf;
}
/**
 *
 * @export
 * @interface Player
 */
export interface Player {
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'birthday'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Player
     */
    'player_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'first_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'last_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'middle_name'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'avatar': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'phone'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'gender'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'photo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Player
     */
    'citizenship'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Player
     */
    'city_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Player
     */
    'ntrp'?: number;
    /**
     *
     * @type {HdbkCity}
     * @memberof Player
     */
    'city'?: HdbkCity;
}
/**
 *
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'post_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'title'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'lead'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'text'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'is_temp': number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Post
     */
    'tags'?: Array<Tag>;
    /**
     *
     * @type {PostLinks}
     * @memberof Post
     */
    '_links'?: PostLinks;
}
/**
 * Database fields:
 * @export
 * @interface PostLinks
 */
export interface PostLinks {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PostLinks
     */
    'admin'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PostLinks
     */
    'public'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PostLinks
     */
    'image1120x633'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PostLinks
     */
    'image460x260'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof PostLinks
     */
    'image'?: HdbkDocumentLinksSelf;
}
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Database fields:
     * @type {number}
     * @memberof Product
     */
    'product_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Product
     */
    'title': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Product
     */
    'price': number;
}
/**
 *
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * Database fields:
     * @type {number}
     * @memberof Profile
     */
    'user_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Profile
     */
    'first_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Profile
     */
    'last_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Profile
     */
    'middle_name'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Profile
     */
    'photo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Profile
     */
    'birthday'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Profile
     */
    'gender'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Profile
     */
    'show_rating'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Profile
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Profile
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Profile
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Profile
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface RatingPlayer
 */
export interface RatingPlayer {
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'player_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'first_count'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'second_count'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'third_count'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'points'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RatingPlayer
     */
    'updated_at'?: number;
    /**
     *
     * @type {Team}
     * @memberof RatingPlayer
     */
    'team'?: Team;
    /**
     *
     * @type {Player}
     * @memberof RatingPlayer
     */
    'player'?: Player;
}
/**
 *
 * @export
 * @interface RatingTeam
 */
export interface RatingTeam {
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'id': number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'season_id': number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'tournament_id': number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'team_id': number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'first_count'?: number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'second_count'?: number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'third_count'?: number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'points'?: number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'created_at'?: number;
    /**
     * Swagger definitions:
     * @type {number}
     * @memberof RatingTeam
     */
    'updated_at'?: number;
    /**
     *
     * @type {Team}
     * @memberof RatingTeam
     */
    'team'?: Team;
}
/**
 *
 * @export
 * @interface Referee
 */
export interface Referee {
    /**
     * Database fields:
     * @type {number}
     * @memberof Referee
     */
    'referee_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Referee
     */
    'first_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Referee
     */
    'middle_name'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Referee
     */
    'last_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Referee
     */
    'birthday'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Referee
     */
    'photo'?: string;
}
/**
 *
 * @export
 * @interface Round
 */
export interface Round {
    /**
     * Database fields:
     * @type {number}
     * @memberof Round
     */
    'round_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Round
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Round
     */
    'type': RoundTypeEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Round
     */
    'name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Round
     */
    'series_regulation': RoundSeriesRegulationEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Round
     */
    'series_match_type': RoundSeriesMatchTypeEnum;
    /**
     * Database fields:
     * @type {number}
     * @memberof Round
     */
    'series_length': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Round
     */
    'target'?: number;
    /**
     * Database fields:
     * @type {boolean}
     * @memberof Round
     */
    'has_table'?: boolean;
    /**
     * Database fields:
     * @type {number}
     * @memberof Round
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {boolean}
     * @memberof Round
     */
    'is_generate'?: boolean;
    /**
     * Database fields:
     * @type {boolean}
     * @memberof Round
     */
    'is_finished'?: boolean;
    /**
     *
     * @type {Tournament}
     * @memberof Round
     */
    'tournament'?: Tournament;
    /**
     * Database fields:
     * @type {Array<RoundTableInner>}
     * @memberof Round
     */
    'table'?: Array<RoundTableInner>;
    /**
     *
     * @type {RoundChess}
     * @memberof Round
     */
    'chess'?: RoundChess;
    /**
     * Database fields:
     * @type {Array<Array<Array<MatchSeries>>>}
     * @memberof Round
     */
    'playoff'?: Array<Array<Array<MatchSeries>>>;
    /**
     *
     * @type {RoundPlayoffHtml}
     * @memberof Round
     */
    'playoffHtml'?: RoundPlayoffHtml;
}

export const RoundTypeEnum = {
    RoundRobinOne: 'round-robin-one',
    RoundRobinTwo: 'round-robin-two',
    PlayoffOlympic: 'playoff-olympic',
    Free: 'free'
} as const;

export type RoundTypeEnum = typeof RoundTypeEnum[keyof typeof RoundTypeEnum];
export const RoundSeriesRegulationEnum = {
    Free: 'free',
    Minimal: 'minimal',
    Optimal: 'optimal',
    Challenge: 'challenge'
} as const;

export type RoundSeriesRegulationEnum = typeof RoundSeriesRegulationEnum[keyof typeof RoundSeriesRegulationEnum];
export const RoundSeriesMatchTypeEnum = {
    Free: 'free',
    SingleMan: 'single-man',
    SingleWoman: 'single-woman',
    SingleMix: 'single-mix',
    PairMan: 'pair-man',
    PairWoman: 'pair-woman',
    PairMix: 'pair-mix'
} as const;

export type RoundSeriesMatchTypeEnum = typeof RoundSeriesMatchTypeEnum[keyof typeof RoundSeriesMatchTypeEnum];

/**
 * Database fields:
 * @export
 * @interface RoundChess
 */
export interface RoundChess {
    /**
     * Database fields:
     * @type {Array<Array<Array<MatchSeries>>>}
     * @memberof RoundChess
     */
    'chess'?: Array<Array<Array<MatchSeries>>>;
    /**
     * Database fields:
     * @type {Array<RoundTable>}
     * @memberof RoundChess
     */
    'tableRows'?: Array<RoundTable>;
    /**
     * Database fields:
     * @type {Array<object>}
     * @memberof RoundChess
     */
    'teamPos'?: Array<object>;
}
/**
 * Database fields:
 * @export
 * @interface RoundPlayoffHtml
 */
export interface RoundPlayoffHtml {
    /**
     * Database fields:
     * @type {string}
     * @memberof RoundPlayoffHtml
     */
    'css'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof RoundPlayoffHtml
     */
    'js'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof RoundPlayoffHtml
     */
    'html'?: string;
}
/**
 *
 * @export
 * @interface RoundTable
 */
export interface RoundTable {
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'round_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'points'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'wins_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'draws'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'losses_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'gf'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'ga'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_points'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_wins_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_draws'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_losses_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_gf'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_ga'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_pf'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_pa'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'place'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTable
     */
    'pre_place'?: number;
    /**
     *
     * @type {Team}
     * @memberof RoundTable
     */
    'team'?: Team;
}
/**
 *
 * @export
 * @interface RoundTableInner
 */
export interface RoundTableInner {
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'place'?: number;
    /**
     *
     * @type {Team}
     * @memberof RoundTableInner
     */
    'team'?: Team;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'points'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'drafts'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'losses_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'gf'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTableInner
     */
    'ga'?: number;
}
/**
 *
 * @export
 * @interface RoundTour
 */
export interface RoundTour {
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTour
     */
    'tour_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTour
     */
    'round_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTour
     */
    'tree_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTour
     */
    'tour_number': number;
}
/**
 *
 * @export
 * @interface RoundTree
 */
export interface RoundTree {
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTree
     */
    'tree_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTree
     */
    'round_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof RoundTree
     */
    'target'?: number;
}
/**
 *
 * @export
 * @interface StatsPlayer
 */
export interface StatsPlayer {
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'round_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'player_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'draws'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'efficiency'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'matches_games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'matches_wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'matches_draws'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'matches_losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsPlayer
     */
    'updated_at'?: number;
    /**
     *
     * @type {Team}
     * @memberof StatsPlayer
     */
    'team'?: Team;
    /**
     *
     * @type {Player}
     * @memberof StatsPlayer
     */
    'player'?: Player;
}
/**
 *
 * @export
 * @interface StatsTeam
 */
export interface StatsTeam {
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'round_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'draws'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'matches_games'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'matches_wins'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'matches_draws'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'matches_losses'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof StatsTeam
     */
    'updated_at'?: number;
    /**
     *
     * @type {Team}
     * @memberof StatsTeam
     */
    'team'?: Team;
}
/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'tag_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'module': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'item_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'color': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'frequency': number;
}
/**
 *
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * Database fields:
     * @type {number}
     * @memberof Team
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'short_name'?: string;
    /**
   * Database fields:
   * @type {string}
   * @memberof Team
   */
    'avatar': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'club': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'club_address': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Team
     */
    'region_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Team
     */
    'zone_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Team
     */
    'city_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'court_coverage': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'phone'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'email'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'payment_status': TeamPaymentStatusEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'logo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Team
     */
    'partner_logo'?: string;
    /**
     *
     * @type {TeamProduct}
     * @memberof Team
     */
    'product'?: TeamProduct;
}

export const TeamPaymentStatusEnum = {
    Unpaid: 'unpaid',
    Paid: 'paid'
} as const;

export type TeamPaymentStatusEnum = typeof TeamPaymentStatusEnum[keyof typeof TeamPaymentStatusEnum];

/**
 *
 * @export
 * @interface TeamPlayer
 */
export interface TeamPlayer {
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamPlayer
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamPlayer
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamPlayer
     */
    'player_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamPlayer
     */
    'captain'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamPlayer
     */
    'status': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamPlayer
     */
    'statusLabel'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamPlayer
     */
    'in'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamPlayer
     */
    'out'?: string;
    /**
     *
     * @type {Player}
     * @memberof TeamPlayer
     */
    'player'?: Player;
    /**
     *
     * @type {Team}
     * @memberof TeamPlayer
     */
    'team'?: Team;
}
/**
 * Database fields:
 * @export
 * @interface TeamProduct
 */
export interface TeamProduct {
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamProduct
     */
    'title'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamProduct
     */
    'price'?: number;
}
/**
 *
 * @export
 * @interface TeamTemp
 */
export interface TeamTemp {
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTemp
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'short_name'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'club': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'club_address': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTemp
     */
    'zone_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTemp
     */
    'city_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'court_coverage': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'phone'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'email'?: string;
    /**
     * Database fields:
     * @type {File}
     * @memberof TeamTemp
     */
    'logo'?: File;
    /**
     * Database fields:
     * @type {File}
     * @memberof TeamTemp
     */
    'partner_logo'?: File;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'status'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'comment'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTemp
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTemp
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'created_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTemp
     */
    'updated_at'?: string;
    /**
     * Database fields:
     * @type {Array<TeamTempPlayer>}
     * @memberof TeamTemp
     */
    'players'?: Array<TeamTempPlayer>;
}
/**
 *
 * @export
 * @interface TeamTempPlayer
 */
export interface TeamTempPlayer {
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTempPlayer
     */
    'player_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTempPlayer
     */
    'team_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'first_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'last_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'birthday': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'gender': string;
    /**
     * Фотография в формате base64
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'photo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'ntrp'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTempPlayer
     */
    'is_duplicate'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTempPlayer
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TeamTempPlayer
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'created_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TeamTempPlayer
     */
    'updated_at'?: string;
}

export const TeamTempPlayerGenderEnum = {
    Man: 'man',
    Woman: 'woman'
} as const;

export type TeamTempPlayerGenderEnum = typeof TeamTempPlayerGenderEnum[keyof typeof TeamTempPlayerGenderEnum];

/**
 * Описание TeamTempPlayer для заявки на создание команды
 * @export
 * @interface TeamTempPlayerRequest
 */
export interface TeamTempPlayerRequest {
    /**
     *
     * @type {string}
     * @memberof TeamTempPlayerRequest
     */
    'first_name': string;
    /**
     *
     * @type {string}
     * @memberof TeamTempPlayerRequest
     */
    'last_name': string;
    /**
     *
     * @type {string}
     * @memberof TeamTempPlayerRequest
     */
    'birthday': string;
    /**
     *
     * @type {string}
     * @memberof TeamTempPlayerRequest
     */
    'gender': string;
    /**
     * Фотография в формате base64
     * @type {string}
     * @memberof TeamTempPlayerRequest
     */
    'photo'?: string;
    /**
     *
     * @type {string}
     * @memberof TeamTempPlayerRequest
     */
    'ntrp'?: string;
}

export const TeamTempPlayerRequestGenderEnum = {
    Man: 'man',
    Woman: 'woman'
} as const;

export type TeamTempPlayerRequestGenderEnum = typeof TeamTempPlayerRequestGenderEnum[keyof typeof TeamTempPlayerRequestGenderEnum];

/**
 *
 * @export
 * @interface Tournament
 */
export interface Tournament {
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'league_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'region_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'zone_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'city_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'short_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'type': TournamentTypeEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'club'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'club_address'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'court_coverage'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'official_ball'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'contact_info'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'logo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'gender'?: TournamentGenderEnum;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'parent_tournament_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'parent_stats': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'is_parent': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'start_dt': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'end_dt': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'app_start_dt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'app_end_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'teams_min'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'teams_max'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'players_min'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'players_max'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'paymen_source'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'payment'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'prize_money'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'transfers_enabled': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'draws_available': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'technical_goals'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'points_win'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'points_win_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'points_draw'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'points_defeat_ot'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'points_defeat'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'period_count'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'extra_time_count'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'extra_time_duration'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'sets_count'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'match_duration'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tournament
     */
    'sorters'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'rating_points_1'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'rating_points_2'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'rating_points_3'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'is_published': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tournament
     */
    'rank'?: number;
    /**
     *
     * @type {Tag}
     * @memberof Tournament
     */
    'tag'?: Tag;
    /**
     *
     * @type {HdbkSeason}
     * @memberof Tournament
     */
    'season'?: HdbkSeason;
    /**
     *
     * @type {League}
     * @memberof Tournament
     */
    'league'?: League;
    /**
     *
     * @type {HdbkRegion}
     * @memberof Tournament
     */
    'region'?: HdbkRegion;
    /**
     *
     * @type {HdbkZone}
     * @memberof Tournament
     */
    'zone'?: HdbkZone;
    /**
     *
     * @type {HdbkCity}
     * @memberof Tournament
     */
    'city'?: HdbkCity;
    /**
     *
     * @type {Product}
     * @memberof Tournament
     */
    'product'?: Product;
    /**
     * Database fields:
     * @type {Array<Round>}
     * @memberof Tournament
     */
    'rounds'?: Array<Round>;
    /**
     * Database fields:
     * @type {Array<HdbkDocument>}
     * @memberof Tournament
     */
    'documents'?: Array<HdbkDocument>;
    /**
     * Database fields:
     * @type {Array<HdbkPartner>}
     * @memberof Tournament
     */
    'partners'?: Array<HdbkPartner>;
    /**
     * Database fields:
     * @type {Array<TournamentWinner>}
     * @memberof Tournament
     */
    'winners'?: Array<TournamentWinner>;
    /**
     *
     * @type {TournamentLinks}
     * @memberof Tournament
     */
    '_links'?: TournamentLinks;
}

export const TournamentTypeEnum = {
    Commercial: 'commercial',
    League: 'league'
} as const;

export type TournamentTypeEnum = typeof TournamentTypeEnum[keyof typeof TournamentTypeEnum];
export const TournamentGenderEnum = {
    Man: 'man',
    Woman: 'woman'
} as const;

export type TournamentGenderEnum = typeof TournamentGenderEnum[keyof typeof TournamentGenderEnum];

/**
 * Database fields:
 * @export
 * @interface TournamentLinks
 */
export interface TournamentLinks {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof TournamentLinks
     */
    'admin'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof TournamentLinks
     */
    'public'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof TournamentLinks
     */
    'logo300x300'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof TournamentLinks
     */
    'logo190x190'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof TournamentLinks
     */
    'logo120x120'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof TournamentLinks
     */
    'logo'?: HdbkDocumentLinksSelf;
}
/**
 *
 * @export
 * @interface TournamentTemp
 */
export interface TournamentTemp {
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentTemp
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'short_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'club': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'club_address': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'start_dt': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'end_dt': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentTemp
     */
    'city_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentTemp
     */
    'zone_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'court_coverage'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'official_ball'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'contact_info'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentTemp
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentTemp
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'created_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'updated_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'status'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof TournamentTemp
     */
    'comment'?: string;
}
/**
 *
 * @export
 * @interface TournamentWinner
 */
export interface TournamentWinner {
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentWinner
     */
    'tournament_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentWinner
     */
    'team_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentWinner
     */
    'place': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof TournamentWinner
     */
    'rating_points'?: number;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'player_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof User
     */
    'phone'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'role'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'desire_role'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'confirmed_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'blocked_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'last_activity_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof User
     */
    'updated_at'?: number;
    /**
     *
     * @type {Profile}
     * @memberof User
     */
    'profile'?: Profile;
}
/**
 *
 * @export
 * @interface Video
 */
export interface Video {
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'video_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'source_url'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'embed_url'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'code'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'preview'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'time'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Video
     */
    'tags'?: Array<Tag>;
    /**
     *
     * @type {VideoLinks}
     * @memberof Video
     */
    '_links'?: VideoLinks;
}
/**
 * Database fields:
 * @export
 * @interface VideoLinks
 */
export interface VideoLinks {
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof VideoLinks
     */
    'admin'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof VideoLinks
     */
    'public'?: HdbkDocumentLinksSelf;
    /**
     *
     * @type {HdbkDocumentLinksSelf}
     * @memberof VideoLinks
     */
    'preview'?: HdbkDocumentLinksSelf;
}

/**
 * HdbkApi - axios parameter creator
 * @export
 */
export const HdbkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию HdbkFaq[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFaqGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Faq по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFaqIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFaqIdGet', 'id', id)
            const localVarPath = `/api/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkPartner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Partner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPartnersIdGet', 'id', id)
            const localVarPath = `/api/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkMainProvision[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvisionGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/provision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Основные положения по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvisionIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProvisionIdGet', 'id', id)
            const localVarPath = `/api/provision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkSeason[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Season по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSeasonsIdGet', 'id', id)
            const localVarPath = `/api/seasons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HdbkApi - functional programming interface
 * @export
 */
export const HdbkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HdbkApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию HdbkFaq[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFaqGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkFaq>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFaqGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiFaqGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Faq по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFaqIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkFaq>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFaqIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiFaqIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkPartner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkPartner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnersGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiPartnersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Partner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkPartner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnersIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiPartnersIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkMainProvision[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProvisionGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkMainProvision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProvisionGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiProvisionGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Основные положения по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProvisionIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkMainProvision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProvisionIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiProvisionIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkSeason[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSeasonsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkSeason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSeasonsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiSeasonsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Season по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSeasonsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkSeason>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSeasonsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkApi.apiSeasonsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * HdbkApi - factory interface
 * @export
 */
export const HdbkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HdbkApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию HdbkFaq[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFaqGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkFaq>> {
            return localVarFp.apiFaqGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Faq по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFaqIdGet(id: number, options?: any): AxiosPromise<HdbkFaq> {
            return localVarFp.apiFaqIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkPartner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkPartner>> {
            return localVarFp.apiPartnersGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Partner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersIdGet(id: number, options?: any): AxiosPromise<HdbkPartner> {
            return localVarFp.apiPartnersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkMainProvision[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvisionGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkMainProvision>> {
            return localVarFp.apiProvisionGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Основные положения по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvisionIdGet(id: number, options?: any): AxiosPromise<HdbkMainProvision> {
            return localVarFp.apiProvisionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkSeason[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkSeason>> {
            return localVarFp.apiSeasonsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Season по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsIdGet(id: number, options?: any): AxiosPromise<HdbkSeason> {
            return localVarFp.apiSeasonsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HdbkApi - object-oriented interface
 * @export
 * @class HdbkApi
 * @extends {BaseAPI}
 */
export class HdbkApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию HdbkFaq[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiFaqGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiFaqGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Faq по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiFaqIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiFaqIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkPartner[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiPartnersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiPartnersGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Partner по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiPartnersIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiPartnersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkMainProvision[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiProvisionGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiProvisionGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Основные положения по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiProvisionIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiProvisionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkSeason[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiSeasonsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiSeasonsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Season по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkApi
     */
    public apiSeasonsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkApiFp(this.configuration).apiSeasonsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HdbkGEOApi - axios parameter creator
 * @export
 */
export const HdbkGEOApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию HdbkCity[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCitiesGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает HdbkCity по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCitiesIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCitiesIdGet', 'id', id)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkRegion[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает HdbkRegion по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegionsIdGet', 'id', id)
            const localVarPath = `/api/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkZone[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZonesGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает HdbkZone по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZonesIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiZonesIdGet', 'id', id)
            const localVarPath = `/api/zones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HdbkGEOApi - functional programming interface
 * @export
 */
export const HdbkGEOApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HdbkGEOApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию HdbkCity[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCitiesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkCity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCitiesGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkGEOApi.apiCitiesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает HdbkCity по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCitiesIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkCity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCitiesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkGEOApi.apiCitiesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkRegion[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegionsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkRegion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegionsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkGEOApi.apiRegionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает HdbkRegion по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegionsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkRegion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegionsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkGEOApi.apiRegionsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkZone[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZonesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZonesGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkGEOApi.apiZonesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает HdbkZone по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZonesIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZonesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HdbkGEOApi.apiZonesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * HdbkGEOApi - factory interface
 * @export
 */
export const HdbkGEOApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HdbkGEOApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию HdbkCity[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCitiesGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkCity>> {
            return localVarFp.apiCitiesGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает HdbkCity по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCitiesIdGet(id: number, options?: any): AxiosPromise<HdbkCity> {
            return localVarFp.apiCitiesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkRegion[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkRegion>> {
            return localVarFp.apiRegionsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает HdbkRegion по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsIdGet(id: number, options?: any): AxiosPromise<HdbkRegion> {
            return localVarFp.apiRegionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkZone[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZonesGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkZone>> {
            return localVarFp.apiZonesGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает HdbkZone по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZonesIdGet(id: number, options?: any): AxiosPromise<HdbkZone> {
            return localVarFp.apiZonesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HdbkGEOApi - object-oriented interface
 * @export
 * @class HdbkGEOApi
 * @extends {BaseAPI}
 */
export class HdbkGEOApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию HdbkCity[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkGEOApi
     */
    public apiCitiesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkGEOApiFp(this.configuration).apiCitiesGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает HdbkCity по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkGEOApi
     */
    public apiCitiesIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkGEOApiFp(this.configuration).apiCitiesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkRegion[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkGEOApi
     */
    public apiRegionsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkGEOApiFp(this.configuration).apiRegionsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает HdbkRegion по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkGEOApi
     */
    public apiRegionsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkGEOApiFp(this.configuration).apiRegionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkZone[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkGEOApi
     */
    public apiZonesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return HdbkGEOApiFp(this.configuration).apiZonesGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает HdbkZone по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HdbkGEOApi
     */
    public apiZonesIdGet(id: number, options?: RawAxiosRequestConfig) {
        return HdbkGEOApiFp(this.configuration).apiZonesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию Product[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Product по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdGet', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Product[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiProductsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Product по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiProductsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Product[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.apiProductsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Product по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet(id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.apiProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию Product[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiProductsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiProductsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Product по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiProductsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LeagueApi - axios parameter creator
 * @export
 */
export const LeagueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию League[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/leagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает League по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeaguesIdGet', 'id', id)
            const localVarPath = `/api/leagues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueApi - functional programming interface
 * @export
 */
export const LeagueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию League[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<League>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeaguesGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeagueApi.apiLeaguesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает League по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<League>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeaguesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeagueApi.apiLeaguesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LeagueApi - factory interface
 * @export
 */
export const LeagueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию League[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<League>> {
            return localVarFp.apiLeaguesGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает League по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesIdGet(id: number, options?: any): AxiosPromise<League> {
            return localVarFp.apiLeaguesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueApi - object-oriented interface
 * @export
 * @class LeagueApi
 * @extends {BaseAPI}
 */
export class LeagueApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию League[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public apiLeaguesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return LeagueApiFp(this.configuration).apiLeaguesGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает League по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public apiLeaguesIdGet(id: number, options?: RawAxiosRequestConfig) {
        return LeagueApiFp(this.configuration).apiLeaguesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MatchApi - axios parameter creator
 * @export
 */
export const MatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию Match[]
         * @param {number} [tournamentId]
         * @param {number} [seriesId]
         * @param {number} [playerId]
         * @param {number} [teamId]
         * @param {Array<ApiMatchesGetWithEnum>} [_with]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMatchesGet: async (tournamentId?: number, seriesId?: number, playerId?: number, teamId?: number, _with?: Array<ApiMatchesGetWithEnum>, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournament_id'] = tournamentId;
            }

            if (seriesId !== undefined) {
                localVarQueryParameter['series_id'] = seriesId;
            }

            if (playerId !== undefined) {
                localVarQueryParameter['player_id'] = playerId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Match по ID
         * @param {number} id
         * @param {Array<ApiMatchesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMatchesIdGet: async (id: number, _with?: Array<ApiMatchesIdGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMatchesIdGet', 'id', id)
            const localVarPath = `/api/matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию MatchSeries[]
         * @param {number} [tournamentId]
         * @param {number} [playerId]
         * @param {number} [teamId]
         * @param {Array<ApiSeriesGetWithEnum>} [_with]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeriesGet: async (tournamentId?: number, playerId?: number, teamId?: number, _with?: Array<ApiSeriesGetWithEnum>, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournament_id'] = tournamentId;
            }

            if (playerId !== undefined) {
                localVarQueryParameter['player_id'] = playerId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает MatchSeries по ID
         * @param {number} id
         * @param {Array<ApiSeriesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeriesIdGet: async (id: number, _with?: Array<ApiSeriesIdGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSeriesIdGet', 'id', id)
            const localVarPath = `/api/series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchApi - functional programming interface
 * @export
 */
export const MatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Match[]
         * @param {number} [tournamentId]
         * @param {number} [seriesId]
         * @param {number} [playerId]
         * @param {number} [teamId]
         * @param {Array<ApiMatchesGetWithEnum>} [_with]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMatchesGet(tournamentId?: number, seriesId?: number, playerId?: number, teamId?: number, _with?: Array<ApiMatchesGetWithEnum>, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Match>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMatchesGet(tournamentId, seriesId, playerId, teamId, _with, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MatchApi.apiMatchesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Match по ID
         * @param {number} id
         * @param {Array<ApiMatchesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMatchesIdGet(id: number, _with?: Array<ApiMatchesIdGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Match>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMatchesIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MatchApi.apiMatchesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию MatchSeries[]
         * @param {number} [tournamentId]
         * @param {number} [playerId]
         * @param {number} [teamId]
         * @param {Array<ApiSeriesGetWithEnum>} [_with]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSeriesGet(tournamentId?: number, playerId?: number, teamId?: number, _with?: Array<ApiSeriesGetWithEnum>, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatchSeries>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSeriesGet(tournamentId, playerId, teamId, _with, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MatchApi.apiSeriesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает MatchSeries по ID
         * @param {number} id
         * @param {Array<ApiSeriesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSeriesIdGet(id: number, _with?: Array<ApiSeriesIdGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchSeries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSeriesIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MatchApi.apiSeriesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MatchApi - factory interface
 * @export
 */
export const MatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Match[]
         * @param {number} [tournamentId]
         * @param {number} [seriesId]
         * @param {number} [playerId]
         * @param {number} [teamId]
         * @param {Array<ApiMatchesGetWithEnum>} [_with]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMatchesGet(tournamentId?: number, seriesId?: number, playerId?: number, teamId?: number, _with?: Array<ApiMatchesGetWithEnum>, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Match>> {
            return localVarFp.apiMatchesGet(tournamentId, seriesId, playerId, teamId, _with, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Match по ID
         * @param {number} id
         * @param {Array<ApiMatchesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMatchesIdGet(id: number, _with?: Array<ApiMatchesIdGetWithEnum>, options?: any): AxiosPromise<Match> {
            return localVarFp.apiMatchesIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию MatchSeries[]
         * @param {number} [tournamentId]
         * @param {number} [playerId]
         * @param {number} [teamId]
         * @param {Array<ApiSeriesGetWithEnum>} [_with]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeriesGet(tournamentId?: number, playerId?: number, teamId?: number, _with?: Array<ApiSeriesGetWithEnum>, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<MatchSeries>> {
            return localVarFp.apiSeriesGet(tournamentId, playerId, teamId, _with, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает MatchSeries по ID
         * @param {number} id
         * @param {Array<ApiSeriesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeriesIdGet(id: number, _with?: Array<ApiSeriesIdGetWithEnum>, options?: any): AxiosPromise<MatchSeries> {
            return localVarFp.apiSeriesIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatchApi - object-oriented interface
 * @export
 * @class MatchApi
 * @extends {BaseAPI}
 */
export class MatchApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию Match[]
     * @param {number} [tournamentId]
     * @param {number} [seriesId]
     * @param {number} [playerId]
     * @param {number} [teamId]
     * @param {Array<ApiMatchesGetWithEnum>} [_with]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public apiMatchesGet(tournamentId?: number, seriesId?: number, playerId?: number, teamId?: number, _with?: Array<ApiMatchesGetWithEnum>, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return MatchApiFp(this.configuration).apiMatchesGet(tournamentId, seriesId, playerId, teamId, _with, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Match по ID
     * @param {number} id
     * @param {Array<ApiMatchesIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public apiMatchesIdGet(id: number, _with?: Array<ApiMatchesIdGetWithEnum>, options?: RawAxiosRequestConfig) {
        return MatchApiFp(this.configuration).apiMatchesIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию MatchSeries[]
     * @param {number} [tournamentId]
     * @param {number} [playerId]
     * @param {number} [teamId]
     * @param {Array<ApiSeriesGetWithEnum>} [_with]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public apiSeriesGet(tournamentId?: number, playerId?: number, teamId?: number, _with?: Array<ApiSeriesGetWithEnum>, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return MatchApiFp(this.configuration).apiSeriesGet(tournamentId, playerId, teamId, _with, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает MatchSeries по ID
     * @param {number} id
     * @param {Array<ApiSeriesIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public apiSeriesIdGet(id: number, _with?: Array<ApiSeriesIdGetWithEnum>, options?: RawAxiosRequestConfig) {
        return MatchApiFp(this.configuration).apiSeriesIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiMatchesGetWithEnum = {
    Tournament: 'tournament',
    Round: 'round',
    Team1: 'team1',
    Team2: 'team2',
    Tour: 'tour',
    Series: 'series',
    Players: 'players',
    Protocol: 'protocol',
    MatchPlayers: 'matchPlayers',
    Periods: 'periods',
    MatchReferee: 'matchReferee',
    Referee: 'referee',
    MatchPlayers1: 'matchPlayers1',
    MatchPlayers2: 'matchPlayers2'
} as const;
export type ApiMatchesGetWithEnum = typeof ApiMatchesGetWithEnum[keyof typeof ApiMatchesGetWithEnum];
/**
 * @export
 */
export const ApiMatchesIdGetWithEnum = {
    Tournament: 'tournament',
    Round: 'round',
    Team1: 'team1',
    Team2: 'team2',
    Tour: 'tour',
    Series: 'series',
    Players: 'players',
    Protocol: 'protocol',
    MatchPlayers: 'matchPlayers',
    Periods: 'periods',
    MatchReferee: 'matchReferee',
    Referee: 'referee',
    MatchPlayers1: 'matchPlayers1',
    MatchPlayers2: 'matchPlayers2'
} as const;
export type ApiMatchesIdGetWithEnum = typeof ApiMatchesIdGetWithEnum[keyof typeof ApiMatchesIdGetWithEnum];
/**
 * @export
 */
export const ApiSeriesGetWithEnum = {
    Tournament: 'tournament',
    Round: 'round',
    Team1: 'team1',
    Team2: 'team2',
    Tour: 'tour',
    Tree: 'tree',
    NextSeries: 'nextSeries',
    LeftSeries: 'leftSeries',
    RightSeries: 'rightSeries',
    LooseSeries: 'looseSeries',
    ParentSeries: 'parentSeries',
    Matches: 'matches',
    PlayedMatches: 'playedMatches',
    NotPlayedMatches: 'notPlayedMatches',
    FinalMatch: 'finalMatch',
    Invoice: 'invoice',
    Winner: 'winner',
    Looser: 'looser'
} as const;
export type ApiSeriesGetWithEnum = typeof ApiSeriesGetWithEnum[keyof typeof ApiSeriesGetWithEnum];
/**
 * @export
 */
export const ApiSeriesIdGetWithEnum = {
    Tournament: 'tournament',
    Round: 'round',
    Team1: 'team1',
    Team2: 'team2',
    Tour: 'tour',
    Tree: 'tree',
    NextSeries: 'nextSeries',
    LeftSeries: 'leftSeries',
    RightSeries: 'rightSeries',
    LooseSeries: 'looseSeries',
    ParentSeries: 'parentSeries',
    Matches: 'matches',
    PlayedMatches: 'playedMatches',
    NotPlayedMatches: 'notPlayedMatches',
    FinalMatch: 'finalMatch',
    Invoice: 'invoice',
    Winner: 'winner',
    Looser: 'looser'
} as const;
export type ApiSeriesIdGetWithEnum = typeof ApiSeriesIdGetWithEnum[keyof typeof ApiSeriesIdGetWithEnum];


/**
 * PlayerApi - axios parameter creator
 * @export
 */
export const PlayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию Player[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayersGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Player по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlayersIdGet', 'id', id)
            const localVarPath = `/api/players/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает по ID команды, в которых состоял/состоит Player
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayersIdTeamsGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlayersIdTeamsGet', 'id', id)
            const localVarPath = `/api/players/{id}/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerApi - functional programming interface
 * @export
 */
export const PlayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Player[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Player>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayersGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PlayerApi.apiPlayersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Player по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayersIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PlayerApi.apiPlayersIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает по ID команды, в которых состоял/состоит Player
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayersIdTeamsGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamPlayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayersIdTeamsGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PlayerApi.apiPlayersIdTeamsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PlayerApi - factory interface
 * @export
 */
export const PlayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayerApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Player[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayersGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Player>> {
            return localVarFp.apiPlayersGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Player по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayersIdGet(id: number, options?: any): AxiosPromise<Player> {
            return localVarFp.apiPlayersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает по ID команды, в которых состоял/состоит Player
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayersIdTeamsGet(id: number, options?: any): AxiosPromise<Array<TeamPlayer>> {
            return localVarFp.apiPlayersIdTeamsGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerApi - object-oriented interface
 * @export
 * @class PlayerApi
 * @extends {BaseAPI}
 */
export class PlayerApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию Player[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public apiPlayersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).apiPlayersGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Player по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public apiPlayersIdGet(id: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).apiPlayersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает по ID команды, в которых состоял/состоит Player
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public apiPlayersIdTeamsGet(id: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).apiPlayersIdTeamsGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RedactionApi - axios parameter creator
 * @export
 */
export const RedactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию Album[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {Array<ApiAlbumsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsGet: async (page?: number, pageSize?: number, _with?: Array<ApiAlbumsGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/albums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Album по ID
         * @param {number} id
         * @param {Array<ApiAlbumsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsIdGet: async (id: number, _with?: Array<ApiAlbumsIdGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAlbumsIdGet', 'id', id)
            const localVarPath = `/api/albums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Banner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Banner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBannersIdGet', 'id', id)
            const localVarPath = `/api/banners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Page по alias
         * @param {string} alias
         * @param {Array<ApiPagesAliasGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesAliasGet: async (alias: string, _with?: Array<ApiPagesAliasGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alias' is not null or undefined
            assertParamExists('apiPagesAliasGet', 'alias', alias)
            const localVarPath = `/api/pages/{alias}`
                .replace(`{${"alias"}}`, encodeURIComponent(String(alias)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Page[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {Array<ApiPagesGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesGet: async (page?: number, pageSize?: number, _with?: Array<ApiPagesGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Page по ID
         * @param {number} id
         * @param {Array<ApiPagesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesIdGet: async (id: number, _with?: Array<ApiPagesIdGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPagesIdGet', 'id', id)
            const localVarPath = `/api/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Post по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPostsIdGet', 'id', id)
            const localVarPath = `/api/posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Tag[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Tag по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTagsIdGet', 'id', id)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Video[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Video по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVideosIdGet', 'id', id)
            const localVarPath = `/api/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RedactionApi - functional programming interface
 * @export
 */
export const RedactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RedactionApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Album[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {Array<ApiAlbumsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAlbumsGet(page?: number, pageSize?: number, _with?: Array<ApiAlbumsGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Album>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAlbumsGet(page, pageSize, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiAlbumsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Album по ID
         * @param {number} id
         * @param {Array<ApiAlbumsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAlbumsIdGet(id: number, _with?: Array<ApiAlbumsIdGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Album>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAlbumsIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiAlbumsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Banner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBannersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Banner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBannersGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiBannersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Banner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBannersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBannersIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiBannersIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Page по alias
         * @param {string} alias
         * @param {Array<ApiPagesAliasGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagesAliasGet(alias: string, _with?: Array<ApiPagesAliasGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagesAliasGet(alias, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiPagesAliasGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Page[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {Array<ApiPagesGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagesGet(page?: number, pageSize?: number, _with?: Array<ApiPagesGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Page>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagesGet(page, pageSize, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiPagesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Page по ID
         * @param {number} id
         * @param {Array<ApiPagesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagesIdGet(id: number, _with?: Array<ApiPagesIdGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagesIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiPagesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Post>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiPostsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Post по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiPostsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Tag[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiTagsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Tag по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiTagsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Video[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVideosGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Video>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVideosGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiVideosGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Video по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVideosIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVideosIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RedactionApi.apiVideosIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RedactionApi - factory interface
 * @export
 */
export const RedactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RedactionApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Album[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {Array<ApiAlbumsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsGet(page?: number, pageSize?: number, _with?: Array<ApiAlbumsGetWithEnum>, options?: any): AxiosPromise<Array<Album>> {
            return localVarFp.apiAlbumsGet(page, pageSize, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Album по ID
         * @param {number} id
         * @param {Array<ApiAlbumsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsIdGet(id: number, _with?: Array<ApiAlbumsIdGetWithEnum>, options?: any): AxiosPromise<Album> {
            return localVarFp.apiAlbumsIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Banner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Banner>> {
            return localVarFp.apiBannersGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Banner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersIdGet(id: number, options?: any): AxiosPromise<Banner> {
            return localVarFp.apiBannersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Page по alias
         * @param {string} alias
         * @param {Array<ApiPagesAliasGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesAliasGet(alias: string, _with?: Array<ApiPagesAliasGetWithEnum>, options?: any): AxiosPromise<Page> {
            return localVarFp.apiPagesAliasGet(alias, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Page[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {Array<ApiPagesGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesGet(page?: number, pageSize?: number, _with?: Array<ApiPagesGetWithEnum>, options?: any): AxiosPromise<Array<Page>> {
            return localVarFp.apiPagesGet(page, pageSize, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Page по ID
         * @param {number} id
         * @param {Array<ApiPagesIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesIdGet(id: number, _with?: Array<ApiPagesIdGetWithEnum>, options?: any): AxiosPromise<Page> {
            return localVarFp.apiPagesIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Post>> {
            return localVarFp.apiPostsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Post по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsIdGet(id: number, options?: any): AxiosPromise<Post> {
            return localVarFp.apiPostsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Tag[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.apiTagsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Tag по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Video[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Video>> {
            return localVarFp.apiVideosGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Video по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosIdGet(id: number, options?: any): AxiosPromise<Video> {
            return localVarFp.apiVideosIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RedactionApi - object-oriented interface
 * @export
 * @class RedactionApi
 * @extends {BaseAPI}
 */
export class RedactionApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию Album[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {Array<ApiAlbumsGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiAlbumsGet(page?: number, pageSize?: number, _with?: Array<ApiAlbumsGetWithEnum>, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiAlbumsGet(page, pageSize, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Album по ID
     * @param {number} id
     * @param {Array<ApiAlbumsIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiAlbumsIdGet(id: number, _with?: Array<ApiAlbumsIdGetWithEnum>, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiAlbumsIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Banner[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiBannersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiBannersGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Banner по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiBannersIdGet(id: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiBannersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Page по alias
     * @param {string} alias
     * @param {Array<ApiPagesAliasGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiPagesAliasGet(alias: string, _with?: Array<ApiPagesAliasGetWithEnum>, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiPagesAliasGet(alias, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Page[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {Array<ApiPagesGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiPagesGet(page?: number, pageSize?: number, _with?: Array<ApiPagesGetWithEnum>, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiPagesGet(page, pageSize, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Page по ID
     * @param {number} id
     * @param {Array<ApiPagesIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiPagesIdGet(id: number, _with?: Array<ApiPagesIdGetWithEnum>, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiPagesIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Post[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiPostsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiPostsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Post по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiPostsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiPostsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Tag[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiTagsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiTagsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Tag по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiTagsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiTagsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Video[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiVideosGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiVideosGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Video по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedactionApi
     */
    public apiVideosIdGet(id: number, options?: RawAxiosRequestConfig) {
        return RedactionApiFp(this.configuration).apiVideosIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiAlbumsGetWithEnum = {
    Cover: 'cover',
    Photos: 'photos'
} as const;
export type ApiAlbumsGetWithEnum = typeof ApiAlbumsGetWithEnum[keyof typeof ApiAlbumsGetWithEnum];
/**
 * @export
 */
export const ApiAlbumsIdGetWithEnum = {
    Cover: 'cover',
    Photos: 'photos'
} as const;
export type ApiAlbumsIdGetWithEnum = typeof ApiAlbumsIdGetWithEnum[keyof typeof ApiAlbumsIdGetWithEnum];
/**
 * @export
 */
export const ApiPagesAliasGetWithEnum = {
    Documents: 'documents'
} as const;
export type ApiPagesAliasGetWithEnum = typeof ApiPagesAliasGetWithEnum[keyof typeof ApiPagesAliasGetWithEnum];
/**
 * @export
 */
export const ApiPagesGetWithEnum = {
    Documents: 'documents'
} as const;
export type ApiPagesGetWithEnum = typeof ApiPagesGetWithEnum[keyof typeof ApiPagesGetWithEnum];
/**
 * @export
 */
export const ApiPagesIdGetWithEnum = {
    Documents: 'documents'
} as const;
export type ApiPagesIdGetWithEnum = typeof ApiPagesIdGetWithEnum[keyof typeof ApiPagesIdGetWithEnum];


/**
 * RefereeApi - axios parameter creator
 * @export
 */
export const RefereeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию Referee[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefereesGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/referees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Referee по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefereesIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRefereesIdGet', 'id', id)
            const localVarPath = `/api/referees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefereeApi - functional programming interface
 * @export
 */
export const RefereeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefereeApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Referee[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefereesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Referee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefereesGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RefereeApi.apiRefereesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Referee по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefereesIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Referee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefereesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RefereeApi.apiRefereesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RefereeApi - factory interface
 * @export
 */
export const RefereeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefereeApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Referee[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefereesGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Referee>> {
            return localVarFp.apiRefereesGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Referee по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefereesIdGet(id: number, options?: any): AxiosPromise<Referee> {
            return localVarFp.apiRefereesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefereeApi - object-oriented interface
 * @export
 * @class RefereeApi
 * @extends {BaseAPI}
 */
export class RefereeApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию Referee[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefereeApi
     */
    public apiRefereesGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return RefereeApiFp(this.configuration).apiRefereesGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Referee по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefereeApi
     */
    public apiRefereesIdGet(id: number, options?: RawAxiosRequestConfig) {
        return RefereeApiFp(this.configuration).apiRefereesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию StatsPlayer[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [playerId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsPlayersGet: async (seasonId?: number, tournamentId?: number, teamId?: number, playerId?: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stats/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['season_id'] = seasonId;
            }

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournament_id'] = tournamentId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (playerId !== undefined) {
                localVarQueryParameter['player_id'] = playerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию RatingPlayer[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsRatingsPlayersGet: async (seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stats/ratings/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['season_id'] = seasonId;
            }

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournament_id'] = tournamentId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию RatingTeam[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsRatingsTeamsGet: async (seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stats/ratings/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['season_id'] = seasonId;
            }

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournament_id'] = tournamentId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию StatsTeam[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsTeamsGet: async (seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stats/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['season_id'] = seasonId;
            }

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournament_id'] = tournamentId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию StatsPlayer[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [playerId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatsPlayersGet(seasonId?: number, tournamentId?: number, teamId?: number, playerId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatsPlayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatsPlayersGet(seasonId, tournamentId, teamId, playerId, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatsApi.apiStatsPlayersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию RatingPlayer[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatsRatingsPlayersGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RatingPlayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatsRatingsPlayersGet(seasonId, tournamentId, teamId, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatsApi.apiStatsRatingsPlayersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию RatingTeam[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatsRatingsTeamsGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RatingTeam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatsRatingsTeamsGet(seasonId, tournamentId, teamId, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatsApi.apiStatsRatingsTeamsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию StatsTeam[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatsTeamsGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatsTeam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatsTeamsGet(seasonId, tournamentId, teamId, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatsApi.apiStatsTeamsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию StatsPlayer[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [playerId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsPlayersGet(seasonId?: number, tournamentId?: number, teamId?: number, playerId?: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<StatsPlayer>> {
            return localVarFp.apiStatsPlayersGet(seasonId, tournamentId, teamId, playerId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию RatingPlayer[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsRatingsPlayersGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<RatingPlayer>> {
            return localVarFp.apiStatsRatingsPlayersGet(seasonId, tournamentId, teamId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию RatingTeam[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsRatingsTeamsGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<RatingTeam>> {
            return localVarFp.apiStatsRatingsTeamsGet(seasonId, tournamentId, teamId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию StatsTeam[]
         * @param {number} [seasonId]
         * @param {number} [tournamentId]
         * @param {number} [teamId]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatsTeamsGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<StatsTeam>> {
            return localVarFp.apiStatsTeamsGet(seasonId, tournamentId, teamId, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию StatsPlayer[]
     * @param {number} [seasonId]
     * @param {number} [tournamentId]
     * @param {number} [teamId]
     * @param {number} [playerId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public apiStatsPlayersGet(seasonId?: number, tournamentId?: number, teamId?: number, playerId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).apiStatsPlayersGet(seasonId, tournamentId, teamId, playerId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию RatingPlayer[]
     * @param {number} [seasonId]
     * @param {number} [tournamentId]
     * @param {number} [teamId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public apiStatsRatingsPlayersGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).apiStatsRatingsPlayersGet(seasonId, tournamentId, teamId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию RatingTeam[]
     * @param {number} [seasonId]
     * @param {number} [tournamentId]
     * @param {number} [teamId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public apiStatsRatingsTeamsGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).apiStatsRatingsTeamsGet(seasonId, tournamentId, teamId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию StatsTeam[]
     * @param {number} [seasonId]
     * @param {number} [tournamentId]
     * @param {number} [teamId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public apiStatsTeamsGet(seasonId?: number, tournamentId?: number, teamId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).apiStatsTeamsGet(seasonId, tournamentId, teamId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Заявка на создание команды
         * @param {number} userId ID пользователя, который создает команду
         * @param {string} fullName Полное название команды
         * @param {string} club Название клуба
         * @param {string} clubAddress Адрес клуба
         * @param {number} zoneId ID зоны
         * @param {string} courtCoverage Покрытие корта
         * @param {number} [cityId] ID города
         * @param {string} [phone] Телефон клуба
         * @param {string} [email] Email клуба
         * @param {File} [logo] Логотип команды
         * @param {File} [partnerLogo] Логотип партнера
         * @param {ApiTeamsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
         * @param {Array<TeamTempPlayerRequest>} [players] Массив игроков в формате JSON
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsCreatePost: async (userId: number, fullName: string, club: string, clubAddress: string, zoneId: number, courtCoverage: string, cityId?: number, phone?: string, email?: string, logo?: File, partnerLogo?: File, isDraft?: ApiTeamsCreatePostIsDraftEnum, players?: Array<TeamTempPlayerRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTeamsCreatePost', 'userId', userId)
            // verify required parameter 'fullName' is not null or undefined
            assertParamExists('apiTeamsCreatePost', 'fullName', fullName)
            // verify required parameter 'club' is not null or undefined
            assertParamExists('apiTeamsCreatePost', 'club', club)
            // verify required parameter 'clubAddress' is not null or undefined
            assertParamExists('apiTeamsCreatePost', 'clubAddress', clubAddress)
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('apiTeamsCreatePost', 'zoneId', zoneId)
            // verify required parameter 'courtCoverage' is not null or undefined
            assertParamExists('apiTeamsCreatePost', 'courtCoverage', courtCoverage)
            const localVarPath = `/api/teams/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (userId !== undefined) {
                localVarFormParams.append('user_id', userId as any);
            }

            if (fullName !== undefined) {
                localVarFormParams.append('full_name', fullName as any);
            }

            if (club !== undefined) {
                localVarFormParams.append('club', club as any);
            }

            if (clubAddress !== undefined) {
                localVarFormParams.append('club_address', clubAddress as any);
            }

            if (zoneId !== undefined) {
                localVarFormParams.append('zone_id', zoneId as any);
            }

            if (cityId !== undefined) {
                localVarFormParams.append('city_id', cityId as any);
            }

            if (courtCoverage !== undefined) {
                localVarFormParams.append('court_coverage', courtCoverage as any);
            }

            if (phone !== undefined) {
                localVarFormParams.append('phone', phone as any);
            }

            if (email !== undefined) {
                localVarFormParams.append('email', email as any);
            }

            if (logo !== undefined) {
                localVarFormParams.append('logo', logo as any);
            }

            if (partnerLogo !== undefined) {
                localVarFormParams.append('partner_logo', partnerLogo as any);
            }

            if (isDraft !== undefined) {
                localVarFormParams.append('is_draft', isDraft as any);
            }
                if (players) {
                localVarFormParams.append('players', players as any);
            }



            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Team[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Team по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTeamsIdGet', 'id', id)
            const localVarPath = `/api/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает список игроков команды TeamPlayer[]
         * @param {number} id
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsIdPlayersGet: async (id: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTeamsIdPlayersGet', 'id', id)
            const localVarPath = `/api/teams/{id}/players`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Team[] по идентификатору администратора
         * @param {number} userId Идентификатор пользователя
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsMineGet: async (userId: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTeamsMineGet', 'userId', userId)
            const localVarPath = `/api/teams/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Оплата команды
         * @param {number} id ID команды, требующей оплаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsPaymentIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTeamsPaymentIdGet', 'id', id)
            const localVarPath = `/api/teams/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на создание команды - TeamTemp[]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsRequestsGet: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/teams/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на создание команды по идентификатору создателя
         * @param {number} userId Идентификатор пользователя
         * @param {ApiTeamsRequestsMineGetStatusEnum} [status]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsRequestsMineGet: async (userId: number, status?: ApiTeamsRequestsMineGetStatusEnum, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTeamsRequestsMineGet', 'userId', userId)
            const localVarPath = `/api/teams/requests/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Заявка на создание команды
         * @param {number} userId ID пользователя, который создает команду
         * @param {string} fullName Полное название команды
         * @param {string} club Название клуба
         * @param {string} clubAddress Адрес клуба
         * @param {number} zoneId ID зоны
         * @param {string} courtCoverage Покрытие корта
         * @param {number} [cityId] ID города
         * @param {string} [phone] Телефон клуба
         * @param {string} [email] Email клуба
         * @param {File} [logo] Логотип команды
         * @param {File} [partnerLogo] Логотип партнера
         * @param {ApiTeamsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
         * @param {Array<TeamTempPlayerRequest>} [players] Массив игроков в формате JSON
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsCreatePost(userId: number, fullName: string, club: string, clubAddress: string, zoneId: number, courtCoverage: string, cityId?: number, phone?: string, email?: string, logo?: File, partnerLogo?: File, isDraft?: ApiTeamsCreatePostIsDraftEnum, players?: Array<TeamTempPlayerRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTeamsCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsCreatePost(userId, fullName, club, clubAddress, zoneId, courtCoverage, cityId, phone, email, logo, partnerLogo, isDraft, players, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Team[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Team по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает список игроков команды TeamPlayer[]
         * @param {number} id
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsIdPlayersGet(id: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamPlayer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsIdPlayersGet(id, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsIdPlayersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Team[] по идентификатору администратора
         * @param {number} userId Идентификатор пользователя
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsMineGet(userId: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsMineGet(userId, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsMineGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Оплата команды
         * @param {number} id ID команды, требующей оплаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsPaymentIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsPaymentIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsPaymentIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на создание команды - TeamTemp[]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsRequestsGet(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamTemp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsRequestsGet(page, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsRequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на создание команды по идентификатору создателя
         * @param {number} userId Идентификатор пользователя
         * @param {ApiTeamsRequestsMineGetStatusEnum} [status]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTeamsRequestsMineGet(userId: number, status?: ApiTeamsRequestsMineGetStatusEnum, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamTemp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTeamsRequestsMineGet(userId, status, page, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamApi.apiTeamsRequestsMineGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         *
         * @summary Заявка на создание команды
         * @param {number} userId ID пользователя, который создает команду
         * @param {string} fullName Полное название команды
         * @param {string} club Название клуба
         * @param {string} clubAddress Адрес клуба
         * @param {number} zoneId ID зоны
         * @param {string} courtCoverage Покрытие корта
         * @param {number} [cityId] ID города
         * @param {string} [phone] Телефон клуба
         * @param {string} [email] Email клуба
         * @param {File} [logo] Логотип команды
         * @param {File} [partnerLogo] Логотип партнера
         * @param {ApiTeamsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
         * @param {Array<TeamTempPlayerRequest>} [players] Массив игроков в формате JSON
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsCreatePost(userId: number, fullName: string, club: string, clubAddress: string, zoneId: number, courtCoverage: string, cityId?: number, phone?: string, email?: string, logo?: File, partnerLogo?: File, isDraft?: ApiTeamsCreatePostIsDraftEnum, players?: Array<TeamTempPlayerRequest>, options?: any): AxiosPromise<ApiTeamsCreatePost200Response> {
            return localVarFp.apiTeamsCreatePost(userId, fullName, club, clubAddress, zoneId, courtCoverage, cityId, phone, email, logo, partnerLogo, isDraft, players, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Team[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.apiTeamsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Team по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsIdGet(id: number, options?: any): AxiosPromise<Team> {
            return localVarFp.apiTeamsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает список игроков команды TeamPlayer[]
         * @param {number} id
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsIdPlayersGet(id: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<TeamPlayer>> {
            return localVarFp.apiTeamsIdPlayersGet(id, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Team[] по идентификатору администратора
         * @param {number} userId Идентификатор пользователя
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsMineGet(userId: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.apiTeamsMineGet(userId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Оплата команды
         * @param {number} id ID команды, требующей оплаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsPaymentIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTeamsPaymentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на создание команды - TeamTemp[]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsRequestsGet(page?: number, options?: any): AxiosPromise<Array<TeamTemp>> {
            return localVarFp.apiTeamsRequestsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на создание команды по идентификатору создателя
         * @param {number} userId Идентификатор пользователя
         * @param {ApiTeamsRequestsMineGetStatusEnum} [status]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTeamsRequestsMineGet(userId: number, status?: ApiTeamsRequestsMineGetStatusEnum, page?: number, options?: any): AxiosPromise<Array<TeamTemp>> {
            return localVarFp.apiTeamsRequestsMineGet(userId, status, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     *
     * @summary Заявка на создание команды
     * @param {number} userId ID пользователя, который создает команду
     * @param {string} fullName Полное название команды
     * @param {string} club Название клуба
     * @param {string} clubAddress Адрес клуба
     * @param {number} zoneId ID зоны
     * @param {string} courtCoverage Покрытие корта
     * @param {number} [cityId] ID города
     * @param {string} [phone] Телефон клуба
     * @param {string} [email] Email клуба
     * @param {File} [logo] Логотип команды
     * @param {File} [partnerLogo] Логотип партнера
     * @param {ApiTeamsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
     * @param {Array<TeamTempPlayerRequest>} [players] Массив игроков в формате JSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsCreatePost(userId: number, fullName: string, club: string, clubAddress: string, zoneId: number, courtCoverage: string, cityId?: number, phone?: string, email?: string, logo?: File, partnerLogo?: File, isDraft?: ApiTeamsCreatePostIsDraftEnum, players?: Array<TeamTempPlayerRequest>, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsCreatePost(userId, fullName, club, clubAddress, zoneId, courtCoverage, cityId, phone, email, logo, partnerLogo, isDraft, players, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Team[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Team по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает список игроков команды TeamPlayer[]
     * @param {number} id
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsIdPlayersGet(id: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsIdPlayersGet(id, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Team[] по идентификатору администратора
     * @param {number} userId Идентификатор пользователя
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsMineGet(userId: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsMineGet(userId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Оплата команды
     * @param {number} id ID команды, требующей оплаты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsPaymentIdGet(id: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsPaymentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию заявок на создание команды - TeamTemp[]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsRequestsGet(page?: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsRequestsGet(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию заявок на создание команды по идентификатору создателя
     * @param {number} userId Идентификатор пользователя
     * @param {ApiTeamsRequestsMineGetStatusEnum} [status]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public apiTeamsRequestsMineGet(userId: number, status?: ApiTeamsRequestsMineGetStatusEnum, page?: number, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).apiTeamsRequestsMineGet(userId, status, page, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiTeamsCreatePostIsDraftEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiTeamsCreatePostIsDraftEnum = typeof ApiTeamsCreatePostIsDraftEnum[keyof typeof ApiTeamsCreatePostIsDraftEnum];
/**
 * @export
 */
export const ApiTeamsRequestsMineGetStatusEnum = {
    Accepted: 'accepted',
    Rejected: 'rejected'
} as const;
export type ApiTeamsRequestsMineGetStatusEnum = typeof ApiTeamsRequestsMineGetStatusEnum[keyof typeof ApiTeamsRequestsMineGetStatusEnum];


/**
 * TournamentApi - axios parameter creator
 * @export
 */
export const TournamentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию неоплаченных коммерческих Tournament[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsCommercialUnpaidGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tournaments/commercial/unpaid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Заявка на создание коммерческого турнира
         * @param {number} userId ID пользователя, который создает турнир
         * @param {string} fullName Полное название турнира
         * @param {string} shortName Краткое название турнира
         * @param {string} club Название клуба
         * @param {string} clubAddress Адрес клуба
         * @param {string} startDt Начало проведения турнира
         * @param {string} endDt Окончание проведения турнира
         * @param {number} cityId ID города проведения турнира
         * @param {number} [zoneId] ID зоны проведения турнира
         * @param {string} [courtCoverage] Покрытие корта
         * @param {string} [officialBall] Официальная мяч
         * @param {string} [contactInfo] Контактные данные
         * @param {ApiTournamentsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsCreatePost: async (userId: number, fullName: string, shortName: string, club: string, clubAddress: string, startDt: string, endDt: string, cityId: number, zoneId?: number, courtCoverage?: string, officialBall?: string, contactInfo?: string, isDraft?: ApiTournamentsCreatePostIsDraftEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'userId', userId)
            // verify required parameter 'fullName' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'fullName', fullName)
            // verify required parameter 'shortName' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'shortName', shortName)
            // verify required parameter 'club' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'club', club)
            // verify required parameter 'clubAddress' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'clubAddress', clubAddress)
            // verify required parameter 'startDt' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'startDt', startDt)
            // verify required parameter 'endDt' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'endDt', endDt)
            // verify required parameter 'cityId' is not null or undefined
            assertParamExists('apiTournamentsCreatePost', 'cityId', cityId)
            const localVarPath = `/api/tournaments/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (userId !== undefined) {
                localVarFormParams.append('user_id', userId as any);
            }

            if (fullName !== undefined) {
                localVarFormParams.append('full_name', fullName as any);
            }

            if (shortName !== undefined) {
                localVarFormParams.append('short_name', shortName as any);
            }

            if (club !== undefined) {
                localVarFormParams.append('club', club as any);
            }

            if (clubAddress !== undefined) {
                localVarFormParams.append('club_address', clubAddress as any);
            }

            if (startDt !== undefined) {
                localVarFormParams.append('start_dt', startDt as any);
            }

            if (endDt !== undefined) {
                localVarFormParams.append('end_dt', endDt as any);
            }

            if (cityId !== undefined) {
                localVarFormParams.append('city_id', cityId as any);
            }

            if (zoneId !== undefined) {
                localVarFormParams.append('zone_id', zoneId as any);
            }

            if (courtCoverage !== undefined) {
                localVarFormParams.append('court_coverage', courtCoverage as any);
            }

            if (officialBall !== undefined) {
                localVarFormParams.append('official_ball', officialBall as any);
            }

            if (contactInfo !== undefined) {
                localVarFormParams.append('contact_info', contactInfo as any);
            }

            if (isDraft !== undefined) {
                localVarFormParams.append('is_draft', isDraft as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Tournament[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiTournamentsGetTypeEnum} [type]
         * @param {Array<ApiTournamentsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsGet: async (page?: number, pageSize?: number, type?: ApiTournamentsGetTypeEnum, _with?: Array<ApiTournamentsGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tournaments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Tournament по ID
         * @param {number} id
         * @param {Array<ApiTournamentsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdGet: async (id: number, _with?: Array<ApiTournamentsIdGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdGet', 'id', id)
            const localVarPath = `/api/tournaments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Round отмеченный как <финальный>
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsFinishGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsFinishGet', 'id', id)
            const localVarPath = `/api/tournaments/{id}/rounds/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Round[]
         * @param {number} id
         * @param {Array<ApiTournamentsIdRoundsGetWithEnum>} [_with]
         * @param {ApiTournamentsIdRoundsGetTypeEnum} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsGet: async (id: number, _with?: Array<ApiTournamentsIdRoundsGetWithEnum>, type?: ApiTournamentsIdRoundsGetTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsGet', 'id', id)
            const localVarPath = `/api/tournaments/{id}/rounds`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает шахматку
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdChessGet: async (id: number, roundId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdChessGet', 'id', id)
            // verify required parameter 'roundId' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdChessGet', 'roundId', roundId)
            const localVarPath = `/api/tournaments/{id}/rounds/{round_id}/chess`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"round_id"}}`, encodeURIComponent(String(roundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Round турнира
         * @param {number} id
         * @param {number} roundId
         * @param {Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdGet: async (id: number, roundId: number, _with?: Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdGet', 'id', id)
            // verify required parameter 'roundId' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdGet', 'roundId', roundId)
            const localVarPath = `/api/tournaments/{id}/rounds/{round_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"round_id"}}`, encodeURIComponent(String(roundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает плей-офф
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdPlayoffGet: async (id: number, roundId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdPlayoffGet', 'id', id)
            // verify required parameter 'roundId' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdPlayoffGet', 'roundId', roundId)
            const localVarPath = `/api/tournaments/{id}/rounds/{round_id}/playoff`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"round_id"}}`, encodeURIComponent(String(roundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает плей-офф HTML
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdPlayoffHtmlGet: async (id: number, roundId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdPlayoffHtmlGet', 'id', id)
            // verify required parameter 'roundId' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdPlayoffHtmlGet', 'roundId', roundId)
            const localVarPath = `/api/tournaments/{id}/rounds/{round_id}/playoff/html`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"round_id"}}`, encodeURIComponent(String(roundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает турнирную таблицу
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdTableGet: async (id: number, roundId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdTableGet', 'id', id)
            // verify required parameter 'roundId' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdTableGet', 'roundId', roundId)
            const localVarPath = `/api/tournaments/{id}/rounds/{round_id}/table`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"round_id"}}`, encodeURIComponent(String(roundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Team этапа
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdTeamsGet: async (id: number, roundId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdTeamsGet', 'id', id)
            // verify required parameter 'roundId' is not null or undefined
            assertParamExists('apiTournamentsIdRoundsRoundIdTeamsGet', 'roundId', roundId)
            const localVarPath = `/api/tournaments/{id}/rounds/{round_id}/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"round_id"}}`, encodeURIComponent(String(roundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Tournament[] по идентификатору администратора
         * @param {number} userId Идентификатор пользователя
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsMineGet: async (userId: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTournamentsMineGet', 'userId', userId)
            const localVarPath = `/api/tournaments/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Оплата коммерческого турнира
         * @param {number} id ID турнира, требующего оплаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsPaymentsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentsPaymentsIdGet', 'id', id)
            const localVarPath = `/api/tournaments/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на проведение коммерческих турниров - TournamentTemp[]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsRequestsGet: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tournaments/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на проведение коммерческих турниров по идентификатору создателя
         * @param {number} userId Идентификатор пользователя
         * @param {ApiTournamentsRequestsMineGetStatusEnum} [status]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsRequestsMineGet: async (userId: number, status?: ApiTournamentsRequestsMineGetStatusEnum, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTournamentsRequestsMineGet', 'userId', userId)
            const localVarPath = `/api/tournaments/requests/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TournamentApi - functional programming interface
 * @export
 */
export const TournamentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TournamentApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию неоплаченных коммерческих Tournament[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsCommercialUnpaidGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tournament>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsCommercialUnpaidGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsCommercialUnpaidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Заявка на создание коммерческого турнира
         * @param {number} userId ID пользователя, который создает турнир
         * @param {string} fullName Полное название турнира
         * @param {string} shortName Краткое название турнира
         * @param {string} club Название клуба
         * @param {string} clubAddress Адрес клуба
         * @param {string} startDt Начало проведения турнира
         * @param {string} endDt Окончание проведения турнира
         * @param {number} cityId ID города проведения турнира
         * @param {number} [zoneId] ID зоны проведения турнира
         * @param {string} [courtCoverage] Покрытие корта
         * @param {string} [officialBall] Официальная мяч
         * @param {string} [contactInfo] Контактные данные
         * @param {ApiTournamentsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsCreatePost(userId: number, fullName: string, shortName: string, club: string, clubAddress: string, startDt: string, endDt: string, cityId: number, zoneId?: number, courtCoverage?: string, officialBall?: string, contactInfo?: string, isDraft?: ApiTournamentsCreatePostIsDraftEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTournamentsCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsCreatePost(userId, fullName, shortName, club, clubAddress, startDt, endDt, cityId, zoneId, courtCoverage, officialBall, contactInfo, isDraft, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Tournament[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiTournamentsGetTypeEnum} [type]
         * @param {Array<ApiTournamentsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsGet(page?: number, pageSize?: number, type?: ApiTournamentsGetTypeEnum, _with?: Array<ApiTournamentsGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tournament>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsGet(page, pageSize, type, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Tournament по ID
         * @param {number} id
         * @param {Array<ApiTournamentsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdGet(id: number, _with?: Array<ApiTournamentsIdGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tournament>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Round отмеченный как <финальный>
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsFinishGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Round>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsFinishGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsFinishGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Round[]
         * @param {number} id
         * @param {Array<ApiTournamentsIdRoundsGetWithEnum>} [_with]
         * @param {ApiTournamentsIdRoundsGetTypeEnum} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsGet(id: number, _with?: Array<ApiTournamentsIdRoundsGetWithEnum>, type?: ApiTournamentsIdRoundsGetTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Round>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsGet(id, _with, type, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает шахматку
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsRoundIdChessGet(id: number, roundId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsRoundIdChessGet(id, roundId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsRoundIdChessGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Round турнира
         * @param {number} id
         * @param {number} roundId
         * @param {Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsRoundIdGet(id: number, roundId: number, _with?: Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Round>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsRoundIdGet(id, roundId, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsRoundIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает плей-офф
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsRoundIdPlayoffGet(id: number, roundId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsRoundIdPlayoffGet(id, roundId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsRoundIdPlayoffGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает плей-офф HTML
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsRoundIdPlayoffHtmlGet(id: number, roundId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsRoundIdPlayoffHtmlGet(id, roundId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsRoundIdPlayoffHtmlGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает турнирную таблицу
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsRoundIdTableGet(id: number, roundId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsRoundIdTableGet(id, roundId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsRoundIdTableGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Team этапа
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsIdRoundsRoundIdTeamsGet(id: number, roundId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsIdRoundsRoundIdTeamsGet(id, roundId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsIdRoundsRoundIdTeamsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Tournament[] по идентификатору администратора
         * @param {number} userId Идентификатор пользователя
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsMineGet(userId: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tournament>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsMineGet(userId, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsMineGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Оплата коммерческого турнира
         * @param {number} id ID турнира, требующего оплаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsPaymentsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsPaymentsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsPaymentsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на проведение коммерческих турниров - TournamentTemp[]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsRequestsGet(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TournamentTemp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsRequestsGet(page, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsRequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на проведение коммерческих турниров по идентификатору создателя
         * @param {number} userId Идентификатор пользователя
         * @param {ApiTournamentsRequestsMineGetStatusEnum} [status]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentsRequestsMineGet(userId: number, status?: ApiTournamentsRequestsMineGetStatusEnum, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TournamentTemp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentsRequestsMineGet(userId, status, page, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TournamentApi.apiTournamentsRequestsMineGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TournamentApi - factory interface
 * @export
 */
export const TournamentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TournamentApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию неоплаченных коммерческих Tournament[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsCommercialUnpaidGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Tournament>> {
            return localVarFp.apiTournamentsCommercialUnpaidGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Заявка на создание коммерческого турнира
         * @param {number} userId ID пользователя, который создает турнир
         * @param {string} fullName Полное название турнира
         * @param {string} shortName Краткое название турнира
         * @param {string} club Название клуба
         * @param {string} clubAddress Адрес клуба
         * @param {string} startDt Начало проведения турнира
         * @param {string} endDt Окончание проведения турнира
         * @param {number} cityId ID города проведения турнира
         * @param {number} [zoneId] ID зоны проведения турнира
         * @param {string} [courtCoverage] Покрытие корта
         * @param {string} [officialBall] Официальная мяч
         * @param {string} [contactInfo] Контактные данные
         * @param {ApiTournamentsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsCreatePost(userId: number, fullName: string, shortName: string, club: string, clubAddress: string, startDt: string, endDt: string, cityId: number, zoneId?: number, courtCoverage?: string, officialBall?: string, contactInfo?: string, isDraft?: ApiTournamentsCreatePostIsDraftEnum, options?: any): AxiosPromise<ApiTournamentsCreatePost200Response> {
            return localVarFp.apiTournamentsCreatePost(userId, fullName, shortName, club, clubAddress, startDt, endDt, cityId, zoneId, courtCoverage, officialBall, contactInfo, isDraft, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Tournament[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiTournamentsGetTypeEnum} [type]
         * @param {Array<ApiTournamentsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsGet(page?: number, pageSize?: number, type?: ApiTournamentsGetTypeEnum, _with?: Array<ApiTournamentsGetWithEnum>, options?: any): AxiosPromise<Array<Tournament>> {
            return localVarFp.apiTournamentsGet(page, pageSize, type, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Tournament по ID
         * @param {number} id
         * @param {Array<ApiTournamentsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdGet(id: number, _with?: Array<ApiTournamentsIdGetWithEnum>, options?: any): AxiosPromise<Tournament> {
            return localVarFp.apiTournamentsIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Round отмеченный как <финальный>
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsFinishGet(id: number, options?: any): AxiosPromise<Round> {
            return localVarFp.apiTournamentsIdRoundsFinishGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Round[]
         * @param {number} id
         * @param {Array<ApiTournamentsIdRoundsGetWithEnum>} [_with]
         * @param {ApiTournamentsIdRoundsGetTypeEnum} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsGet(id: number, _with?: Array<ApiTournamentsIdRoundsGetWithEnum>, type?: ApiTournamentsIdRoundsGetTypeEnum, options?: any): AxiosPromise<Array<Round>> {
            return localVarFp.apiTournamentsIdRoundsGet(id, _with, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает шахматку
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdChessGet(id: number, roundId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.apiTournamentsIdRoundsRoundIdChessGet(id, roundId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Round турнира
         * @param {number} id
         * @param {number} roundId
         * @param {Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdGet(id: number, roundId: number, _with?: Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>, options?: any): AxiosPromise<Round> {
            return localVarFp.apiTournamentsIdRoundsRoundIdGet(id, roundId, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает плей-офф
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdPlayoffGet(id: number, roundId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.apiTournamentsIdRoundsRoundIdPlayoffGet(id, roundId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает плей-офф HTML
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdPlayoffHtmlGet(id: number, roundId: number, options?: any): AxiosPromise<ApiTournamentsIdRoundsRoundIdPlayoffHtmlGet200Response> {
            return localVarFp.apiTournamentsIdRoundsRoundIdPlayoffHtmlGet(id, roundId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает турнирную таблицу
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdTableGet(id: number, roundId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.apiTournamentsIdRoundsRoundIdTableGet(id, roundId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Team этапа
         * @param {number} id
         * @param {number} roundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsIdRoundsRoundIdTeamsGet(id: number, roundId: number, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.apiTournamentsIdRoundsRoundIdTeamsGet(id, roundId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Tournament[] по идентификатору администратора
         * @param {number} userId Идентификатор пользователя
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsMineGet(userId: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Tournament>> {
            return localVarFp.apiTournamentsMineGet(userId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Оплата коммерческого турнира
         * @param {number} id ID турнира, требующего оплаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsPaymentsIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTournamentsPaymentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на проведение коммерческих турниров - TournamentTemp[]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsRequestsGet(page?: number, options?: any): AxiosPromise<Array<TournamentTemp>> {
            return localVarFp.apiTournamentsRequestsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию заявок на проведение коммерческих турниров по идентификатору создателя
         * @param {number} userId Идентификатор пользователя
         * @param {ApiTournamentsRequestsMineGetStatusEnum} [status]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentsRequestsMineGet(userId: number, status?: ApiTournamentsRequestsMineGetStatusEnum, page?: number, options?: any): AxiosPromise<Array<TournamentTemp>> {
            return localVarFp.apiTournamentsRequestsMineGet(userId, status, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TournamentApi - object-oriented interface
 * @export
 * @class TournamentApi
 * @extends {BaseAPI}
 */
export class TournamentApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию неоплаченных коммерческих Tournament[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsCommercialUnpaidGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsCommercialUnpaidGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Заявка на создание коммерческого турнира
     * @param {number} userId ID пользователя, который создает турнир
     * @param {string} fullName Полное название турнира
     * @param {string} shortName Краткое название турнира
     * @param {string} club Название клуба
     * @param {string} clubAddress Адрес клуба
     * @param {string} startDt Начало проведения турнира
     * @param {string} endDt Окончание проведения турнира
     * @param {number} cityId ID города проведения турнира
     * @param {number} [zoneId] ID зоны проведения турнира
     * @param {string} [courtCoverage] Покрытие корта
     * @param {string} [officialBall] Официальная мяч
     * @param {string} [contactInfo] Контактные данные
     * @param {ApiTournamentsCreatePostIsDraftEnum} [isDraft] Сохранить как черновик
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsCreatePost(userId: number, fullName: string, shortName: string, club: string, clubAddress: string, startDt: string, endDt: string, cityId: number, zoneId?: number, courtCoverage?: string, officialBall?: string, contactInfo?: string, isDraft?: ApiTournamentsCreatePostIsDraftEnum, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsCreatePost(userId, fullName, shortName, club, clubAddress, startDt, endDt, cityId, zoneId, courtCoverage, officialBall, contactInfo, isDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Tournament[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiTournamentsGetTypeEnum} [type]
     * @param {Array<ApiTournamentsGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsGet(page?: number, pageSize?: number, type?: ApiTournamentsGetTypeEnum, _with?: Array<ApiTournamentsGetWithEnum>, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsGet(page, pageSize, type, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Tournament по ID
     * @param {number} id
     * @param {Array<ApiTournamentsIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdGet(id: number, _with?: Array<ApiTournamentsIdGetWithEnum>, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Round отмеченный как <финальный>
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsFinishGet(id: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsFinishGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Round[]
     * @param {number} id
     * @param {Array<ApiTournamentsIdRoundsGetWithEnum>} [_with]
     * @param {ApiTournamentsIdRoundsGetTypeEnum} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsGet(id: number, _with?: Array<ApiTournamentsIdRoundsGetWithEnum>, type?: ApiTournamentsIdRoundsGetTypeEnum, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsGet(id, _with, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает шахматку
     * @param {number} id
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsRoundIdChessGet(id: number, roundId: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsRoundIdChessGet(id, roundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Round турнира
     * @param {number} id
     * @param {number} roundId
     * @param {Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsRoundIdGet(id: number, roundId: number, _with?: Array<ApiTournamentsIdRoundsRoundIdGetWithEnum>, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsRoundIdGet(id, roundId, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает плей-офф
     * @param {number} id
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsRoundIdPlayoffGet(id: number, roundId: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsRoundIdPlayoffGet(id, roundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает плей-офф HTML
     * @param {number} id
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsRoundIdPlayoffHtmlGet(id: number, roundId: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsRoundIdPlayoffHtmlGet(id, roundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает турнирную таблицу
     * @param {number} id
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsRoundIdTableGet(id: number, roundId: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsRoundIdTableGet(id, roundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Team этапа
     * @param {number} id
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsIdRoundsRoundIdTeamsGet(id: number, roundId: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsIdRoundsRoundIdTeamsGet(id, roundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Tournament[] по идентификатору администратора
     * @param {number} userId Идентификатор пользователя
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsMineGet(userId: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsMineGet(userId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Оплата коммерческого турнира
     * @param {number} id ID турнира, требующего оплаты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsPaymentsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsPaymentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию заявок на проведение коммерческих турниров - TournamentTemp[]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsRequestsGet(page?: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsRequestsGet(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию заявок на проведение коммерческих турниров по идентификатору создателя
     * @param {number} userId Идентификатор пользователя
     * @param {ApiTournamentsRequestsMineGetStatusEnum} [status]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentApi
     */
    public apiTournamentsRequestsMineGet(userId: number, status?: ApiTournamentsRequestsMineGetStatusEnum, page?: number, options?: RawAxiosRequestConfig) {
        return TournamentApiFp(this.configuration).apiTournamentsRequestsMineGet(userId, status, page, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiTournamentsCreatePostIsDraftEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiTournamentsCreatePostIsDraftEnum = typeof ApiTournamentsCreatePostIsDraftEnum[keyof typeof ApiTournamentsCreatePostIsDraftEnum];
/**
 * @export
 */
export const ApiTournamentsGetTypeEnum = {
    League: 'league',
    Commercial: 'commercial'
} as const;
export type ApiTournamentsGetTypeEnum = typeof ApiTournamentsGetTypeEnum[keyof typeof ApiTournamentsGetTypeEnum];
/**
 * @export
 */
export const ApiTournamentsGetWithEnum = {
    Season: 'season',
    League: 'league',
    Region: 'region',
    Zone: 'zone',
    City: 'city',
    Product: 'product',
    Rounds: 'rounds',
    Documents: 'documents',
    Partners: 'partners',
    Winners: 'winners'
} as const;
export type ApiTournamentsGetWithEnum = typeof ApiTournamentsGetWithEnum[keyof typeof ApiTournamentsGetWithEnum];
/**
 * @export
 */
export const ApiTournamentsIdGetWithEnum = {
    Season: 'season',
    League: 'league',
    Region: 'region',
    Zone: 'zone',
    City: 'city',
    Product: 'product',
    Rounds: 'rounds',
    Documents: 'documents',
    Partners: 'partners',
    Winners: 'winners'
} as const;
export type ApiTournamentsIdGetWithEnum = typeof ApiTournamentsIdGetWithEnum[keyof typeof ApiTournamentsIdGetWithEnum];
/**
 * @export
 */
export const ApiTournamentsIdRoundsGetWithEnum = {
    Tournament: 'tournament',
    Table: 'table',
    Chess: 'chess',
    Playoff: 'playoff',
    PlayoffHtml: 'playoffHtml'
} as const;
export type ApiTournamentsIdRoundsGetWithEnum = typeof ApiTournamentsIdRoundsGetWithEnum[keyof typeof ApiTournamentsIdRoundsGetWithEnum];
/**
 * @export
 */
export const ApiTournamentsIdRoundsGetTypeEnum = {
    RoundRobinOne: 'round-robin-one',
    RoundRobinTwo: 'round-robin-two',
    PlayoffOlympic: 'playoff-olympic',
    Free: 'free'
} as const;
export type ApiTournamentsIdRoundsGetTypeEnum = typeof ApiTournamentsIdRoundsGetTypeEnum[keyof typeof ApiTournamentsIdRoundsGetTypeEnum];
/**
 * @export
 */
export const ApiTournamentsIdRoundsRoundIdGetWithEnum = {
    Tournament: 'tournament',
    Table: 'table',
    Chess: 'chess',
    Playoff: 'playoff',
    PlayoffHtml: 'playoffHtml'
} as const;
export type ApiTournamentsIdRoundsRoundIdGetWithEnum = typeof ApiTournamentsIdRoundsRoundIdGetWithEnum[keyof typeof ApiTournamentsIdRoundsRoundIdGetWithEnum];
/**
 * @export
 */
export const ApiTournamentsRequestsMineGetStatusEnum = {
    Accepted: 'accepted',
    Rejected: 'rejected'
} as const;
export type ApiTournamentsRequestsMineGetStatusEnum = typeof ApiTournamentsRequestsMineGetStatusEnum[keyof typeof ApiTournamentsRequestsMineGetStatusEnum];


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Подтверждение пользователя
         * @param {number} id
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersConfirmIdCodeGet: async (id: number, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersConfirmIdCodeGet', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiUsersConfirmIdCodeGet', 'code', code)
            const localVarPath = `/api/users/confirm/{id}/{code}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает список желаемых ролей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDesireRolesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/desire-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Восстановление пароля
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersForgotPost: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiUsersForgotPost', 'email', email)
            const localVarPath = `/api/users/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) {
                localVarFormParams.set('email', email as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию User[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает User по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdGet', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Вход
         * @param {string} login Логин
         * @param {string} password Пароль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersLoginPost: async (login: string, password: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('apiUsersLoginPost', 'login', login)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiUsersLoginPost', 'password', password)
            const localVarPath = `/api/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (login !== undefined) {
                localVarFormParams.set('login', login as any);
            }

            if (password !== undefined) {
                localVarFormParams.set('password', password as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Смена пароля
         * @param {number} id
         * @param {string} code
         * @param {string} password
         * @param {string} passwordRepeat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersRecoverIdCodePost: async (id: number, code: string, password: string, passwordRepeat: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersRecoverIdCodePost', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiUsersRecoverIdCodePost', 'code', code)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiUsersRecoverIdCodePost', 'password', password)
            // verify required parameter 'passwordRepeat' is not null or undefined
            assertParamExists('apiUsersRecoverIdCodePost', 'passwordRepeat', passwordRepeat)
            const localVarPath = `/api/users/recover/{id}/{code}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (password !== undefined) {
                localVarFormParams.set('password', password as any);
            }

            if (passwordRepeat !== undefined) {
                localVarFormParams.set('passwordRepeat', passwordRepeat as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Создаёт User
         * @param {string} firstName Имя
         * @param {string} lastName Фамилия
         * @param {string} email Email
         * @param {string} phone Телефон
         * @param {string} password Пароль
         * @param {string} passwordRepeat Повтор пароля
         * @param {string} reCaptcha reCaptcha
         * @param {number} [desireRole] Желаемая роль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersRegisterPost: async (firstName: string, lastName: string, email: string, phone: string, password: string, passwordRepeat: string, reCaptcha: string, desireRole?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'lastName', lastName)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'email', email)
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'phone', phone)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'password', password)
            // verify required parameter 'passwordRepeat' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'passwordRepeat', passwordRepeat)
            // verify required parameter 'reCaptcha' is not null or undefined
            assertParamExists('apiUsersRegisterPost', 'reCaptcha', reCaptcha)
            const localVarPath = `/api/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (firstName !== undefined) {
                localVarFormParams.set('firstName', firstName as any);
            }

            if (lastName !== undefined) {
                localVarFormParams.set('lastName', lastName as any);
            }

            if (email !== undefined) {
                localVarFormParams.set('email', email as any);
            }

            if (phone !== undefined) {
                localVarFormParams.set('phone', phone as any);
            }

            if (desireRole !== undefined) {
                localVarFormParams.set('desireRole', desireRole as any);
            }

            if (password !== undefined) {
                localVarFormParams.set('password', password as any);
            }

            if (passwordRepeat !== undefined) {
                localVarFormParams.set('passwordRepeat', passwordRepeat as any);
            }

            if (reCaptcha !== undefined) {
                localVarFormParams.set('reCaptcha', reCaptcha as any);
            }


            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Подтверждение пользователя
         * @param {number} id
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersConfirmIdCodeGet(id: number, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUsersConfirmIdCodeGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersConfirmIdCodeGet(id, code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersConfirmIdCodeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает список желаемых ролей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersDesireRolesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiUsersDesireRolesGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersDesireRolesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersDesireRolesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Восстановление пароля
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersForgotPost(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUsersForgotPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersForgotPost(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersForgotPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию User[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает User по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Вход
         * @param {string} login Логин
         * @param {string} password Пароль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersLoginPost(login: string, password: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUsersLoginPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersLoginPost(login, password, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersLoginPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Смена пароля
         * @param {number} id
         * @param {string} code
         * @param {string} password
         * @param {string} passwordRepeat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersRecoverIdCodePost(id: number, code: string, password: string, passwordRepeat: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUsersRecoverIdCodePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersRecoverIdCodePost(id, code, password, passwordRepeat, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersRecoverIdCodePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Создаёт User
         * @param {string} firstName Имя
         * @param {string} lastName Фамилия
         * @param {string} email Email
         * @param {string} phone Телефон
         * @param {string} password Пароль
         * @param {string} passwordRepeat Повтор пароля
         * @param {string} reCaptcha reCaptcha
         * @param {number} [desireRole] Желаемая роль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersRegisterPost(firstName: string, lastName: string, email: string, phone: string, password: string, passwordRepeat: string, reCaptcha: string, desireRole?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUsersRegisterPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersRegisterPost(firstName, lastName, email, phone, password, passwordRepeat, reCaptcha, desireRole, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUsersRegisterPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         *
         * @summary Подтверждение пользователя
         * @param {number} id
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersConfirmIdCodeGet(id: number, code: string, options?: any): AxiosPromise<ApiUsersConfirmIdCodeGet200Response> {
            return localVarFp.apiUsersConfirmIdCodeGet(id, code, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает список желаемых ролей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDesireRolesGet(options?: any): AxiosPromise<Array<ApiUsersDesireRolesGet200ResponseInner>> {
            return localVarFp.apiUsersDesireRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Восстановление пароля
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersForgotPost(email: string, options?: any): AxiosPromise<ApiUsersForgotPost200Response> {
            return localVarFp.apiUsersForgotPost(email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию User[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.apiUsersGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает User по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.apiUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Вход
         * @param {string} login Логин
         * @param {string} password Пароль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersLoginPost(login: string, password: string, options?: any): AxiosPromise<ApiUsersLoginPost200Response> {
            return localVarFp.apiUsersLoginPost(login, password, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Смена пароля
         * @param {number} id
         * @param {string} code
         * @param {string} password
         * @param {string} passwordRepeat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersRecoverIdCodePost(id: number, code: string, password: string, passwordRepeat: string, options?: any): AxiosPromise<ApiUsersRecoverIdCodePost200Response> {
            return localVarFp.apiUsersRecoverIdCodePost(id, code, password, passwordRepeat, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Создаёт User
         * @param {string} firstName Имя
         * @param {string} lastName Фамилия
         * @param {string} email Email
         * @param {string} phone Телефон
         * @param {string} password Пароль
         * @param {string} passwordRepeat Повтор пароля
         * @param {string} reCaptcha reCaptcha
         * @param {number} [desireRole] Желаемая роль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersRegisterPost(firstName: string, lastName: string, email: string, phone: string, password: string, passwordRepeat: string, reCaptcha: string, desireRole?: number, options?: any): AxiosPromise<ApiUsersRegisterPost200Response> {
            return localVarFp.apiUsersRegisterPost(firstName, lastName, email, phone, password, passwordRepeat, reCaptcha, desireRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @summary Подтверждение пользователя
     * @param {number} id
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersConfirmIdCodeGet(id: number, code: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersConfirmIdCodeGet(id, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает список желаемых ролей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersDesireRolesGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersDesireRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Восстановление пароля
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersForgotPost(email: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersForgotPost(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию User[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersGet(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает User по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersIdGet(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Вход
     * @param {string} login Логин
     * @param {string} password Пароль
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersLoginPost(login: string, password: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersLoginPost(login, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Смена пароля
     * @param {number} id
     * @param {string} code
     * @param {string} password
     * @param {string} passwordRepeat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersRecoverIdCodePost(id: number, code: string, password: string, passwordRepeat: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersRecoverIdCodePost(id, code, password, passwordRepeat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Создаёт User
     * @param {string} firstName Имя
     * @param {string} lastName Фамилия
     * @param {string} email Email
     * @param {string} phone Телефон
     * @param {string} password Пароль
     * @param {string} passwordRepeat Повтор пароля
     * @param {string} reCaptcha reCaptcha
     * @param {number} [desireRole] Желаемая роль
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersRegisterPost(firstName: string, lastName: string, email: string, phone: string, password: string, passwordRepeat: string, reCaptcha: string, desireRole?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersRegisterPost(firstName, lastName, email, phone, password, passwordRepeat, reCaptcha, desireRole, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVersionGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiVersionGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVersionGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VersionApi.apiVersionGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any): AxiosPromise<ApiVersionGet200Response> {
            return localVarFp.apiVersionGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * @export
 */
export const ApiPagesAliasGetAliasEnum = {
  About: "about",
  LegalInformation: "legal-information",
} as const;
export type ApiPagesAliasGetAliasEnum =
  (typeof ApiPagesAliasGetAliasEnum)[keyof typeof ApiPagesAliasGetAliasEnum];
/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public apiVersionGet(options?: RawAxiosRequestConfig) {
        return VersionApiFp(this.configuration).apiVersionGet(options).then((request) => request(this.axios, this.basePath));
    }
}



