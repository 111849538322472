import {FilterOption} from "../types/common"
import {getYear} from "date-fns"
import {range} from "../utils/common-helpers"

export enum ItemsForSort {
  TeamsRating = "teamsRating",
  PlayersRating = "playersRating",
  RoundTablesInner = "roundTablesInner",
  PlayersMatches = "playersMatches"
}

export enum ItemsForFilter {
  Leagues = "leagues",
  Tournaments = "tournaments",
  Videos = "videos",
  Albums = "albums",
  Posts = "posts",
  PlayersRating = "playersRating",
  TeamsRating = "teamsRating",
  MatchSeriesArray = "matchSeriesArray",
  TeamMatchSeriesArray = "teamMatchSeriesArray",
  PlayerInfo = 'playerInfo'
}

export enum DropdownTypes {
  TournamentType = "tournamentType",
  Season = "season",
  Region = "region",
  Stage = "stage",
  Undefined = "undefined"
}

export enum DropdownPlaceholders {
  TournamentType = "тип турнира",
  Season = "сезон",
  Region = "регион",
  Stage = "этап",
  Undefined = "не работает"
}

export const years = range(1990, getYear(new Date()) + 1, 1)

export const yearsAsOptions: FilterOption[] = years.map((year) => ({
  value: year,
  label: String(year)
}))

export const filterTournamentsType = [
  {value: "league", label: "лига"},
  {value: "commercial", label: "турнир"}
]

export const filterStage = [
  {value: "regionalRound", label: "региональный этап"},
  {value: "regionalPlayoff", label: "региональный плей-офф"},
  {value: "allRussianPlayOff", label: "всероссийский плей-офф"}
]
