import React from "react";

function Amount({amount, type}: {
  amount: number,
  type: string
}) {
  return (
    <div className="amount">
      <p className="amount__text">всего {type}:</p>
      <p className="amount__number">{amount}</p>
    </div>
  );
}

export default Amount;
