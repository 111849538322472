import React from 'react'
import {Link, useLocation} from "react-router-dom"
import {AppRoute} from "../../../core/constants/routes"
import {
  Post,
  Video
} from "../../../api"
import {roundDateFormat} from "../../../core/utils/date-time-helpers";

function SidebarItem({content}: {content: Post | Video}) {
  const location = useLocation()
  const isVideosPage = location.pathname === AppRoute.VideosPage.url;

  const publishedDate: string | undefined = content.published_dt;
  const formattedDay: string | undefined = publishedDate ? roundDateFormat(new Date(publishedDate), "dd") : "";
  const formattedMonth: string | undefined = publishedDate ? roundDateFormat(new Date(publishedDate), "MMM") : "";

  let src;
  let contentId;

  if (content._links) {
    if ("preview" in content._links) {
      src = content._links?.preview?.href;
    } else if ("image1120x633" in content._links) {
      src = content._links?.image1120x633?.href;
      if (
        content._links?.image1120x633?.href?.match(/image_placeholder/)
      ) {
        src = "/images/default/content.svg";
      }
    } else {
      src = "/images/default/content.svg";
    }
  }

  if ("video_id" in content) {
    contentId = content.video_id;
  } else if ("post_id" in content) {
    contentId = content.post_id;
  }

  const contentPath = isVideosPage
      ? `${AppRoute.VideosPage.url}/${contentId}`
      : `${AppRoute.PostsPage.url}/${contentId}`;

  return (
    <li className="sidebar__item">
      <Link className="sidebar__link" to={contentPath}>
        <div className="sidebar__content">
          <img className="sidebar__img" src={src} width="340" height="192" alt={content.title}/>
          {isVideosPage && (
            <div className="sidebar__block">
              <svg className="sidebar__icon" width="60" height="60">
                <use xlinkHref="images/sprite.svg#play"/>
              </svg>
            </div>
          )}
          <div className="sidebar__count">
            <p className="sidebar__date">{formattedDay}</p>
            <p className="sidebar__date" title={formattedMonth}>{formattedMonth}</p>
          </div>
        </div>
        <p className="sidebar__text">{content.title}</p>
      </Link>
    </li>
  )
}

export default SidebarItem
