import {matchPath} from 'react-router-dom'
import {AppRoute} from '../constants/routes'
import {ItemsForFilter} from '../constants/filters'
import {
  Player,
  Round,
  Team,
  Tournament,
  TournamentTypeEnum
} from "../../api";
import { Area } from 'react-easy-crop'

export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function range(start: number, end: number, step: number = 1): number[] {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

export const highlightSearchQuery = (text: string, searchQuery?: string) => {
  if (!searchQuery || !text) return text;
  const escapedSearchQuery = searchQuery.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearchQuery})`, 'gi');
  return text.replace(regex, `<span class="highlight">$1</span>`);
};

export function getItemForPageType(locationPathname: string) {
  if (matchPath({path: AppRoute.VideosPage.url}, locationPathname)) {
    return ItemsForFilter.Videos;
  } else if (matchPath({path: AppRoute.AlbumsPage.url}, locationPathname)) {
    return ItemsForFilter.Albums;
  } else {
    return ItemsForFilter.Posts;
  }
}

export function generateTournamentButtonsArray(tournament: Tournament | null) {
  return [
    {
      title: "Таблицы и сетки",
      url: `${AppRoute.RatingPage.url}/${tournament?.tournament_id}`
    },
    {
      title: "Протоколы",
      url: `${AppRoute.ProtocolsPage.url}/${tournament?.tournament_id}`
    },
    {
      title: `О ${tournament?.type === TournamentTypeEnum.Commercial ? 'турнире' : 'лиге'}`,
      url: `${AppRoute.AboutPage.url}/${tournament?.tournament_id}`,
      hidden: tournament?.type !== TournamentTypeEnum.Commercial,
    },
  ];
}

export function generateTournamentTabsArray(tournamentId: string | undefined, lapRounds: Round[], playoffRounds: Round[]) {
  return [
    {title: "Рейтинг", url: `${AppRoute.RatingPage.url}/${tournamentId}`, hidden: lapRounds.length === 0},
    {title: "Таблица", url: `${AppRoute.ChessPage.url}/${tournamentId}`, hidden: lapRounds.length === 0},
    {title: "Сетка", url: `${AppRoute.GridsPage.url}/${tournamentId}`, hidden: playoffRounds.length === 0},
  ];
}

export function generatePlayerButtonsArray(player: Player | null) {
  return [
    {title: "Статистика игрока", url: `${AppRoute.PlayerStatPage.url}/${player?.player_id}`},
    {title: "Личные встречи", url: `${AppRoute.PlayerMeetPage.url}/${player?.player_id}`},
    {title: "История игрока", url: `${AppRoute.PlayerHistoryPage.url}/${player?.player_id}`},
  ];
}

export function generateTeamButtonsArray(team: Team | null) {
  return [
    {title: "Календарь игр", url: `${AppRoute.CommandCalendarPage.url}/${team?.team_id}`},
    {title: "Матчи", url: `${AppRoute.CommandMatchesPage.url}/${team?.team_id}`},
    {title: "Состав", url: `${AppRoute.CommandLineUpPage.url}/${team?.team_id}`},
  ];
}

export function getCroppedImg (imageSrc: string, crop: Area): Promise<string> {
  const image = new Image();
  image.src = imageSrc;

  return new Promise((resolve) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx?.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const croppedImage = URL.createObjectURL(blob);
          resolve(croppedImage);
        }
      }, "image/png");
    };
  });
};

export function getStatusConfig(status?: string, className?: string) {
  const baseClass = `clue ${className || ''}`;

  switch (status) {
    case 'draft':
      return {
        iconUrl: 'images/sprite.svg#draft',
        statusText: 'Черновик',
        containerClass: `${baseClass} clue--draft`
      };
    case 'accepted':
      return {
        iconUrl: 'images/sprite.svg#star',
        statusText: 'Принято',
        containerClass: baseClass
      };
    case 'rejected':
      return {
        iconUrl: 'images/sprite.svg#cross',
        statusText: 'Отклонено',
        containerClass: baseClass
      };
    case 'on-approval':
      return {
        iconUrl: 'images/sprite.svg#star',
        statusText: 'На рассмотрении',
        containerClass: baseClass
      };
    default:
      return {
        iconUrl: 'images/sprite.svg#unknown',
        statusText: 'Отправлено',
        containerClass: baseClass
      };
  }
}