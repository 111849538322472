import React, { useState, useCallback } from "react";
import Cropper, { Area } from "react-easy-crop";
import { getCroppedImg } from "../../../core/utils/common-helpers";

function ImageCropper({
  image,
  onCropComplete,
  onCancel,
}: {
  image: string;
  onCropComplete: (croppedImage: string | null) => void;
  onCancel: () => void;
}) {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropCompleteHandler = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async () => {
    if (croppedAreaPixels) {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      onCropComplete(croppedImage);
    }
  };

  return (
    <div className="cropper">
      <div className="cropper-container">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          cropShape="round"
          showGrid={false}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropCompleteHandler}
          onZoomChange={setZoom}
        />
      </div>
      <div className="cropper-controls">
        <input
          className="cropper-zoom-range"
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e) => setZoom(Number(e.target.value))}
        />
        <button className="save" type="button" onClick={handleCrop}>
          Сохранить
        </button>
        <button className="save" type="button" onClick={onCancel}>
          Отмена
        </button>
      </div>
    </div>
  );
}

export default ImageCropper;
