import React, {useEffect} from "react"
import AsideMedia from "../../components/simple/aside-media/aside-media"
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import Title from "../../components/ui/title/title"
import Author from "../../components/ordinary/author/author"
import Images from "../../components/ordinary/images/images"
import RedactorStyles from "../../components/ordinary/redactor-styles/redactor-styles"
import {useParams} from "react-router-dom"
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks"
import {
  getCurrentPost,
  getPostErrorStatus,
  getPostLoadingStatus,
  getPosts,
  getPostsErrorStatus,
  getPostsLoadingStatus
} from "../../core/store/posts-data/selectors"
import {AppRoute} from "../../core/constants/routes"
import {
  fetchPost,
  fetchPosts
} from "../../core/store/api-actions"
import {excludePostById, getTopItemsByPublishedDt} from "../../core/utils/array-prepare-helpers"
import Stub from "../../components/ordinary/stub/stub"

function CurrentNewsPage() {
  const {id: postId} = useParams()
  const dispatch = useAppDispatch()
  const post = useAppSelector(getCurrentPost)!
  const postError = useAppSelector(getPostErrorStatus)
  const postLoading = useAppSelector(getPostLoadingStatus)
  const hidden = post === null || postError || postLoading
  const posts = useAppSelector(getPosts)
  const postsWithoutCurrent = excludePostById(posts, postId)
  const topPosts = getTopItemsByPublishedDt(postsWithoutCurrent, 3)
  const postsError = useAppSelector(getPostsErrorStatus)
  const postsLoading = useAppSelector(getPostsLoadingStatus)
  const currentURL = window.location.href

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.PostsPage,
    {title: post?.title || "", url: currentURL},
  ]

  useEffect(() => {
    dispatch(fetchPost(Number(postId)))
    dispatch(fetchPosts())
  }, [postId])

  return (
    <div className="main__container">
      <div className="container container--850">
        <div className="gap gap--0">
          <div className={`gap gap--20 ${hidden ? "hidden" : ""}`}>
            <CrumbsList routes={crumbsRoutes}/>
            <Title title={post?.title}/>
            <Author name={post?.author} date={post?.published_dt}/>
            <div className="gap gap--25">
              <Images data={post}/>
              <RedactorStyles string={post?.text || ''}/>
            </div>
          </div>
          <Stub
            type={
              postError
                ? "error"
                : post === null
                  ? "absence"
                  : ""
            }
            error={
              postError ? `Произошла ошибка при загрузке новости с идентификатором ${postId}.` : ""
            }
          />
        </div>
      </div>
      <AsideMedia
        contents={topPosts}
        contentsError={postsError}
        contentsLoading={postsLoading}
      />
    </div>
  )
}

export default CurrentNewsPage
