import React from "react";
import { Link } from "react-router-dom";

function TranslationItem() {
  return (
    <li className="translation__item">
      <Link className="translation__link" to="#">
        <div className="translation__content">
          <img
            className="translation__img"
            src="/images/default/content.svg"
            width="960"
            height="550"
            alt="Картина трансляции"
          />
          <div className="translation__icons">
            <svg className="translation__icon" width="60" height="60">
              <use xlinkHref="images/sprite.svg#play" />
            </svg>
          </div>
        </div>
        <p className="translation__text">Moscow Open Cup 2023. ФИНАЛ</p>
      </Link>
    </li>
  );
}

export default TranslationItem;
