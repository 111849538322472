import React from "react";
import { Link } from "react-router-dom";
import { Banner } from "../../../api";

function BannerItem({ banner }: { banner: Banner }) {
  return (
    <Link
      className="banner"
      to={banner.link}
      target={banner.blank ? "_blank" : "_self"}
    >
      {/*нужно доработать*/}
      <img className="banner__img" src={banner.image} alt={banner.title} />
    </Link>
  );
}

export default BannerItem;
