import {createSlice} from '@reduxjs/toolkit';
import {fetchRegions} from '../api-actions';
import {RegionsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: RegionsState = {
  isRegionsLoading: false,
  isRegionsError: false,
  regions: [],
};

export const regionsData = createSlice({
  name: NameSpace.Regions,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.isRegionsLoading = true;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.regions = action.payload;
        state.isRegionsLoading = false;
      })
      .addCase(fetchRegions.rejected, (state) => {
        state.isRegionsError = true;
        state.isRegionsLoading = false;
      })
  }
});
