import React, {useEffect} from 'react';
import Title from "../../components/ui/title/title";
import DocumentsList from "../../components/ordinary/documents-list/documents-list";
import RedactorStyles from "../../components/ordinary/redactor-styles/redactor-styles";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import {useParams} from "react-router-dom";
import {generateTournamentButtonsArray} from "../../core/utils/common-helpers";
import ButtonsList from "../../components/ui/buttons-list/buttons-list";
import {fetchTournament} from "../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getCurrentTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus
} from "../../core/store/tournaments-data/selectors";
import {TournamentTypeEnum} from "../../api";
import Stub from "../../components/ordinary/stub/stub";
import {AppRoute} from "../../core/constants/routes";

function TournamentPage() {
  const dispatch = useAppDispatch();
  const {id: tournamentId} = useParams();
  const tournament = useAppSelector(getCurrentTournament);
  const tournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const tournamentError = useAppSelector(getTournamentErrorStatus);
  const buttonsArray = generateTournamentButtonsArray(tournament);

  const crumbsRoutes = [
    AppRoute.Main,
    tournament?.type === TournamentTypeEnum.Commercial ? AppRoute.TournamentsPage : AppRoute.LeaguePage,
    {
      title: `${tournament?.full_name}/${tournament?.type === TournamentTypeEnum.Commercial ? 'О турнире' : 'О лиге'}`,
      url: `#`
    }
  ];

  useEffect(() => {
    dispatch(fetchTournament({
      tournamentId: Number(tournamentId),
      _with: ["documents"]
    }));
  }, [dispatch, tournamentId]);

  return (
    <div className="container">
      <div className={`gap gap--20 ${tournamentLoading || tournamentError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes}/>
        <div className="gap gap--20 gap--row">
          <Title className="title--uppercase" title={tournament?.type === TournamentTypeEnum.Commercial ? 'Турнир ' : 'Лига '} titleGreen={tournament?.full_name}/>
          <ButtonsList buttonsData={buttonsArray}/>
        </div>
        <div className="grid">
          <RedactorStyles string={tournament?.description || 'Описание отсутствует'}/>
          <DocumentsList documents={tournament?.documents} className="documents--end"/>
        </div>
      </div>
      <Stub
        type={tournamentError ? "error" : tournament === null || tournament === undefined ? "absence" : ""}
        error={tournamentError ? "Произошла ошибка при загрузке информации." : ""}
      />
    </div>
  );
}

export default TournamentPage;
