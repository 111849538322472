import React from "react";
import AccordionItem from "../accordion-item/accordion-item";
import {
  HdbkFaq,
  HdbkMainProvision
} from "../../../api";

function AccordionList({items}: { items: HdbkFaq[] | HdbkMainProvision[] }) {
  return (
    <ul className="accordion">
      {items.map((item, index) => (
        <AccordionItem key={index} data={item}/>
      ))}
    </ul>
  );
}

export default AccordionList;
