import { State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { TeamTemp } from '../../../api'

export const getTeamTempsLoadingStatus = (state: State): boolean =>
  state[NameSpace.TeamsTemps].isTeamTempsLoading;
export const getTeamTempsErrorStatus = (
  state: State
): boolean => state[NameSpace.TeamsTemps].isTeamTempsError;
export const getTeamTemps = (state: State): TeamTemp[] =>
  state[NameSpace.TeamsTemps].teamTemps;