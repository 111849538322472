import React from "react";
import CalendarItem from "../calendar-item/calendar-item";
import { MatchSeries } from "../../../api";

function CalendarList({
  matchSeriesArray,
}: {
  matchSeriesArray: MatchSeries[];
}) {
  return (
    <ul className="widget">
      {matchSeriesArray.map((matchSeries) => (
        <CalendarItem matchSeries={matchSeries} key={matchSeries.series_id} />
      ))}
    </ul>
  );
}

export default CalendarList;
