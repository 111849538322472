import React, { ReactNode } from 'react'
import { useAppSelector } from '../../../core/hooks'
import { getAuthorizationStatus, getAuthorizationUserRole } from '../../../core/store/authorization-data/selectors'
import { Navigate } from 'react-router-dom'
import { AppRoute } from '../../../core/constants/routes'

const PrivateRoute = ({children, roles}:{children:ReactNode, roles?:number[]})=>{
  const isAuthenticated = useAppSelector(getAuthorizationStatus)
  const userRole = useAppSelector(getAuthorizationUserRole)
  return !isAuthenticated ? (
    <Navigate to={AppRoute.Main.url} replace />
  ) : roles && userRole !== undefined && !roles.includes(parseInt(userRole)) ? (
    <Navigate to={AppRoute.Main.url} replace />
  ) : (
    <>{children}</>
  );

}

export default PrivateRoute