import React from 'react';
import {Link} from "react-router-dom";
import {Tag} from '../../../api'

function HashtagItem({tag}: { tag: Tag }) {
  return (
    <li className="hashtag__item">
      <Link className="hashtag__link" to="#">{tag.name}</Link>
    </li>
  );
}

export default HashtagItem;
