import { createSlice } from '@reduxjs/toolkit'
import { CreateTournamentState } from '../../types/state'
import { NameSpace } from '../../constants/api-constants'
import { createTournament } from '../api-actions'

const initialState: CreateTournamentState = {
  createTournamentLoading:false,
  createTournamentStatus: undefined,
  createTournamentErrors: [],
}

export const createTournamentData = createSlice({
  name:NameSpace.CreateTournamentTemps,
  initialState,
  reducers:{},
  extraReducers: (builder)=>{
    builder
    .addCase(createTournament.pending, (state) => {
      state.createTournamentLoading = true;
    })
    .addCase(createTournament.fulfilled, (state, action) => {
      state.createTournamentLoading = false;
      state.createTournamentStatus = action.payload.success;
      state.createTournamentErrors = action.payload.errors ?? [];
    })
    .addCase(createTournament.rejected, (state, action) => {
      state.createTournamentLoading = false;
      state.createTournamentErrors = [{message: action.error.message || 'Неизвестная ошибка'}]
    })
  }
})