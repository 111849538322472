import React from "react";
import {
  EventClickArg,
  EventContentArg
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import CalendarInfo from "../../simple/calendar-info/calendar-info";
import ReactDOMServer from "react-dom/server";
import EventModal from "../event-modal/event-modal";
import {eventTypeColors} from "../../../core/constants/common";

// eslint-disable-next-line
function CalendarMain({calendarRef}: { calendarRef: any; }) {
  const events = [
    {
      title: 'game-home',
      start: '2024-06-30',
      type: 'home',
      display: 'background',
      color: eventTypeColors['home'],
      teamLogo: '/images/default/team.svg',
      teamName: '-',
      place: '-',
      regulations: '-',
      category: '-',
    },
  ];

  function showModal(arg: EventClickArg) {
    const allModals = document.getElementsByClassName('event');
    for (let i = 0; i < allModals.length; i++) {
      allModals[i].classList.remove('event--open');
    }
    const currentModal = arg.el.getElementsByClassName('event')[0];
    if (currentModal) {
      currentModal.classList.add('event--open');
      const dayFrame = arg.el.closest('.fc-daygrid-day-frame');
      if (dayFrame && dayFrame instanceof HTMLElement) {
        dayFrame.style.zIndex = '9';
      }
    }
  }

  document.addEventListener('click', (event: MouseEvent) => {
    const allModals = document.getElementsByClassName('event') as HTMLCollectionOf<HTMLElement>;
    const allModalsArray = Array.from(allModals);
    if (event.target && !(event.target as HTMLElement).closest('.fc-media-screen')) {
      allModalsArray.forEach(modal => {
        modal.classList.remove('event--open');
      });
    }
  });

  function showEventContent(arg: EventContentArg) {
    const arrayOfDomNodes = [];
    const eventContainer = document.createElement('div');
    eventContainer.classList.add("fc-event-container");
    const imgEventWrap = document.createElement('div');
    imgEventWrap.classList.add("fc-event-img");
    const modalEventWrap = document.createElement('div');
    modalEventWrap.classList.add("event-modal");
    if (arg.event.extendedProps.teamLogo) {
      imgEventWrap.innerHTML = '<img src="' + arg.event.extendedProps.teamLogo + '" width="35" height="35" alt="' + arg.event._def.title + '">';
    }
    modalEventWrap.innerHTML = ReactDOMServer.renderToString(<EventModal event={arg.event}/>);
    eventContainer.appendChild(imgEventWrap);
    eventContainer.appendChild(modalEventWrap);
    arrayOfDomNodes.push(eventContainer);
    return {domNodes: arrayOfDomNodes};
  }

  return (
    <div className="calendar__main">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        events={events}
        headerToolbar={false}
        locale={'ru'}
        height={'auto'}
        contentHeight={'auto'}
        eventContent={(arg) => showEventContent(arg)}
        firstDay={1}
        eventClick={(arg) => showModal(arg)}
      />
      <CalendarInfo/>
    </div>
  );
}

export default CalendarMain;
