import React, { useState } from "react";
import ImageCropper from '../../smart/image-cropper/image-cropper'

function Avatar({
  profile,
  onFileChange,
}: {
  profile: string;
  onFileChange: (file: File|null) => void;
}) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [isCropping, setIsCropping] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsCropping(true);
    }
  };

  const handleRemovePhoto = () => {
    setSelectedFile(null);
    setCroppedImage(null);
    onFileChange(null);
  };

  const handleCropComplete = (croppedImg: string | null) => {
    setIsCropping(false);
    if (croppedImg) {
      setCroppedImage(croppedImg);
      onFileChange(new File([croppedImg], "cropped-image.png", { type: "image/png" }));
    }
  };

  return (
    <div className="avatar">
      {croppedImage ? (
        <img
          className="avatar__img"
          src={croppedImage}
          width="160"
          height="160"
          alt="Фото команды"
        />
      ) : (
        <img className="avatar__img"
        src={`images/default/${profile}`}
        alt="Загрузить фото"
        width="160"
        height="160" />
      )}
      <div className="avatar__texts">
        <p className="avatar__text">Мин. размер фото 400x400</p>
        <label htmlFor="avatar-upload" className="avatar__title">
        {selectedFile ? "Изменить изображение" : "Загрузить изображение"}
        </label>
        <input
          id="avatar-upload"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {selectedFile && (
          <a className="avatar__title" onClick={handleRemovePhoto}>
            Удалить изображение
          </a>
        )}
      </div>
      {isCropping && selectedFile && (
        <div className="cropper-overlay">
        <ImageCropper
          image={URL.createObjectURL(selectedFile)}
          onCropComplete={handleCropComplete}
          onCancel={() => setIsCropping(false)}
        />
      </div>
      )}
    </div>
  );
}

export default Avatar;
