import React, { useEffect, useMemo, useState } from "react";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import ContentList from "../../components/ordinary/content-list/content-list";
import Pagination from "../../components/ordinary/pagination/pagination";
import {
  getVideos,
  getVideosErrorStatus,
  getVideosFilterSeasonStatus,
  getVideosLoadingStatus,
} from "../../core/store/videos-data/selectors";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  filterMediaBySeason,
  sortItemsByPublicationDate,
} from "../../core/utils/array-prepare-helpers";
import { AppRoute } from "../../core/constants/routes";
import { CONTENT_PER_PAGE } from "../../core/constants/common";
import { fetchVideos } from "../../core/store/api-actions";
import FilterPagesList from "../../components/simple/filter-pages-list/filter-pages-list";
import { getSeasonsLoadingStatus } from "../../core/store/seasons-data/selectors";
import Stub from "../../components/ordinary/stub/stub";

function VideosPage() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.VideosPage];
  const videos = useAppSelector(getVideos);
  const videosErrorStatus = useAppSelector(getVideosErrorStatus);
  const videosLoadingStatus = useAppSelector(getVideosLoadingStatus);
  const filterSeasonStatus = useAppSelector(getVideosFilterSeasonStatus);
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus);

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  const createProcessedVideos = useMemo(() => {
    let processedVideos = videos;
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      processedVideos = filterMediaBySeason(
        processedVideos,
        filterSeasonStatus.label
      );
    }
    return sortItemsByPublicationDate(processedVideos);
  }, [videos, filterSeasonStatus]);

  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = CONTENT_PER_PAGE;
  const pageCount = Math.ceil(
    (createProcessedVideos?.length || 0) / videosPerPage
  );
  const indexOfLastVideo = (currentPage + 1) * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos
    ? createProcessedVideos.slice(indexOfFirstVideo, indexOfLastVideo)
    : [];

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const videosPageLoading = videosLoadingStatus || seasonsLoading;

  return (
    <div className="container-y">
      <div className={`gap gap--20 ${videosPageLoading ? "hidden" : ""}`}>
        <div className="container-x">
          <div className="gap gap--20">
            <CrumbsList routes={crumbsRoutes} />
            <div className="gap gap--row">
              <Title title="Видео" />
              <FilterPagesList />
            </div>
          </div>
        </div>
        <Stub
          type={
            videosErrorStatus
              ? "error"
              : videos.length > 0 && createProcessedVideos.length === 0
              ? "searchError"
              : videos.length === 0
              ? "absence"
              : ""
          }
          error={
            videosErrorStatus ? "Произошла ошибка при загрузке контента." : ""
          }
        />
        <div className="gap gap--40">
          <ContentList items={currentVideos} />
          {pageCount > 1 && (
            <Pagination
              pageCount={pageCount}
              forcePage={currentPage}
              onPageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default VideosPage;
