import React from "react";
import {
  Link,
  matchPath,
  useLocation
} from "react-router-dom";
import {LinkType} from "../../../core/types/common";

function ButtonsItem({buttonData, parentPath}: {
  buttonData: LinkType,
  parentPath?: string
}) {
  const {pathname} = useLocation();
  const isActive =  !!matchPath({ path: buttonData.url }, pathname,);
  const parentPathActive = parentPath ? buttonData.url.includes(parentPath) : false;
  const className = `buttons__item ${isActive || parentPathActive ? 'buttons__item--active' : buttonData.hidden ? 'hidden' : ''}`

  return (
    <li className={className}>
      <Link className="buttons__link" to={buttonData.url}>{buttonData.title}</Link>
    </li>
  );
}

export default ButtonsItem;
