import React, {useRef, useState} from "react";
import "./../../../assets/css/custom-calendar.css";
import CalendarHeader from "../../ordinary/calendar-header/calendar-header";
import CalendarMain from "../../ordinary/calendar-main/calendar-main";

function Calendar() {
  // eslint-disable-next-line
  const calendarRef = useRef<any>(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleChangeDate = (type: string, date?: Date) => {
    const calendarApi = calendarRef.current.getApi();
    if (type === 'changeMonth' && date) {
      calendarApi.gotoDate(date);
    } else if (type === 'prevYear') {
      calendarApi.prevYear();
    } else if (type === 'nextYear') {
      calendarApi.nextYear();
    } else if (type === 'prevMonth') {
      calendarApi.prev();
    } else if (type === 'nextMonth') {
      calendarApi.next();
    }
    setCurrentDate(calendarApi.currentData.currentDate);
  }

  return (
    <div className="calendar">
      <CalendarHeader currentDate={currentDate} onChangeDate={handleChangeDate}/>
      <CalendarMain calendarRef={calendarRef}/>
    </div>
  );
}

export default Calendar;