import React from 'react';
import RedactorStyles from '../redactor-styles/redactor-styles'

function Reason({comment}:{comment:string}) {
  return (
    <div className="reason">
      <p className="reason__title">Причина отклонения</p>
      <RedactorStyles string={comment}/>
    </div>
  );
}

export default Reason;
