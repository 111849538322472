import React from "react";
import Proffer from "../proffer/proffer";
import FormSelect from "../../smart/select/select";
import { genderOptions } from "../../../core/constants/common";
import { SelectTypes } from "../../../core/constants/selects";
import { TeamTempPlayerRequest } from '../../../api'

function CommandsCompoundForm({
  formik,
  player,
  index,
  onRemove,
  onAdd,
  disableRemove,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  player: TeamTempPlayerRequest;
  index: number;
  onRemove: () => void;
  onAdd: () => void;
  disableRemove:boolean;
}) {

  const hasErrors = index !== undefined && formik.errors.players?.[index];
  const hasPlayer = player !== undefined;


  return (
    <div className="gap gap--40">
      <div className="fields">
        <div className="fields__item">
          <label className="form">
            <p className="form__text">
              Имя
              <span
                className={`form__help ${hasErrors?.first_name ? "" : "hidden"}`}
              >
                {hasErrors?.first_name}
              </span>
            </p>
            <input
              className={`form__input ${hasErrors?.first_name ? "form__input--error" : ""}`}
              type="text"
              placeholder="Введите имя"
              name="first_name"
              value={hasPlayer ? player.first_name : ""}
              onChange={(e) =>
                formik.setFieldValue(
                  `players[${index}].first_name`,
                  e.target.value,
                )
              }
            />
          </label>
          <label className="form">
            <p className="form__text">
              Фамилия
              <span
                className={`form__help ${hasErrors?.last_name ? "" : "hidden"}`}
              >
                {hasErrors?.last_name}
              </span>
            </p>
            <input
              className={`form__input ${hasErrors?.last_name ? "form__input--error" : ""}`}
              type="text"
              placeholder="Введите фамилию"
              name={`players[${index}].last_name`}
              value={hasPlayer ? player.last_name : ""}
              onChange={(e) =>
                formik.setFieldValue(
                  `players[${index}].last_name`,
                  e.target.value,
                )
              }
            />
          </label>
        </div>
        <div className="fields__item fields__item--3">
          <label className="form">
            <p className="form__text">
              Дата рождения
              <span
                className={`form__help ${hasErrors?.birthday ? "" : "hidden"}`}
              >
                {hasErrors?.birthday}
              </span>
            </p>
            <input
              className={`form__input ${hasErrors?.birthday ? "form__input--error" : ""}`}
              type="date"
              placeholder="Введите дату"
              name={`players[${index}].birthday`}
              value={hasPlayer ? player.birthday : ""}
              onChange={(e) =>
                formik.setFieldValue(
                  `players[${index}].birthday`,
                  e.target.value,
                )
              }
            />
          </label>
          <label className="form">
            <p className="form__text">Пол <span
              className={`form__help ${hasErrors?.gender ? "" : "hidden"}`}
            >
              {hasErrors?.gender}
            </span></p>
            <FormSelect
              options={genderOptions}
              type={SelectTypes.Gender}
              formik={formik}
              value={hasPlayer ? formik.values.players[index].gender : ""}
              name={`players[${index}].gender`}
            />
          </label>
          <label className="form">
            <p className="form__text">
              NTRP
              <span className={`form__help ${hasErrors?.ntrp ? "" : "hidden"}`}>
                {hasErrors?.ntrp}
              </span>
            </p>
            <input
              className={`form__input ${hasErrors?.ntrp ? "form__input--error" : ""}`}
              type="text"
              placeholder="Введите значение"
              name={`players[${index}].ntrp`}
              value={hasPlayer ? player.ntrp : ""}
              onChange={(e) =>
                formik.setFieldValue(`players[${index}].ntrp`, e.target.value)
              }
            />
          </label>
        </div>
      </div>
      <Proffer onRemove={onRemove} onAdd={onAdd} disableRemove={disableRemove} />
    </div>
  );
}

export default CommandsCompoundForm;
