import { createSlice } from '@reduxjs/toolkit'
import { TournamentsTempsState } from '../../types/state'
import { NameSpace } from '../../constants/api-constants'
import { fetchTournamentsTempsMine } from '../api-actions'

const initialState:TournamentsTempsState = {
  isTournamentsTempsLoading: false,
  isTournamentsTempsError: false,
  tournamentsTemps:[] ,
}

export const tournamentsTempsData = createSlice({
  name:NameSpace.TournamentsTemps,
  initialState,
  reducers:{

  },
  extraReducers(builder) {
    builder
      .addCase(fetchTournamentsTempsMine.pending, (state) => {
        state.isTournamentsTempsLoading = true;
      })
      .addCase(fetchTournamentsTempsMine.fulfilled, (state, action) => {
        state.tournamentsTemps = action.payload;
        state.isTournamentsTempsLoading = false;
      })
      .addCase(fetchTournamentsTempsMine.rejected, (state) => {
        state.isTournamentsTempsError = true;
        state.isTournamentsTempsLoading = false;
      });
    },
})