import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import Title from "../../components/ui/title/title"
import ButtonsList from "../../components/ui/buttons-list/buttons-list"
import TabsList from "../../components/ui/tabs-list/tabs-list"
import Rating from "../../components/ordinary/raiting/raiting"
import {AppRoute} from "../../core/constants/routes"
import {
  fetchRounds,
  fetchTournament
} from "../../core/store/api-actions"
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks"
import {
  getCurrentTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus
} from "../../core/store/tournaments-data/selectors"
import {
  generateTournamentButtonsArray,
  generateTournamentTabsArray
} from "../../core/utils/common-helpers"
import {
  getRounds,
  getRoundsErrorStatus,
  getRoundsLoadingStatus
} from "../../core/store/rounds-data/selectors"
import {filterRoundsByTypes} from "../../core/utils/array-prepare-helpers"
import {
  ApiTournamentsIdRoundsGetTypeEnum,
  TournamentTypeEnum
} from "../../api"
import Stub from "../../components/ordinary/stub/stub"

function RatingPage() {
  const dispatch = useAppDispatch()
  const {id: tournamentId} = useParams()
  const tournament = useAppSelector(getCurrentTournament)
  const tournamentLoading = useAppSelector(getTournamentLoadingStatus)
  const tournamentError = useAppSelector(getTournamentErrorStatus)
  const rounds = useAppSelector(getRounds)
  const roundsLoading = useAppSelector(getRoundsLoadingStatus)
  const roundsError = useAppSelector(getRoundsErrorStatus)
  const lapRounds = filterRoundsByTypes(rounds, [ApiTournamentsIdRoundsGetTypeEnum.RoundRobinOne, ApiTournamentsIdRoundsGetTypeEnum.RoundRobinTwo])
  const playoffRounds = filterRoundsByTypes(rounds, [ApiTournamentsIdRoundsGetTypeEnum.PlayoffOlympic])
  const ratingPageLoading = tournamentLoading || roundsLoading
  const ratingPageError = tournamentError || roundsError
  const buttonsArray = generateTournamentButtonsArray(tournament)
  const tabsArray = generateTournamentTabsArray(tournamentId, lapRounds, playoffRounds)

  const crumbsRoutes = [
    AppRoute.Main,
    tournament?.type === TournamentTypeEnum.Commercial ? AppRoute.TournamentsPage : AppRoute.LeaguePage,
    {
      title: `${tournament?.full_name}/${AppRoute.RatingPage.title}`,
      url: `#`
    }
  ]

  useEffect(() => {
    dispatch(fetchTournament({
      tournamentId: Number(tournamentId),
      _with: ["zone"]
    }))
    dispatch(fetchRounds({
      tournamentId: Number(tournamentId),
      _with: ["table"]
    }))
  }, [dispatch, tournamentId])

  return (
    <div className="container">
      <div className={`gap gap--20 ${ratingPageLoading || ratingPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes}/>
        <div className="gap gap--20 gap--row">
          <Title className="title--uppercase" title="зона " titleGreen={tournament?.zone?.title}/>
          <ButtonsList buttonsData={buttonsArray} parentPath="/tables"/>
        </div>
        <div className="gap gap--20">
          <TabsList tabsData={tabsArray}/>
          {lapRounds.map((round) => (
            <div className="gap gap--20" key={round.round_id}>
              <Title title={round.name}/>
              <Rating roundTablesInner={round.table}/>
            </div>
          ))}
        </div>
      </div>
      <Stub
        type={ratingPageError ? "error" : lapRounds.length === 0 ? "absence" : ""}
        error={ratingPageError ? "Произошла ошибка при загрузке страницы рейтинга." : ""}
      />
    </div>
  )
}

export default RatingPage
