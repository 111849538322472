import React from 'react'
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import CommandsItem from "../../components/ordinary/commands-item/commands-item"
import ButtonsList from "../../components/ui/buttons-list/buttons-list"
import {useAppSelector} from "../../core/hooks"
import {
  getTeam,
  getTeamErrorStatus,
  getTeamLoadingStatus
} from "../../core/store/teams-data/selectors"
import {generateTeamButtonsArray} from "../../core/utils/common-helpers"
import {AppRoute} from "../../core/constants/routes"
import Stub from "../../components/ordinary/stub/stub"
import {
  getTeamPlayersErrorStatus,
  getTeamPlayersLoadingStatus
} from "../../core/store/team-players-data/selectors"
import CommandMatches from "../../components/ordinary/command-matches/command-matches"
import {
  getMatchSeriesArrayErrorStatus,
  getMatchSeriesArrayLoadingStatus
} from "../../core/store/match-series-data/selectors"

function CommandMatchesPage() {
  const team = useAppSelector(getTeam)
  const teamLoading = useAppSelector(getTeamLoadingStatus)
  const teamError = useAppSelector(getTeamErrorStatus)
  const teamPlayersLoading = useAppSelector(getTeamPlayersLoadingStatus)
  const teamPlayersError = useAppSelector(getTeamPlayersErrorStatus)
  const matchSeriesArrayLoading = useAppSelector(getMatchSeriesArrayLoadingStatus)
  const matchSeriesArrayError = useAppSelector(getMatchSeriesArrayErrorStatus)
  const teamMatchesPageLoading = teamLoading || teamPlayersLoading || matchSeriesArrayLoading
  const teamMatchesPageError = teamError || teamPlayersError || matchSeriesArrayError
  const buttonsArray = generateTeamButtonsArray(team)

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.CommandsRatingPage,
    {
      title: team?.full_name || '',
      url: '#'
    }
  ]

  return (
    <div className="container">
      <div className={`gap gap--40 ${teamMatchesPageLoading || teamMatchesPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes}/>
        <div className="grid-profile">
          <CommandsItem/>
          <div className="gap gap--0 gap--start">
            <ButtonsList className="buttons--border" buttonsData={buttonsArray}/>
            <div className="blocks">
              <svg className="blocks__background" width="1400" height="600">
                <use xlinkHref="images/sprite.svg#background-logo"/>
              </svg>
              <CommandMatches/>
            </div>
          </div>
        </div>
      </div>
      <Stub
        type={teamMatchesPageError ? "error" : ""}
        error={teamMatchesPageError ? "Произошла ошибка при загрузке страницы матчи команды." : ""}
      />
    </div>
  )
}

export default CommandMatchesPage
