import React, { useEffect } from "react";
import AsideBanners from "../../components/simple/aside-banners/aside-banners";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import AccordionList from "../../components/ordinary/accordion-list/accordionList";
import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getProvisions,
  getProvisionsLoadingStatus,
} from "../../core/store/basic-provisions-data/selectors";
import { sortItemsByRank } from "../../core/utils/array-prepare-helpers";
import { fetchProvisions } from "../../core/store/api-actions";
import { BannerPositionEnum } from "../../api";

function MainProvisionsPage() {
  const provisions = useAppSelector(getProvisions);
  const sortedProvisions = sortItemsByRank(provisions);
  const provisionsLoading = useAppSelector(getProvisionsLoadingStatus);
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.MainProvisionsPage];

  useEffect(() => {
    dispatch(fetchProvisions());
  }, [dispatch]);

  return (
    <div className="main__container">
      <div className="container-left">
        <div className={`gap gap--20 ${provisionsLoading ? "hidden" : ""}`}>
          <CrumbsList routes={crumbsRoutes} />
          <Title
            className="title--uppercase"
            title="Основные положения серии"
          />
          <AccordionList items={sortedProvisions} />
        </div>
      </div>
      <AsideBanners bannerPosition={BannerPositionEnum.SidebarFaq} />
    </div>
  );
}

export default MainProvisionsPage;
