import React, { useEffect } from "react";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import { AppRoute } from "../../core/constants/routes";
import ButtonsList from "../../components/ui/buttons-list/buttons-list";
import PaymentForm from "../../components/ordinary/payment-form/payment-form";
import { useAppDispatch, useAppSelector, useFilteredButtons } from '../../core/hooks'
import { getAuthorizationUserId, getAuthorizationUserRole } from '../../core/store/authorization-data/selectors'
import { fetchTeamsMine } from '../../core/store/api-actions'
import { getTeams } from '../../core/store/teams-data/selectors'

function PaymentPage() {
  const dispatch = useAppDispatch();
  const filteredButtons = useFilteredButtons();
  const userId = useAppSelector(getAuthorizationUserId);
  const userRole = useAppSelector(getAuthorizationUserRole)
  const teamsMine = useAppSelector(getTeams)
  const crumbsRoutes = [AppRoute.Main, AppRoute.PaymentPage];

  useEffect(() => {
    if (userId) {
      dispatch(fetchTeamsMine(Number(userId)));
    }
  }, [dispatch, userId]);


    return (
    <div className="container">
      <div className="gap gap--20">
        <div className="gap gap--20">
          <CrumbsList routes={crumbsRoutes} />
          <div className="gap gap--row">
            <Title title="Оплата" className="title--uppercase"/>
            <ButtonsList buttonsData={filteredButtons}/>
          </div>
        </div>
        <div className="gap gap--15">
          <Title title="Выберите продукт для оплаты"/>
          <form className="background-form">
            <PaymentForm role={Number(userRole) ?? 0} team={teamsMine[teamsMine.length-1]}/>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PaymentPage;
