import { CalendarState } from "../../types/state";
import { filterStage, filterTournamentsType } from "../../constants/filters";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NameSpace } from "../../constants/api-constants";
import { FilterOption } from "../../types/common";

const initialState: CalendarState = {
  tournamentTypeFilter: filterTournamentsType[0],
  tournamentStageFilter: filterStage[0],
};

export const calendarData = createSlice({
  name: NameSpace.Calendar,
  initialState,
  reducers: {
    changeTournamentTypeFilter: (
      state,
      action: PayloadAction<{ tournamentTypeFilter: FilterOption }>,
    ) => {
      const { tournamentTypeFilter } = action.payload;
      state.tournamentTypeFilter = tournamentTypeFilter;
    },
    changeTournamentStageFilter: (
      state,
      action: PayloadAction<{ tournamentStageFilter: FilterOption }>,
    ) => {
      const { tournamentStageFilter } = action.payload;
      state.tournamentStageFilter = tournamentStageFilter;
    },
  },
});

export const { changeTournamentTypeFilter, changeTournamentStageFilter } =
  calendarData.actions;
