import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { State, AppDispatch } from "../types/state";
import { getAuthorizationUserId } from "../store/authorization-data/selectors";
import { getUser } from "../store/users-data/selectors";
import { useEffect, useState } from "react";
import { fetchUser } from "../store/api-actions";
import { registrationButtonsArray } from "../constants/navigations";
import { NavigationItem } from "../types/navigation";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export const useFilteredButtons = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getAuthorizationUserId);
  const user = useAppSelector(getUser);
  const [filteredButtons, setFilteredButtons] = useState<NavigationItem[]>([]);

  useEffect(() => {
    if (userId !== undefined && user) {
      dispatch(fetchUser(Number(userId)));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (user?.role !== undefined) {
      const buttons = registrationButtonsArray.filter((button) => {
        if (user.role === 4096) {
          return true;
        } else if (user.role === 34) {
          return (
            button.title === "Регистрация команды" || button.title === "Оплата"
          );
        } else if (user.role === 66) {
          return (
            button.title === "Регистрация турнира" || button.title === "Оплата"
          );
        }
        return false;
      });
      setFilteredButtons(buttons);
    }
  }, [user]);

  return filteredButtons;
};
