import React, {
  useEffect,
  useState
} from "react"
import RatingRow from "../rating-row/rating-row"
import Sorting from "../sorting/sorting"
import {RoundTableInner} from "../../../api"
import {sortRoundTablesInner} from "../../../core/utils/array-prepare-helpers"
import {tournamentRatingSorters} from "../../../core/constants/sorters"
import {ItemsForSort} from "../../../core/constants/filters"
import {useAppSelector} from "../../../core/hooks"
import {getRoundTablesInnerSorterStatus} from "../../../core/store/round-tables-inner-data/selectors"

function RatingTable({ roundTablesInner }: { roundTablesInner?: RoundTableInner[] }) {
  const [sortedRoundTablesInner, setSortedRoundTablesInner] = useState<RoundTableInner[]>(roundTablesInner || [])
  const sorterStatus = useAppSelector(getRoundTablesInnerSorterStatus)

  useEffect(() => {
    setSortedRoundTablesInner(sortRoundTablesInner([...roundTablesInner || []], sorterStatus));
  }, [sorterStatus])

  useEffect(() => {
    setSortedRoundTablesInner([...roundTablesInner || []])
  }, [roundTablesInner])

  return (
    <div className="table-scroll">
      <div className="table-border">
        <table className="table">
          <thead className="table__thead">
          <tr className="table__row">
            <th className="table__th table__number">
              <p>№</p>
            </th>
            {tournamentRatingSorters.map((tournamentRatingSorter) => (
              <th className="table__th" key={tournamentRatingSorter}>
                <Sorting
                  title={tournamentRatingSorter}
                  items={ItemsForSort.RoundTablesInner}
                />
              </th>
            ))}
          </tr>
          </thead>
          <tbody className="table__tbody">
          {sortedRoundTablesInner?.map((roundTableInner, index) => (
            <RatingRow index={index} roundTableInner={roundTableInner} key={roundTableInner.team?.team_id} />
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RatingTable
