import React from "react";
import {RoundChess} from "../../../api";
import ChessRow from "../chess-row/chess-row";

const ChessScores = React.forwardRef<HTMLTableElement, { roundChess?: RoundChess }>(({roundChess}, ref) => {
  return (
    <table ref={ref} className="table table-scores">
      <thead className="table__thead">
      <tr className="table__row">
        {roundChess?.tableRows?.map((_, index) => (
          <th className="table__th table__127" key={index}>{index + 1}</th>
        ))}
      </tr>
      </thead>
      <tbody className="table__tbody">
      {roundChess?.chess ? Object.values(roundChess.chess).map((seriesGroup, rowIndex) => (
        <ChessRow seriesGroup={seriesGroup} key={rowIndex}/>
      )): ''}
      </tbody>
    </table>
  );
});

ChessScores.displayName = 'ChessScores';
export default ChessScores;
