import React from "react"
import {Link} from "react-router-dom"
import {
  RatingPlayer,
  StatsPlayer
} from "../../../api"
import {AppRoute} from "../../../core/constants/routes"

function RatingPlayersRow({playerRating, playerStats, index}: {
  playerRating: RatingPlayer
  playerStats?: StatsPlayer
  index: number
}) {
  return (
    <tr className="table__row">
      <td className="table__cell table__number">{index + 1}</td>
      <td className="table__cell">
        <Link
          className="table__link table__start"
          to={`${AppRoute.PlayerStatPage.url}/${playerRating.player_id}`}
        >
          <img
            className="table__img"
            src={
              playerRating.player?.avatar.includes("photo_placeholder_100x100.svg")
                ? "/images/default/player.svg"
                : playerRating.player?.avatar
            }
            width="25"
            height="25"
            alt={`${playerRating.player?.first_name} ${playerRating.player?.last_name}`}
          />
          <p className="table__name">
            {playerRating.player?.first_name} {playerRating.player?.last_name}
          </p>
        </Link>
      </td>
      <td className="table__cell">{playerStats?.efficiency || 0}</td>
      <td className="table__cell">{playerStats?.wins || 0}</td>
      <td className="table__cell">{playerStats?.losses || 0}</td>
    </tr>
  )
}

export default RatingPlayersRow
