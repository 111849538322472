import React from "react";
import {Link} from "react-router-dom";
import {AlbumPhoto} from "../../../api";

function AlbumItem({photo}: { photo: AlbumPhoto }) {
  return (
    <li className="album__item">
      <Link
        className="album__link"
        to={photo._links?.image?.href || ""}
        data-fancybox="gallery"
      >
        <img
          className="album__img"
          src={photo._links?.image?.href || "/images/default/content.svg"}
          width="305"
          height="180"
          alt={photo.description}
        />
      </Link>
    </li>
  );
}

export default AlbumItem;
