import React, {
  useEffect,
  useState
} from "react"
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks"
import {fetchDesireRoles} from "../../../core/store/api-actions"
import {getDesireRoles} from "../../../core/store/desire-roles-data/selectors"
import {SelectTypes} from "../../../core/constants/selects"
import FormSelect from "../../smart/select/select"

function ModalRegistrationList({formik, setErrors}: {
  // eslint-disable-next-line
  formik: any,
  setErrors:  React.Dispatch<React.SetStateAction<string[]>>
}) {
  const dispatch = useAppDispatch()
  const [firstShowPassword, setFirstShowPassword] = useState(false)
  const [secondShowPassword, setSecondShowPassword] = useState(false)
  const desireRoles = useAppSelector(getDesireRoles)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    setErrors([])
  }

  useEffect(() => {
    dispatch(fetchDesireRoles())
  }, [dispatch])

  return (
    <div className="modal__list">
      <label className="form">
        <p className="form__text">Имя<span className={`form__help ${formik.errors.firstName ? '' : 'hidden'}`}>{formik.errors.firstName}</span></p>
        <input
          className={`form__input ${formik.errors.firstName ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите ваше имя"
          name="firstName"
          value={formik.values.firstName}
          onChange={handleInputChange}
        />
      </label>
      <label className="form">
        <p className="form__text">Фамилия<span className={`form__help ${formik.errors.lastName ? '' : 'hidden'}`}>{formik.errors.lastName}</span></p>
        <input
          className={`form__input ${formik.errors.lastName ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите вашу фамилию"
          name="lastName"
          value={formik.values.lastName}
          onChange={handleInputChange}
        />
      </label>
      <label className="form">
        <p className="form__text">E-mail<span className={`form__help ${formik.errors.email ? '' : 'hidden'}`}>{formik.errors.email}</span></p>
        <input
          className={`form__input ${formik.errors.email ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите почту"
          name="email"
          value={formik.values.email}
          onChange={handleInputChange}
        />
      </label>
      <label className="form">
        <p className="form__text">Телефон<span className={`form__help ${formik.errors.phone ? '' : 'hidden'}`}>{formik.errors.phone}</span></p>
        <input
          className={`form__input ${formik.errors.phone ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите номер"
          name="phone"
          value={formik.values.phone}
          onChange={handleInputChange}
        />
      </label>
      <label className="form">
        <p className="form__text">Роль</p>
        <FormSelect
          options={desireRoles}
          type={SelectTypes.DesireRoles}
          formik={formik}
          value={formik.values.desireRole}
          name="desireRole"
        />
      </label>
      <label className="form form--password">
        <p className="form__text">Пароль<span className={`form__help ${formik.errors.password ? '' : 'hidden'}`}>{formik.errors.password}</span></p>
        <input
          className={`form__input ${formik.errors.password ? 'form__input--error' : ''}`}
          type={firstShowPassword ? "text" : "password"}
          placeholder="Придумайте пароль"
          name="password"
          value={formik.values.password}
          onChange={handleInputChange}
        />
        <button className={`form__control ${firstShowPassword ? "form__control--view" : ""}`} type="button" onClick={() => setFirstShowPassword(!firstShowPassword)}></button>
      </label>
      <label className="form form--password">
        <p className="form__text">Подтверждение пароля<span className={`form__help ${formik.errors.passwordRepeat ? '' : 'hidden'}`}>{formik.errors.passwordRepeat}</span></p>
        <input
          className={`form__input ${formik.errors.passwordRepeat ? 'form__input--error' : ''}`}
          type={secondShowPassword ? "text" : "password"}
          placeholder="Повторите придуманный пароль"
          name="passwordRepeat"
          value={formik.values.passwordRepeat}
          onChange={handleInputChange}
        />
        <button className={`form__control ${secondShowPassword ? "form__control--view" : ""}`} type="button" onClick={() => setSecondShowPassword(!secondShowPassword)}></button>
      </label>
    </div>
  )
}

export default ModalRegistrationList
