import {
  Link,
  useLocation
} from "react-router-dom";
import React from "react";
import {AppRoute} from "../../../core/constants/routes";
import {
  Album,
  Post,
  Video
} from "../../../api";
import {roundDateFormat} from "../../../core/utils/date-time-helpers";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function ContentItem({data}: { data: Video | Album | Post }) {
  const location = useLocation();
  const isAlbumPage = location.pathname === AppRoute.AlbumsPage.url;
  const isVideosPage = location.pathname === AppRoute.VideosPage.url;

  const publishedDate: string | undefined = data.published_dt;
  const formattedDay: string | undefined = publishedDate
    ? roundDateFormat(new Date(publishedDate), "dd")
    : "";
  const formattedMonth: string | undefined = publishedDate
    ? roundDateFormat(new Date(publishedDate), "MMM")
    : "";

  let src;
  let info;
  let count;
  let contentId;

  if (data._links) {
    if ("preview" in data._links) {
      src = data._links?.preview?.href;
    } else if ("covers" in data._links) {
      src = data._links?.covers?.image?.href;
    } else if ("image1120x633" in data._links) {
      src = data._links?.image1120x633?.href;
      if (
        data._links?.image1120x633?.href?.match(/image_placeholder/)
      ) {
        src = "/images/default/content.svg";
      }
    } else {
      src = "/images/default/content.svg";
    }
  }

  if ("description" in data) {
    info = data.description;
  } else if ("lead" in data) {
    info = data.lead;
  }

  if ("photos" in data) {
    count = data.photos?.length;
  }

  if ("album_id" in data) {
    contentId = data.album_id;
  } else if ("video_id" in data) {
    contentId = data.video_id;
  } else if ("post_id" in data) {
    contentId = data.post_id;
  }

  const contentPath = isAlbumPage
    ? `${AppRoute.AlbumsPage.url}/${contentId}`
    : isVideosPage
      ? `${AppRoute.VideosPage.url}/${contentId}`
      : `${AppRoute.PostsPage.url}/${contentId}`;

  return (
    <li className="content__item">
      <Link className="content__link" to={contentPath}>
        <div className="content__block">
          <div className={`content__icons ${!isAlbumPage ? "hidden" : ""}`}>
            <svg className="content__icon" width="30" height="30">
              <use xlinkHref="images/sprite.svg#camera"/>
            </svg>
            <p className="content__number">{count}</p>
          </div>
          <div
            className={`content__icons content__icons--video ${
              !isVideosPage ? "hidden" : ""
            }`}
          >
            <svg className="content__icon" width="30" height="30">
              <use xlinkHref="images/sprite.svg#play"/>
            </svg>
          </div>
          <img
            className="content__img"
            src={src}
            width="480"
            height="275"
            alt={data.title}
          />
          <div className="content__count">
            <p className="content__date">{formattedDay}</p>
            <p className="content__date">{formattedMonth}</p>
          </div>
        </div>
        <div className="content__text">
          <HtmlRenderer htmlString={info || "Описание отсутствует"}/>
        </div>
      </Link>
    </li>
  );
}

export default ContentItem;
