import React from "react";
import FilterLeagueItem from "../../smart/filter-league-item/filter-league-item";
import Stub from "../stub/stub";
import {Tournament} from "../../../api";

function FilterLeagueList({leagues, leaguesErrorStatus, filteredLeagues}: {
  leagues: Tournament[];
  leaguesErrorStatus: boolean;
  filteredLeagues: Tournament[];
}) {
  return (
    <div className="gap gap--0">
      <ul className="filter filter--tournaments">
        <FilterLeagueItem/>
      </ul>
      <Stub
        type={
          leaguesErrorStatus
            ? "error"
            : leagues.length > 0 && filteredLeagues.length === 0
              ? "searchError"
              : leagues.length === 0
                ? "absence"
                : ""
        }
        error={
          leaguesErrorStatus ? "Произошла ошибка при загрузке турниров." : ""
        }
      />
    </div>
  );
}

export default FilterLeagueList;
