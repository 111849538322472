/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import CompoundCommandsItem from "../compound-commands-item/compound-commands-item";
import { FieldArray } from "formik";
import { TeamTempPlayer, TeamTempPlayerRequest } from '../../../api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CompoundCommandsList({ players, formik }: { players: (TeamTempPlayerRequest | TeamTempPlayer)[], formik:any }) {

  const [numForms, setNumForms] = useState(players.length);

  const handleRemove = (arrayHelpers: any, index: number) => {
    if (numForms > 1) {
      arrayHelpers.remove(index);
      setNumForms(numForms - 1);
    }
  };

  const handleAdd = (arrayHelpers: any) => {
    arrayHelpers.push({
      first_name: "",
      last_name: "",
      birthday: "",
      gender: "",
      photo: "",
      ntrp: "",
    });
    setNumForms(numForms + 1);
  };

  return (
    <FieldArray
      name="players"
      render={(arrayHelpers) => (
        <ul className="compound">
          {players.length>0 && players.map((player, index) => (
            <CompoundCommandsItem
            formik={formik}
              key={index}
              index={index}
              player={player}
              onRemove={() => handleRemove(arrayHelpers, index)}
              onAdd={() =>handleAdd(arrayHelpers)}
              disableRemove={numForms === 1}
            />
          ))}
        </ul>
      )}
    />
  );
}

export default CompoundCommandsList;
