import React from "react"
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import PlayersItem from "../../components/ordinary/players-item/players-item"
import Stub from "../../components/ordinary/stub/stub"
import MeetingStatsPlayer from "../../components/ordinary/meeting-stats-player/meeting-stats-player"
import ButtonsList from "../../components/ui/buttons-list/buttons-list"
import {useAppSelector} from "../../core/hooks"
import {
  getPlayer,
  getPlayerErrorStatus,
  getPlayerLoadingStatus,
} from "../../core/store/players-data/selectors"
import {AppRoute} from "../../core/constants/routes"
import {generatePlayerButtonsArray} from "../../core/utils/common-helpers"
import {
  getMatchesErrorStatus,
  getMatchesLoadingStatus
} from "../../core/store/matches-data/selectors"

function PlayerMeetPage() {
  const player = useAppSelector(getPlayer)
  const playerLoading = useAppSelector(getPlayerLoadingStatus)
  const playerError = useAppSelector(getPlayerErrorStatus)
  const matchesLoading = useAppSelector(getMatchesLoadingStatus)
  const matchesError = useAppSelector(getMatchesErrorStatus)
  const playerMeetPageLoading = playerLoading || matchesLoading
  const playerMeetPageError = playerError || matchesError
  const buttonsArray = generatePlayerButtonsArray(player)

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.PlayersRatingPage,
    {
      title: `${player?.first_name} ${player?.last_name}`,
      url: "#",
    },
  ]

  return (
    <div className="container">
      <div
        className={`gap gap--40 ${playerMeetPageLoading || playerMeetPageError ? "hidden" : ""}`}
      >
        <CrumbsList routes={crumbsRoutes}/>
        <div className="grid-profile">
          <PlayersItem/>
          <div className="gap gap--0 gap--start">
            <ButtonsList
              className="buttons--border"
              buttonsData={buttonsArray}
            />
            <div className="blocks">
              <svg className="blocks__background" width="1400" height="600">
                <use xlinkHref="images/sprite.svg#background-logo"/>
              </svg>
              <MeetingStatsPlayer/>
            </div>
          </div>
        </div>
      </div>
      <Stub
        type={playerMeetPageError ? "error" : ""}
        error={
          playerMeetPageError
            ? "Произошла ошибка при загрузке страницы личные встречи."
            : ""
        }
      />
    </div>
  )
}

export default PlayerMeetPage
