import React, {
  useEffect,
  useState,
} from "react";
import {Link} from "react-router-dom";
import ModalEntranceList from "../modal-entrance-list/modal-entrance-list";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {login} from "../../../core/store/api-actions";
import {useFormik} from 'formik';
import {loginFormValidationSchema} from "../../../core/utils/form-validate-helpers";
import {
  getAuthorizationErrors,
  getAuthorizationSendStatus
} from "../../../core/store/authorization-data/selectors";
import {
  sendErrorText,
  SendRequestStatus
} from "../../../core/constants/common";

function ModalEntrance({hidden}: { hidden: boolean }) {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<string[]>([]);
  const loginErrors = useAppSelector(getAuthorizationErrors);
  const loginSendStatus = useAppSelector(getAuthorizationSendStatus);

  useEffect(() => {
    if (loginErrors) {
      if (loginErrors.email) {
        loginErrors.email.map((error) => {
          setErrors((prevErrors) => [...prevErrors, error]);
        })
      }
      if (loginErrors.password) {
        loginErrors.password.map((error) => {
          setErrors((prevErrors) => [...prevErrors, error])
        })
      }
    }
  }, [loginErrors]);

  useEffect(() => {
    if (loginSendStatus === SendRequestStatus.UnSuccess) {
      setErrors((prevErrors) => [...prevErrors, sendErrorText])
    }
  }, [loginSendStatus])

  // eslint-disable-next-line
  const onSubmit = async (values: any) => {
    dispatch(login(values));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: 0,
    },
    validationSchema: loginFormValidationSchema,
    onSubmit
  });

  return (
    <div className={`modal__background ${hidden ? 'hidden' : ''}`}>
      <svg className="modal__logo" width="1025" height="440">
        <use xlinkHref="images/sprite.svg#background-logo"/>
      </svg>
      <div className="modal__column">
        <ul className="modal__errors">
          {errors.map((error) => (
            <li className="modal__error" key={error}>{error}</li>
          ))}
        </ul>
        <form className="modal__form" onSubmit={formik.handleSubmit}>
          <ModalEntranceList
            email={formik.values.email}
            password={formik.values.password}
            onInputChange={(e) => {
              formik.handleChange(e);
              setErrors([]);
            }}
            errors={{email: formik.errors.email, password: formik.errors.password}}
          />
          <div className="modal__block">
            <label className="check">
              <input
                name="remember"
                className="check__input"
                type="checkbox"
                value={formik.values.remember}
                onInputCapture={(e) => {
                  formik.handleChange(e);
                  setErrors([]);
                }}
              />
              <span className="check__indicator"></span>
              <span className="check__text">Запомнить меня</span>
            </label>
            <Link className="modal__recovery" to="#">Забыли пароль?</Link>
          </div>
          <button className="modal__button modal__button--uppercase" type="submit">Войти</button>
          <Link className="modal__reactivation" to="#">Повторная активация</Link>
        </form>
      </div>
    </div>
  );
}

export default ModalEntrance;
