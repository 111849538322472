import {RoundTable} from "../../../api"
import React from "react"
import {Link} from "react-router-dom"
import {AppRoute} from "../../../core/constants/routes";

const ChessTeamsRow = ({roundTable, index}: {
  roundTable: RoundTable
  index: number }) => {
  return (
    <tr className="table__row">
      <td className="table__cell table__number">{index + 1}</td>
      <td className="table__cell table__start">
        <Link
          className="table__link"
          to={`${AppRoute.CommandCalendarPage.url}/${roundTable.team_id}`}
        >
          <img
            className="table__img"
            src={
              roundTable.team?.avatar.includes("logo_placeholder_100x100.svg")
                ? "/images/default/team.svg"
                : roundTable.team?.avatar
            }
            width="25"
            height="25"
            alt="Логотип команды"
          />
          <p className="table__team">{roundTable.team?.full_name}</p>
        </Link>
      </td>
      <td className="table__cell table__green">{roundTable.games}</td>
    </tr>
  )
}

export default ChessTeamsRow
