import React, {
  useEffect,
  useRef,
  useState
} from "react"
import NavigationItem from "../../ordinary/navigation-item/navigation-item"
import {HeaderNavigations} from "../../../core/constants/navigations"
import {NavigationWithSubMenu} from "../../../core/types/navigation"
import Burger from "../burger/burger"

function NavigationList() {
  const [activeNavigation, setActiveNavigation] = useState<NavigationWithSubMenu | null>(null)
  const [isHoverMode, setIsHoverMode] = useState<boolean>(window.innerWidth <= 1080)
  const menuRef = useRef<HTMLUListElement>(null)

  const handleNavigationHoverClick = (navigation: NavigationWithSubMenu) => {
    setActiveNavigation(prevNavigation => (prevNavigation === navigation ? null : navigation))
  }

  const handleMouseLeave = () => {
    setActiveNavigation(null)
  }

  useEffect(() => {
    setIsHoverMode(window.innerWidth >= 1080)
    const handleResize = () => {
      setIsHoverMode(window.innerWidth >= 1080)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setActiveNavigation(null)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef])

  return (
    <nav className="navigation">
      <Burger/>
      <ul className="navigation__list" ref={menuRef}>
        {HeaderNavigations.map((headerNavigation) => (
          <NavigationItem
            headerNavigation={headerNavigation}
            key={headerNavigation.title}
            isActive={activeNavigation === headerNavigation}
            onHoverClick={() => handleNavigationHoverClick(headerNavigation)}
            onMouseLeave={handleMouseLeave}
            isHoverMode={isHoverMode}
          />
        ))}
      </ul>
    </nav>
  )
}

export default NavigationList
