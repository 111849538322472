import React, {useState} from 'react';
import {Link} from "react-router-dom";

function Share() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleShare = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`share ${isOpen ? 'share--open' : ''}`}>
      <a className="share__link" onClick={toggleShare}>
        <p className="share__text">Поделиться</p>
        <div className="share__border">
          <svg className="share__logo" width="8" height="8"><use xlinkHref="images/sprite.svg#share"/></svg>
        </div>
      </a>
      <div className="share__socials">
        <div className="share__line"></div>
        <Link className="share__social" to="#">
          <svg className="share__icon" width="15" height="15"><use xlinkHref="images/sprite.svg#tg"/></svg>
        </Link>
        <Link className="share__social" to="#">
          <svg className="share__icon" width="15" height="15"><use xlinkHref="images/sprite.svg#vk"/></svg>
        </Link>
        <Link className="share__social" to="#">
          <svg className="share__icon" width="15" height="15"><use xlinkHref="images/sprite.svg#yt"/></svg>
        </Link>
      </div>
    </div>
  );
}

export default Share;
