import React from "react";
import Title from "../../ui/title/title";
import ButtonForm from "../../ui/button-form/button-form";
import { TournamentTemp, TeamTemp } from "../../../api";
import RequestStatus from "../../simple/request-status/request-status";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";

function RequestApplicationItem({
  temp,
  type,
}: {
  temp?: TournamentTemp | TeamTemp;
  type: "tournament" | "team";
}) {
  return (
    <div className="gap gap--40">
      <div className="background-form">
        <div className="gap gap--row gap--25">
          <Title
            className="title--white"
            title={
              type === "tournament"
                ? temp && (temp as TournamentTemp)
                  ? temp.full_name
                  : ""
                : `Команда ${temp && (temp as TeamTemp) ? temp.full_name : ""}`
            }
          />
          <div className="gap gap--row-2 gap--40">
            <RequestStatus className="clue--white" status={temp?.status} />
            <Link
              to={`${
                type === "tournament"
                  ? AppRoute.RegistrationTournamentPage.url
                  : AppRoute.RegistrationCommandsPage.url
              }/${
                type === "tournament"
                  ? (temp && (temp as TournamentTemp).tournament_id) || ""
                  : (temp && (temp as TeamTemp).team_id) || ""
              }`}
            >
              <ButtonForm
                className={temp ? "" : "save--disabled"}
                title="Посмотреть заявку"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestApplicationItem;
