import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {Errors} from "../../../api";

export const getAuthorizationSendStatus = (state: State): string => state[NameSpace.Authorization].sendStatus;
export const getAuthorizationStatus = (state: State): boolean | undefined => state[NameSpace.Authorization].authorizationStatus;
export const getAuthorizationToken = (state: State): string | undefined => state[NameSpace.Authorization].token;
export const getAuthorizationErrors = (state: State): Errors | undefined => state[NameSpace.Authorization].errors;
export const getAuthorizationRemember = (state: State): number => state[NameSpace.Authorization].remember;
export const getAuthorizationUserId = (state: State): string | undefined => state[NameSpace.Authorization].userId;
export const getAuthorizationUserRole = (state: State): string | undefined => state[NameSpace.Authorization].userRole;