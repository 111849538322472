import React from "react";
import FilterMatchesItem from "../../smart/filter-matches-item/filter-matches-item";

function FilterMatches() {
  return (
    <ul className="filter filter--matches">
      <FilterMatchesItem />
    </ul>
  );
}

export default FilterMatches;
