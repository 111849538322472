import React from "react";
import TournamentsItem from "../tournaments-item/tournaments-item";
import {Tournament} from "../../../api";

function TournamentsList({tournaments}: { tournaments: Tournament[] }) {
  return (
    <ul className={`tournaments ${tournaments.length === 0 ? "hidden" : ""}`}>
      {tournaments.map((tournament) => (
        <TournamentsItem key={tournament.tournament_id} tournament={tournament}/>
      ))}
    </ul>
  );
}

export default TournamentsList;
