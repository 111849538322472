import React from "react";

function Title({title, className, titleGreen}: {
  title?: string,
  className?: string,
  titleGreen?: string
}) {
  return (
    <h2 className={`title ${className || ''}`}>{title}
      {titleGreen && <span className="title__green">{titleGreen}</span>}
    </h2>
  );
}

export default Title;
