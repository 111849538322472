import React from "react";
import SocialItem from "../../ordinary/social-item/social-item";

function SocialList() {
  return (
    <div className="social">
      <SocialItem social="tg"/>
      <SocialItem social="vk"/>
      <SocialItem social="yt"/>
    </div>
  );
}

export default SocialList;
