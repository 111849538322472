import {MatchSeries} from "../../../api";
import React from "react";
import ChessCell from "../chess-cell/chess-cell";

const ChessRow = ({seriesGroup}: { seriesGroup: MatchSeries[][] }) => {
  return (
    <tr className="table__row">
      {Object.values(seriesGroup).map((seriesArray, cellIndex) => (
        seriesArray === null
          ? <td className="table__cell table-scores__empty" key={cellIndex}></td>
          : <ChessCell seriesArray={seriesArray} key={cellIndex}/>
      ))}
    </tr>
  );
}
export default ChessRow;
