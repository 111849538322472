import React, {
  useEffect,
  useState
} from 'react'
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list"
import Title from "../../components/ui/title/title"
import ButtonsList from "../../components/ui/buttons-list/buttons-list"
import {ratingPage} from "../../core/constants/navigations"
import Pagination from "../../components/ordinary/pagination/pagination"
import Amount from "../../components/ordinary/amount/amount"
import RatingCommandsTable from "../../components/ordinary/rating-commands-table/rating-commands-table"
import FilterRatingCommandsList from "../../components/simple/filter-rating-commands-list/filter-rating-commands-list"
import {
  fetchTeamsRating,
  fetchTeamsStats
} from "../../core/store/api-actions"
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks"
import {AppRoute} from "../../core/constants/routes"
import {RATING_PER_PAGE} from "../../core/constants/common"
import Stub from "../../components/ordinary/stub/stub"
import {
  filterTeamsRatingByRegionId,
  filterTeamsRatingByString
} from "../../core/utils/array-prepare-helpers"
import {
  getTeamsRatingFilterRegionStatus,
  getTeamsRating,
  getTeamsRatingError,
  getTeamsRatingLoading,
  getTeamsRatingSearchString,
  getTeamsRatingFilterSeasonStatus
} from "../../core/store/teams-rating-data/selectors"
import {
  getTeamsStats,
  getTeamsStatsError,
  getTeamsStatsLoading
} from "../../core/store/teams-stats-data/selectors"
import {RatingTeam} from "../../api"

function CommandsRatingPage() {
  const dispatch = useAppDispatch()
  const teamsRating = useAppSelector(getTeamsRating)
  const teamsRatingLoading = useAppSelector(getTeamsRatingLoading)
  const teamsRatingError = useAppSelector(getTeamsRatingError)
  const teamsStats = useAppSelector(getTeamsStats)
  const teamsStatsLoading = useAppSelector(getTeamsStatsLoading)
  const teamsStatsError = useAppSelector(getTeamsStatsError)
  const pageError = teamsRatingError || teamsStatsError
  const pageLoading = teamsRatingLoading || teamsStatsLoading
  const hidden = pageLoading || pageError || teamsRating.length === 0
  const filterSeasonStatus = useAppSelector(getTeamsRatingFilterSeasonStatus)
  const filterRegionStatus = useAppSelector(getTeamsRatingFilterRegionStatus)
  const teamsRatingSearchString = useAppSelector(getTeamsRatingSearchString)
  const crumbsRoutes = [AppRoute.Main, AppRoute.CommandsRatingPage]
  const [filteredTeamsRating, setFilteredTeamsRating] = useState<RatingTeam[]>([])

  const [currentPage, setCurrentPage] = useState(0)
  const teamsRatingPerPage = RATING_PER_PAGE
  const pageCount = Math.ceil((filteredTeamsRating?.length || 0) / teamsRatingPerPage)
  const indexOfLastTeamRating = (currentPage + 1) * teamsRatingPerPage
  const indexOfFirstTeamRating = indexOfLastTeamRating - teamsRatingPerPage
  const currentTeamsRating = filteredTeamsRating.slice(indexOfFirstTeamRating, indexOfLastTeamRating)

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected)
  }

  useEffect(() => {
    let filteredTeams = teamsRating;
    if (filterRegionStatus?.label && filterRegionStatus.value !== "all") {
      filteredTeams = filterTeamsRatingByRegionId(filteredTeams, Number(filterRegionStatus.value));
    }
    if (teamsRatingSearchString !== '') {
      filteredTeams = filterTeamsRatingByString(filteredTeams, teamsRatingSearchString);
    }
    setFilteredTeamsRating(filteredTeams);
  }, [filterRegionStatus, teamsRatingSearchString, teamsRating]);

  useEffect(() => {
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      dispatch(fetchTeamsRating({seasonId: Number(filterSeasonStatus.value)}))
      dispatch(fetchTeamsStats({seasonId: Number(filterSeasonStatus.value)}))
    } else {
      dispatch(fetchTeamsRating({}))
      dispatch(fetchTeamsStats({}))
    }
  }, [dispatch, filterSeasonStatus])

  return (
    <div className="container">
      <div className="gap gap--20">
        <CrumbsList routes={crumbsRoutes}/>
        <div className="gap gap--row">
          <Title className="title--uppercase" title="рейтинг команд"/>
          <ButtonsList buttonsData={ratingPage}/>
        </div>
        <div className="gap gap--25">
          <div className="gap gap--0">
            <div className="gap gap--filter">
              <Amount amount={teamsRating.length} type="команд"/>
              <FilterRatingCommandsList/>
            </div>
            <Stub
              type={pageError ? "error" : teamsRating.length > 0 && filteredTeamsRating.length === 0 ? "searchError" : teamsRating.length === 0 ? "absence" : ""}
              error={pageError ? "Произошла ошибка при загрузке рейтинга команд." : ""}
            />
          </div>
          <div className={`gap ga--40 ${hidden ? 'hidden' : ''}`}>
            <RatingCommandsTable
              teamsRating={currentTeamsRating}
              teamsStats={teamsStats}
            />
            <Pagination
              pageCount={pageCount}
              forcePage={currentPage}
              onPageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommandsRatingPage
