import React from "react";
import { useLocation } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";

function Author({
  name,
  date,
  number,
}: {
  name?: string;
  date?: string;
  number?: number;
}) {
  const location = useLocation();
  const isAlbumPage = location.pathname === AppRoute.AlbumPage.url;
  const dateTime = date ? new Date(date) : "";
  const publishedTime = dateTime
    ? simpleDateFormat(dateTime, "HH:mm")
    : "Время неизвестно";
  const publishedDate = dateTime
    ? simpleDateFormat(dateTime, "dd MMMM yyyy")
    : "Дата неизвестно";
  return (
    <div className="author">
      <p className={`author__name ${name ? "" : "hidden"}`}>
        <span className="author__green">Автор:</span> {name}
      </p>
      <div className="author__block">
        <div className={`author__info ${!isAlbumPage ? "hidden" : ""}`}>
          <svg className="author__icon" width="20" height="20">
            <use xlinkHref="images/sprite.svg#camera" />
          </svg>
          <p className="author__number">{number}</p>
        </div>
        <p className="author__info">{publishedDate}</p>
        <p className={`author__info ${isAlbumPage ? "hidden" : ""}`}>
          {publishedTime}
        </p>
      </div>
    </div>
  );
}

export default Author;
