import React, {useState} from "react";

function ModalEntranceList({onInputChange, email, password, errors}: {
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  email: string;
  password: string;
  errors: { email?: string; password?: string };
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="modal__list">
      <label className="form">
        <p className="form__text">Email<span className={`form__help ${errors.email ? '' : 'hidden'}`}>{errors.email}</span></p>
        <input
          className={`form__input ${errors.email ? 'form__input--error' : ''}`}
          type="text"
          name="email"
          placeholder="Введите вашу почту"
          onChange={onInputChange}
          value={email}
        />
      </label>
      <label className="form form--password">
        <p className="form__text">Пароль<span className={`form__help ${errors.password ? '' : 'hidden'}`}>{errors.password}</span></p>
        <div className="form__password">
          <input
            className={`form__input ${errors.password ? 'form__input--error' : ''}`}
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Введите ваш пароль"
            onChange={onInputChange}
            value={password}
          />
        </div>
        <button
          className={`form__control ${showPassword ? "form__control--view" : ""}`}
          type="button"
          onClick={() => setShowPassword(!showPassword)}
        ></button>
      </label>
    </div>
  );
}

export default ModalEntranceList;
