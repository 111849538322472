import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchVideo, fetchVideos } from "../api-actions";
import { VideosState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { FilterOption } from "../../types/common";

const initialState: VideosState = {
  isVideosLoading: false,
  isVideoLoading: false,
  isVideosError: false,
  isVideoError: false,
  videos: [],
  currentVideo: null,
  filterSeasonStatus: {
    value: "all",
    label: "все сезоны",
  },
};

export const videosData = createSlice({
  name: NameSpace.Videos,
  initialState,
  reducers: {
    filteringVideosBySeason: (
      state,
      action: PayloadAction<{ filterSeasonStatus: FilterOption }>
    ) => {
      const { filterSeasonStatus } = action.payload;
      state.filterSeasonStatus = filterSeasonStatus;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.isVideosLoading = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.videos = action.payload;
        state.isVideosLoading = false;
      })
      .addCase(fetchVideos.rejected, (state) => {
        state.isVideosError = true;
        state.isVideosLoading = false;
      })
      .addCase(fetchVideo.pending, (state) => {
        state.isVideoLoading = true;
      })
      .addCase(fetchVideo.fulfilled, (state, action) => {
        state.currentVideo = action.payload;
        state.isVideoLoading = false;
      })
      .addCase(fetchVideo.rejected, (state) => {
        state.isVideoError = true;
        state.isVideoLoading = false;
      });
  },
});

export const { filteringVideosBySeason } = videosData.actions;
