import React, {useEffect} from "react";
import Search from "../../ordinary/search/search";
import Dropdown from "../dropdown/dropdown";
import {
  DropdownTypes,
  ItemsForFilter
} from "../../../core/constants/filters";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getSeasons} from "../../../core/store/seasons-data/selectors";
import {
  transformRegionsToOptions,
  transformSeasonsToOptions
} from "../../../core/utils/array-prepare-helpers";
import Reset from "../reset/reset";
import {getRegions} from "../../../core/store/regions-data/selectors";
import {
  fetchRegions,
  fetchSeasons
} from "../../../core/store/api-actions";

function FilterRatingCommandsItem() {
  const dispatch = useAppDispatch();
  const seasons = useAppSelector(getSeasons);
  const seasonsOptions = transformSeasonsToOptions(seasons);
  const regions = useAppSelector(getRegions);
  const regionsOptions = transformRegionsToOptions(regions);

  useEffect(() => {
    dispatch(fetchSeasons());
    dispatch(fetchRegions());
  }, [dispatch]);

  return (
    <>
      <li className="filter__item">
        <Dropdown
          options={seasonsOptions}
          type={DropdownTypes.Season}
          items={ItemsForFilter.TeamsRating}
        />
      </li>
      <li className="filter__item">
        <Dropdown
          options={regionsOptions}
          type={DropdownTypes.Region}
          items={ItemsForFilter.TeamsRating}
        />
      </li>
      <li className="filter__item">
        <Search items={ItemsForFilter.TeamsRating}/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.TeamsRating}/>
      </li>
    </>
  );
}

export default FilterRatingCommandsItem;
