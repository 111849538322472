import React from "react";
import { Link } from "react-router-dom";
import { MatchSeries } from "../../../api";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { AppRoute } from "../../../core/constants/routes";

function CalendarItem({ matchSeries }: { matchSeries: MatchSeries }) {
  const startDateTime = matchSeries.start_dt
    ? new Date(matchSeries.start_dt)
    : "";
  const startTime = startDateTime
    ? simpleDateFormat(startDateTime, "HH:mm")
    : "время не указано";
  const startDate = startDateTime
    ? simpleDateFormat(startDateTime, "dd MMMM yyyy")
    : "дата не указана";

  return (
    <li className="widget__item">
      <Link
        className="widget__link"
        to={`${AppRoute.MatchPage.url}/${matchSeries.series_id}`}
      >
        <div className="widget__teams">
          <div className="widget__team">
            <img
              className="widget__logo"
              src={
                matchSeries.team1?.avatar.includes(
                  "logo_placeholder_100x100.svg",
                )
                  ? "/images/default/team.svg"
                  : matchSeries.team1?.avatar
              }
              alt={matchSeries.team1?.full_name}
              width="50"
              height="50"
            />
            <p className="widget__name">{matchSeries.team1?.full_name}</p>
          </div>
          <p className="widget__scores">
            <span
              className={`widget__score ${matchSeries.score1 !== undefined && matchSeries.score2 !== undefined && matchSeries.score1 > matchSeries.score2 ? "widget__score--win" : ""}`}
            >
              {matchSeries.score1 === null ? "-" : matchSeries.score1}
            </span>
            <span className="widget__colon"> : </span>
            <span
              className={`widget__score ${matchSeries.score2 !== undefined && matchSeries.score1 !== undefined && matchSeries.score2 > matchSeries.score1 ? "widget__score--win" : ""}`}
            >
              {matchSeries.score2 === null ? "-" : matchSeries.score2}
            </span>
          </p>
          <div className="widget__team">
            <img
              className="widget__logo"
              src={
                matchSeries.team2?.avatar.includes(
                  "logo_placeholder_100x100.svg",
                )
                  ? "/images/default/team.svg"
                  : matchSeries.team2?.avatar
              }
              alt={matchSeries.team2?.full_name}
              width="50"
              height="50"
            />
            <p className="widget__name">{matchSeries.team2?.full_name}</p>
          </div>
        </div>
        <p className="widget__infos">
          <span className="widget__date">{startDate}</span>
          <span className="widget__line"> | </span>
          <span className="widget__time">{startTime}</span>
        </p>
      </Link>
    </li>
  );
}

export default CalendarItem;
