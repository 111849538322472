import { BannerPositionEnum } from "../../../api";
import React, { useEffect } from "react";
import BannerItem from "../banner-item/banner-item";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getBanners } from "../../../core/store/banners-data/selectors";
import { filterBannersByPosition } from "../../../core/utils/array-prepare-helpers";
import { fetchBanners } from "../../../core/store/api-actions";
import { Link } from "react-router-dom";

function BannerList({
  bannerPosition,
}: {
  bannerPosition: BannerPositionEnum;
}) {
  const dispatch = useAppDispatch();
  const banners = useAppSelector(getBanners);
  const filteredBanners = filterBannersByPosition(banners, bannerPosition);
  const sidebarPosition = bannerPosition.includes("sidebar");

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  return (
    <>
      <div
        className={`gap gap--20 ${filteredBanners.length === 0 ? "hidden" : ""}`}
      >
        {filteredBanners.map((banner) => (
          <BannerItem banner={banner} key={banner.banner_id} />
        ))}
      </div>
      {/*нужно доработать*/}
      <Link
        className={`banner ${filteredBanners.length > 0 ? "hidden" : ""}`}
        to="mailto:info@amatour.ru"
        target="_blank"
      >
        <img
          className="banner__img"
          src={
            sidebarPosition
              ? "/images/default/banner-sidebar.svg"
              : "/images/default/banner-page.png"
          }
          alt="Рекламный баннер"
        />
      </Link>
    </>
  );
}

export default BannerList;
