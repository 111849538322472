import React from "react";
import Tourney from "../tourney/tourney";
import { BannerPositionEnum } from "../../../api";
import BannerList from "../../ordinary/banner-list/banner-list";

function AsideBanners({
  bannerPosition,
}: {
  bannerPosition: BannerPositionEnum;
}) {
  return (
    <aside className="sidebar-banner">
      <Tourney />
      <BannerList bannerPosition={bannerPosition} />
    </aside>
  );
}

export default AsideBanners;
