import React from 'react';
import Title from "../../components/ui/title/title";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Personal from "../../components/ordinary/personal/personal";
import { AppRoute } from '../../core/constants/routes'

function PersonalPage() {
  
  const crumbsRoutes = [AppRoute.Main, AppRoute.PersonalPage];
  return (
    <div className="container">
      <div className="gap gap--20">
        <CrumbsList routes={crumbsRoutes}/>
        <Title className="title--uppercase title--center" title="Личный кабинет"/>
        <div className="background-form">
          <Personal/>
        </div>
      </div>
    </div>
  );
}

export default PersonalPage;
