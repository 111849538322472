import React, { useEffect, useState } from "react";
import Title from "../../ui/title/title";
import FilterMatches from "../../ordinary/filter-matches/filter-matches";
import Button from "../../ui/button/button";
import { AppRoute } from "../../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import CalendarList from "../../ordinary/calendar-list/calendar-list";
import { fetchMatchSeriesArray } from "../../../core/store/api-actions";
import {
  getMatchSeriesArray,
  getMatchSeriesArrayErrorStatus,
} from "../../../core/store/match-series-data/selectors";
import {
  getCalendarTournamentStageFilter,
  getCalendarTournamentTypeFilter,
} from "../../../core/store/calendar-data/selectors";
import {
  filterMatchSeriesArrayByStage,
  filterMatchSeriesArrayByTournamentType,
  getTopItemsByStartDt,
} from "../../../core/utils/array-prepare-helpers";
import { filterTournamentsType } from "../../../core/constants/filters";
import Stub from "../../ordinary/stub/stub";

function MatchesWidget() {
  const dispatch = useAppDispatch();
  const matchSeriesArray = useAppSelector(getMatchSeriesArray);
  const matchSeriesArrayError = useAppSelector(getMatchSeriesArrayErrorStatus);
  const tournamentTypeFilter = useAppSelector(getCalendarTournamentTypeFilter);
  const tournamentStageFilter = useAppSelector(
    getCalendarTournamentStageFilter,
  );

  const [filteredMatchSeriesArray, setFilteredMatchSeriesArray] =
    useState(matchSeriesArray);

  useEffect(() => {
    const filteredMatchSeriesArrayByTournamentType =
      filterMatchSeriesArrayByTournamentType(
        matchSeriesArray,
        tournamentTypeFilter.value,
      );
    let filteredMatchSeriesArrayByTournamentStage =
      filteredMatchSeriesArrayByTournamentType;
    if (tournamentTypeFilter === filterTournamentsType[0]) {
      filteredMatchSeriesArrayByTournamentStage = filterMatchSeriesArrayByStage(
        filteredMatchSeriesArrayByTournamentType,
        tournamentStageFilter.label,
      );
    }
    const topMatchSeriesArray = getTopItemsByStartDt(
      filteredMatchSeriesArrayByTournamentStage,
      3,
    );
    setFilteredMatchSeriesArray(topMatchSeriesArray);
  }, [tournamentTypeFilter, matchSeriesArray, tournamentStageFilter]);

  useEffect(() => {
    dispatch(
      fetchMatchSeriesArray({
        _with: ["team1", "team2", "tournament", "round"],
      }),
    );
  }, [dispatch]);

  return (
    <div className="gap gap--30">
      <div className="container-row">
        <div className="gap gap--row-2">
          <div className="gap gap--40 gap--row-3">
            <Title className="title--uppercase" title="Календарь" />
            <FilterMatches />
          </div>
          <Button
            className="button--full show-580"
            text={`Все ${tournamentTypeFilter === filterTournamentsType[0] ? "лиги" : "турниры"}`}
            to={
              tournamentTypeFilter === filterTournamentsType[0]
                ? AppRoute.LeaguePage.url
                : AppRoute.TournamentsPage.url
            }
          />
        </div>
      </div>
      <div className="gap gap--0">
        <CalendarList matchSeriesArray={filteredMatchSeriesArray} />
        <Stub
          type={
            matchSeriesArrayError
              ? "error"
              : matchSeriesArray.length > 0 &&
                  filteredMatchSeriesArray.length === 0
                ? "searchError"
                : matchSeriesArray.length === 0
                  ? "absence"
                  : ""
          }
          error={
            matchSeriesArrayError
              ? "Произошла ошибка при загрузке серий матчей."
              : ""
          }
        />
        <Button
          className="button--full hide-580"
          text={`Все ${tournamentTypeFilter === filterTournamentsType[0] ? "лиги" : "турниры"}`}
          to={
            tournamentTypeFilter === filterTournamentsType[0]
              ? AppRoute.LeaguePage.url
              : AppRoute.TournamentsPage.url
          }
        />
      </div>
    </div>
  );
}

export default MatchesWidget;
