import React from "react";
import Dropdown from "../dropdown/dropdown";
import {
  DropdownTypes,
  filterStage,
  filterTournamentsType,
  ItemsForFilter,
} from "../../../core/constants/filters";
import { useAppSelector } from "../../../core/hooks";
import { getCalendarTournamentTypeFilter } from "../../../core/store/calendar-data/selectors";

function FilterMatchesItem() {
  const tournamentTypeFilter = useAppSelector(getCalendarTournamentTypeFilter);

  return (
    <>
      <li className="filter__item">
        <Dropdown
          options={filterTournamentsType}
          type={DropdownTypes.TournamentType}
          items={ItemsForFilter.MatchSeriesArray}
        />
      </li>
      <li
        className={`filter__item ${tournamentTypeFilter === filterTournamentsType[0] ? "" : "hidden"}`}
      >
        <Dropdown
          options={filterStage}
          type={DropdownTypes.Stage}
          items={ItemsForFilter.MatchSeriesArray}
        />
      </li>
    </>
  );
}

export default FilterMatchesItem;
