import React from "react";
import {NavigationWithSubMenu} from "../../../core/types/navigation";
import NavItem from "../nav-item/nav-item";

function NavList({headerNavigation, isActive, onClick}: {
  headerNavigation: NavigationWithSubMenu;
  isActive: boolean;
  onClick: () => void;
}) {

  const isHidden = !headerNavigation.submenu;

  return (
    <ul className={`nav ${isActive ? "nav--active" : ""} ${isHidden ? "hidden" : ""}`}>
      {headerNavigation.submenu?.map((headerSubNavigation) => (
        <NavItem
          key={headerSubNavigation.title}
          headerNavigation={headerSubNavigation}
          onClick={onClick}
        />
      ))}
    </ul>
  );
}

export default NavList;
