import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  transformCitiesToOptions,
  transformZonesToOptions,
} from "../../../core/utils/array-prepare-helpers";
import { getZones } from "../../../core/store/zones-data/selectors";
import { fetchCities, fetchZones } from "../../../core/store/api-actions";
import FormSelect from "../../smart/select/select";
import { SelectTypes } from "../../../core/constants/selects";
import { getCities } from "../../../core/store/cities-data/selectors";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TournamentForm({ formik }: { formik: any }) {
  const dispatch = useAppDispatch();
  const zones = useAppSelector(getZones);
  const cities = useAppSelector(getCities);
  const zonesOptions = transformZonesToOptions(zones);
  const citiesOptions = transformCitiesToOptions(cities);

  useEffect(() => {
    dispatch(fetchZones());
    dispatch(fetchCities());
  }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  return (
    <div className="fields">
      <div className="fields__item fields__item--3">
        <label className="form">
          <p className="form__text">
            Название турнира
            <span
              className={`form__help ${formik.errors.full_name ? "" : "hidden"}`}
            >
              {formik.errors.full_name}
            </span>
          </p>

          <input
            className={`form__input ${
              formik.errors.full_name ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="full_name"
            value={formik.values.full_name}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">
            Короткое название
            <span
              className={`form__help ${formik.errors.short_name ? "" : "hidden"}`}
            >
              {formik.errors.short_name}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.short_name ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="short_name"
            value={formik.values.short_name}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">
            Название клуба
            <span
              className={`form__help ${formik.errors.club ? "" : "hidden"}`}
            >
              {formik.errors.club}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.club ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="club"
            value={formik.values.club}
            onChange={handleInputChange}
          />
        </label>
      </div>
      <div className="fields__item fields__item--3">
        <label className="form">
          <p className="form__text">
            Адрес клуба
            <span
              className={`form__help ${formik.errors.club_address ? "" : "hidden"}`}
            >
              {formik.errors.club_address}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.club_address ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите адрес"
            name="club_address"
            value={formik.values.club_address}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">
            Начало турнира
            <span
              className={`form__help ${formik.errors.start_dt ? "" : "hidden"}`}
            >
              {formik.errors.start_dt}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.start_dt ? "form__input--error" : ""
            }`}
            type="date"
            placeholder="Введите дату"
            name="start_dt"
            value={formik.values.start_dt}
            onChange={handleInputChange}
          />
        </label>
        <label className="form">
          <p className="form__text">
            Окончание турнира
            <span
              className={`form__help ${formik.errors.end_dt ? "" : "hidden"}`}
            >
              {formik.errors.end_dt}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.end_dt ? "form__input--error" : ""
            }`}
            type="date"
            placeholder="Введите дату"
            name="end_dt"
            value={formik.values.end_dt}
            onChange={handleInputChange}
          />
        </label>
      </div>
      <div className="fields__item fields__item--3">
        <label className="form">
          <p className="form__text">
            Город
            <span
              className={`form__help ${formik.errors.city_id ? "" : "hidden"}`}
            >
              {formik.errors.city_id}
            </span>
          </p>
          <FormSelect
            options={citiesOptions}
            type={SelectTypes.Cities}
            formik={formik}
            value={formik.values.city_id}
            name="city_id"
          />
        </label>
        <label className="form">
          <p className="form__text">Желаемая региональная зона<span
              className={`form__help ${formik.errors.zone_id ? "" : "hidden"}`}
            >
              {formik.errors.zone_id}
            </span></p>
          <FormSelect
            options={zonesOptions}
            type={SelectTypes.Zones}
            formik={formik}
            value={formik.values.zone_id}
            name="zone_id"
          />
        </label>
        <label className="form">
          <p className="form__text">
            Покрытие корта{" "}
            <span
              className={`form__help ${
                formik.errors.court_coverage ? "" : "hidden"
              }`}
            >
              {formik.errors.court_coverage}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.court_coverage ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите тип"
            name="court_coverage"
            value={formik.values.court_coverage}
            onChange={handleInputChange}
          />
        </label>
      </div>
      <div className="fields__item fields__item--3">
        <label className="form">
          <p className="form__text">
            Мяч
            <span
              className={`form__help ${
                formik.errors.official_ball ? "" : "hidden"
              }`}
            >
              {formik.errors.official_ball}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.official_ball ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="official_ball"
            value={formik.values.official_ball}
            onChange={handleInputChange}
          />
        </label>
        {/* не готов бек */}
        <label className="form">
          <p className="form__text">
            Контактный телефон(инфо)
            <span
              className={`form__help ${
                formik.errors.contact_info ? "" : "hidden"
              }`}
            >
              {formik.errors.contact_info}
            </span>
          </p>
          <input
            className={`form__input ${
              formik.errors.contact_info ? "form__input--error" : ""
            }`}
            type="text"
            placeholder="Введите название"
            name="contact_info"
            value={formik.values.contact_info}
            onChange={handleInputChange}
          />
        </label>
        {/* не готов бек */}
        <label className="form">
          <p className="form__text">ФИО организатора</p>
          <input
            className="form__input"
            type="text"
            placeholder="Введите ФИО"
          />
        </label>
      </div>
    </div>
  );
}

export default TournamentForm;
