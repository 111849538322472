import React from 'react';
import Title from "../../components/ui/title/title";
import {Link} from "react-router-dom";
import {AppRoute} from "../../core/constants/routes";

function ErrorPage() {
  return (
    <div className="mistake">
      <Title title="Страница не найдена"/>
      <p className="mistake__number">404</p>
      <div className="gap gap--20">
        <p className="mistake__title">Простите, но у нас возникли проблемы с поиском страницы, которую Вы запрашиваете</p>
        <p className="mistake__subtitle">Возможно, запрашиваемая Вами страница была перенесена или удалена. Также возможно, Вы допустили небольшую опечатку при вводе адреса – такое случается даже с нами, поэтому еще раз внимательно проверьте или вернитесь на <Link className="mistake__link" to={AppRoute.Main.url}> главную.</Link></p>
      </div>
    </div>
  );
}

export default ErrorPage;
