import { RegionsState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { HdbkRegion } from "../../../api";

export const getRegionsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Regions].isRegionsLoading;
export const getRegionsErrorStatus = (
  state: State
): RegionsState["isRegionsError"] => state[NameSpace.Regions].isRegionsError;
export const getRegions = (state: State): HdbkRegion[] =>
  state[NameSpace.Regions].regions;
