import React from "react";
import Button from "../../ui/button/button";

function Tourney() {
  return (
    <div className="tourney">
      <img
        className="tourney__img"
        src="/images/dev/dev-340x130.png"
        width="340"
        height="130"
        alt="Фото турнира"
      />
      <div className="tourney__wrap">
        <svg className="tourney__background" width="710" height="288">
          <use xlinkHref="images/sprite.svg#background-logo" />
        </svg>
        <div className="tourney__block">
          <div className="tourney__content">
            <h3 className="tourney__title">Chekhov&apos;s Trophy</h3>
            <p className="tourney__subtitle">Россия, г. Москва</p>
          </div>
          <img
            className="tourney__logo"
            src="/images/default/tournament.svg"
            width="70"
            height="70"
            alt="Логотип турнира"
          />
        </div>
        <ul className="tourney__list">
          <li className="tourney__item">
            <p className="tourney__text">Клуб:</p>
            <p className="tourney__name">Клуб: Top spin</p>
          </li>
          <li className="tourney__item">
            <p className="tourney__text">Адрес:</p>
            <p className="tourney__name">Крылатская ул. 10</p>
          </li>
          <li className="tourney__item">
            <p className="tourney__text">Формат:</p>
            <p className="tourney__name">Optima</p>
          </li>
          <li className="tourney__item">
            <p className="tourney__text">Покрытие:</p>
            <p className="tourney__name">Хард</p>
          </li>
          <li className="tourney__item">
            <p className="tourney__text">Мяч:</p>
            <p className="tourney__name">Wilson</p>
          </li>
        </ul>
        <Button text="Подробнее" />
      </div>
    </div>
  );
}

export default Tourney;
