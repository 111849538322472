import {
  HdbkApi,
  HdbkGEOApi,
  InvoiceApi,
  LeagueApi,
  MatchApi,
  PlayerApi,
  RedactionApi,
  RefereeApi,
  StatsApi,
  TeamApi,
  TournamentApi,
  UserApi,
  VersionApi,
} from "../api";

export const api = {
  redaction: new RedactionApi(),
  version: new VersionApi(),
  hdbk: new HdbkApi(),
  hdbkGeo: new HdbkGEOApi(),
  invoice: new InvoiceApi(),
  league: new LeagueApi(),
  match: new MatchApi(),
  player: new PlayerApi(),
  referee: new RefereeApi(),
  team: new TeamApi(),
  tournament: new TournamentApi(),
  user: new UserApi(),
  stats: new StatsApi(),
};
