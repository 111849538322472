import React, { useEffect } from "react";
import Search from "../../ordinary/search/search";
import {
  transformRegionsToOptions,
  transformSeasonsToOptions,
} from "../../../core/utils/array-prepare-helpers";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getRegions } from "../../../core/store/regions-data/selectors";
import { getSeasons } from "../../../core/store/seasons-data/selectors";
import { fetchRegions, fetchSeasons } from "../../../core/store/api-actions";
import { DropdownTypes, ItemsForFilter } from "../../../core/constants/filters";
import Dropdown from "../dropdown/dropdown";
import Reset from "../reset/reset";

function FilterTournamentsItem() {
  const dispatch = useAppDispatch();
  const seasons = useAppSelector(getSeasons);
  const seasonsOptions = transformSeasonsToOptions(seasons);
  const regions = useAppSelector(getRegions);
  const regionsOptions = transformRegionsToOptions(regions);

  useEffect(() => {
    dispatch(fetchSeasons());
    dispatch(fetchRegions());
  }, [dispatch]);
  return (
    <>
      <li className="filter__item">
        <Dropdown
          options={seasonsOptions}
          type={DropdownTypes.Season}
          items={ItemsForFilter.Tournaments}
        />
      </li>
      <li className="filter__item">
        <Dropdown
          options={regionsOptions}
          type={DropdownTypes.Region}
          items={ItemsForFilter.Tournaments}
        />
      </li>
      <li className="filter__item">
        <Search items={ItemsForFilter.Tournaments}/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Tournaments} />
      </li>
    </>
  );
}

export default FilterTournamentsItem;
