import { createSlice } from '@reduxjs/toolkit'
import { CreateTeamState } from '../../types/state'
import { NameSpace } from '../../constants/api-constants'
import { createTeam } from '../api-actions'

const initialState: CreateTeamState = {
  CreateTeamLoading:false,
  createTeamStatus: undefined,
  CreateTeamErrors: [],
  CreateTeamDuplicates: [],
}

export const createTeamData = createSlice({
  name:NameSpace.CreateTeamTemps,
  initialState,
  reducers:{},
  extraReducers: (builder)=>{
    builder
    .addCase(createTeam.pending, (state) => {
      state.CreateTeamLoading = true;
    })
    .addCase(createTeam.fulfilled, (state, action) => {
      state.CreateTeamLoading = false;
      state.createTeamStatus = action.payload.success;
      state.CreateTeamErrors = action.payload.errors ?? [];
      state.CreateTeamDuplicates = action.payload.duplicates ?? [];
    })
    .addCase(createTeam.rejected, (state, action) => {
      state.CreateTeamLoading = false;
      state.CreateTeamErrors = [{message: action.error.message || 'Неизвестная ошибка'}]
    })
  }
})