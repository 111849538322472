import {PlayersRatingState} from "../../types/state"
import {NameSpace} from "../../constants/api-constants"
import {fetchPlayersRating} from "../api-actions"
import {
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit"
import {
  FilterOption,
  SorterOption
} from "../../types/common"

const initialState: PlayersRatingState = {
  playersRatingLoading: false,
  playersRatingError: false,
  playersRating: [],
  searchString: '',
  filterSeasonStatus: {
    value: "all",
    label: "все сезоны",
  },
  sorterStatus: {
    key: null,
    direction: 0
  }
}

export const playersRatingData = createSlice ({
  name: NameSpace.PlayersRating,
  initialState,
  reducers: {
    sortingPlayersRating: (
      state,
      action: PayloadAction<{ sorterStatus: SorterOption }>
    ) => {
      const {sorterStatus} = action.payload
      state.sorterStatus = sorterStatus
    },
    filteringPlayersRatingBySeason: (
      state,
      action: PayloadAction<{ filterSeasonStatus: FilterOption }>
    ) => {
      const {filterSeasonStatus} = action.payload
      state.filterSeasonStatus = filterSeasonStatus
    },
    filteringPlayersByString: (
      state,
      action: PayloadAction<string>
    ) => {state.searchString = action.payload},
    resetPlayersFilters: (state) => {
      state.searchString = initialState.searchString
      state.filterSeasonStatus = initialState.filterSeasonStatus
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayersRating.pending, (state) => {
        state.playersRatingLoading = true
      })
      .addCase(fetchPlayersRating.fulfilled, (state, action) => {
        state.playersRating = action.payload
        state.playersRatingLoading = false
      })
      .addCase(fetchPlayersRating.rejected, (state) => {
        state.playersRatingError = true
        state.playersRatingLoading = false
      })
  }
})

export const {
  sortingPlayersRating,
  filteringPlayersRatingBySeason,
  filteringPlayersByString,
  resetPlayersFilters
} = playersRatingData.actions
