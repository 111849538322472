import React, { useEffect } from "react";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import { AppRoute } from "../../core/constants/routes";
import ButtonsList from "../../components/ui/buttons-list/buttons-list";
import TournamentForm from "../../components/ordinary/tournament-form/tournament-form";
import ButtonForm from "../../components/ui/button-form/button-form";
import { registrationTournamentFormValidationSchema } from "../../core/utils/form-validate-helpers";
import {
  createTournament,
  fetchTournamentsTempsMine,
} from "../../core/store/api-actions";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppSelector,
  useFilteredButtons,
} from "../../core/hooks";
import { useParams } from "react-router-dom";
import { getAuthorizationUserId } from "../../core/store/authorization-data/selectors";
import { getTournamentTemps } from "../../core/store/tournaments-temps-data/selectors";
import Reason from "../../components/ordinary/reason/reason";
import RequestStatus from "../../components/simple/request-status/request-status";
import { getCities } from "../../core/store/cities-data/selectors";
import { getZones } from "../../core/store/zones-data/selectors";
import {
  transformCitiesToOptions,
  transformZonesToOptions,
} from "../../core/utils/array-prepare-helpers";

function RequestTournamentPage() {
  const dispatch = useAppDispatch();
  const { id: requestId } = useParams();
  const filteredButtons = useFilteredButtons();
  const userId = useAppSelector(getAuthorizationUserId);
  const tournamentTemps = useAppSelector(getTournamentTemps);
  const zones = useAppSelector(getZones);
  const cities = useAppSelector(getCities);

  const zonesOptions = React.useMemo(() => {
    return transformZonesToOptions(zones);
  }, [zones]);

  const citiesOptions = React.useMemo(() => {
    return transformCitiesToOptions(cities);
  }, [cities]);

  const temp = tournamentTemps.find(
    (tournament) => tournament.tournament_id === Number(requestId),
  );

  useEffect(() => {
    if (userId) {
      dispatch(fetchTournamentsTempsMine(Number(userId)));
      formik.setFieldValue("user_id", Number(userId));
    }
  }, [dispatch, userId]);

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.RegistrationTournamentPage,
    { title: temp?.full_name || "", url: "#" },
  ];

  const cityObject = citiesOptions.find((city) => city.value === temp?.city_id);
  const zoneObject = zonesOptions.find((zone) => zone.value === temp?.zone_id);

  const initialValues = {
    user_id: userId,
    full_name: temp?.full_name || "",
    short_name: temp?.short_name || "",
    club: temp?.club || "",
    club_address: temp?.club_address || "",
    start_dt: temp?.start_dt || "",
    end_dt: temp?.end_dt || "",
    city_id: cityObject || "",
    zone_id: zoneObject || "",
    court_coverage: temp?.court_coverage || "",
    official_ball: temp?.official_ball || "",
    contact_info: temp?.contact_info || "",
    is_draft: 0,
  };
  const validationSchema = registrationTournamentFormValidationSchema;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    console.log("Форма отправлена:", values);
    dispatch(createTournament(values));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleSaveDraft = () => {
    formik.setFieldValue("is_draft", 1);
    formik.handleSubmit();
  };

  const handleSaveAndSubmit = () => {
    formik.setFieldValue("is_draft", 0);
    formik.handleSubmit();
  };

  return (
    <div className="container">
      <div className="gap gap--20">
        <div className="gap gap--20">
          <CrumbsList routes={crumbsRoutes} />
          <div className="gap">
            <div className="gap gap--row gap--15">
              <div className="gap gap--50 gap--row-2">
                <Title title={temp?.full_name} className="title--uppercase" />
                <RequestStatus status={temp?.status} />
              </div>
              <ButtonsList buttonsData={filteredButtons} />
            </div>
            {temp?.comment && <Reason comment={temp.comment} />}
          </div>
          <div className="gap gap-40">
            <form className="background-form">
              <TournamentForm formik={formik} />
            </form>
            <div className="saves">
              <ButtonForm
                className="save--green"
                title="Сохранить"
                onClick={handleSaveDraft}
              />
              <ButtonForm
                title="Сохранить и отправить на проверку"
                onClick={handleSaveAndSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestTournamentPage;
