import React, { useEffect, useMemo, useState } from "react";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import ContentList from "../../components/ordinary/content-list/content-list";
import Pagination from "../../components/ordinary/pagination/pagination";
import { AppRoute } from "../../core/constants/routes";
import { fetchPosts } from "../../core/store/api-actions";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getPosts,
  getPostsErrorStatus,
  getPostsFilterSeasonStatus,
  getPostsLoadingStatus,
} from "../../core/store/posts-data/selectors";
import {
  filterMediaBySeason,
  sortItemsByPublicationDate,
} from "../../core/utils/array-prepare-helpers";
import { CONTENT_PER_PAGE } from "../../core/constants/common";
import FilterPagesList from "../../components/simple/filter-pages-list/filter-pages-list";
import { getSeasonsLoadingStatus } from "../../core/store/seasons-data/selectors";
import Stub from "../../components/ordinary/stub/stub";

function PostsPage() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.PostsPage];
  const posts = useAppSelector(getPosts);
  const postsErrorStatus = useAppSelector(getPostsErrorStatus);
  const postsLoadingStatus = useAppSelector(getPostsLoadingStatus);
  const filterSeasonStatus = useAppSelector(getPostsFilterSeasonStatus);
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  const createProcessedPosts = useMemo(() => {
    let processedPosts = posts;
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      processedPosts = filterMediaBySeason(
        processedPosts,
        filterSeasonStatus.label
      );
    }
    return sortItemsByPublicationDate(processedPosts);
  }, [posts, filterSeasonStatus]);

  const [currentPage, setCurrentPage] = useState(0);
  const postsPerPage = CONTENT_PER_PAGE;
  const pageCount = Math.ceil(
    (createProcessedPosts?.length || 0) / postsPerPage
  );
  const indexOfLastPost = (currentPage + 1) * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts
    ? createProcessedPosts.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const postsPageLoading = postsLoadingStatus || seasonsLoading;

  return (
    <div className="container-y">
      <div className={`gap gap--20 ${postsPageLoading ? "hidden" : ""}`}>
        <div className="container-x">
          <Title title="Новости" />
          <div className="gap gap--20">
            <CrumbsList routes={crumbsRoutes} />
            <div className="gap gap--row">
              <Title title="Новости" />
              <FilterPagesList />
            </div>
          </div>
        </div>
        <Stub
          type={
            postsErrorStatus
              ? "error"
              : posts.length > 0 && createProcessedPosts.length === 0
              ? "searchError"
              : posts.length === 0
              ? "absence"
              : ""
          }
          error={
            postsErrorStatus ? "Произошла ошибка при загрузке контента." : ""
          }
        />
        <div className="gap gap--40">
          <ContentList items={currentPosts} />
          {currentPage > 1 && (
            <Pagination
              className="pagination--arrows"
              pageCount={pageCount}
              forcePage={currentPage}
              onPageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PostsPage;
