import React, { useEffect, useMemo, useState } from "react";
import CrumbsList from "../../components/ordinary/crumbs-list/crumbs-list";
import Title from "../../components/ui/title/title";
import FilterLeagueList from "../../components/ordinary/filter-league-list/filter-league-list";
import LeagueList from "../../components/ordinary/league-list/league-list";
import Pagination from "../../components/ordinary/pagination/pagination";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { AppRoute } from "../../core/constants/routes";
import { fetchLeagues } from "../../core/store/api-actions";
import {
  filterItemsByRegion,
  filterItemsBySeason,
  filterItemsByStage,
  filterLeaguesByZone,
  filterRegularLeagues,
  sortItemsByStartDate,
} from "../../core/utils/array-prepare-helpers";
import { LEAGUES_PER_PAGE } from "../../core/constants/common";
import {
  getLeagues,
  getLeaguesErrorStatus,
  getLeaguesFilterRegionStatus,
  getLeaguesFilterSeasonStatus,
  getLeaguesFilterStageStatus,
  getLeaguesLoadingStatus,
} from "../../core/store/leagues-data/selectors";
import { getSeasonsLoadingStatus } from "../../core/store/seasons-data/selectors";
import { getRegionsLoadingStatus } from "../../core/store/regions-data/selectors";

function LeaguePage() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.LeaguePage];
  const leagues = useAppSelector(getLeagues);
  const leaguesErrorStatus = useAppSelector(getLeaguesErrorStatus);
  const filterSeasonStatus = useAppSelector(getLeaguesFilterSeasonStatus);
  const filterRegionStatus = useAppSelector(getLeaguesFilterRegionStatus);
  const filterStageStatus = useAppSelector(getLeaguesFilterStageStatus);

  useEffect(() => {
    dispatch(fetchLeagues());
  }, [dispatch]);

  const filteredLeagues = filterRegularLeagues(leagues);
  const sortedLeagues = sortItemsByStartDate(filteredLeagues);
  const leaguesByZone = filterLeaguesByZone(sortedLeagues);

  const createProcessedLeagues = useMemo(() => {
    let processedLeagues = leaguesByZone;
    const regularLeagues = filteredLeagues;
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      processedLeagues = filterItemsBySeason(
        regularLeagues,
        filterSeasonStatus.label,
      );
    }
    if (filterRegionStatus?.label && filterRegionStatus.value !== "all") {
      processedLeagues = filterItemsByRegion(
        regularLeagues,
        filterRegionStatus.label,
      );
    }
    if (filterStageStatus?.label && filterStageStatus.value !== "all") {
      processedLeagues = filterItemsByStage(
        regularLeagues,
        filterStageStatus.label,
      );
    }
    return processedLeagues;
  }, [
    sortedLeagues,
    filterSeasonStatus,
    filterRegionStatus,
    filterStageStatus,
  ]);

  const leaguesPerPage = LEAGUES_PER_PAGE;
  const pageCount = Math.ceil((filteredLeagues?.length || 0) / leaguesPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastLeague = (currentPage + 1) * leaguesPerPage;
  const indexOfFirstLeague = indexOfLastLeague - leaguesPerPage;
  const currentLeagues = sortedLeagues
    ? createProcessedLeagues.slice(indexOfFirstLeague, indexOfLastLeague)
    : [];
  const handlePageClick = (selectedPage: { selected: number }) => {
    if (selectedPage.selected >= 0 && selectedPage.selected < pageCount) {
      setCurrentPage(selectedPage.selected);
    }
  };

  const leaguesLoading = useAppSelector(getLeaguesLoadingStatus);
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus);
  const regionsLoading = useAppSelector(getRegionsLoadingStatus);
  const leaguesPageLoading = leaguesLoading || seasonsLoading || regionsLoading;

  return (
    <div className="container">
      <div className={`gap gap--40 ${leaguesPageLoading ? "hidden" : ""}`}>
        <div className="gap">
          <div className="gap gap--25">
            <CrumbsList routes={crumbsRoutes} />
            <div className="gap gap--20">
              <Title className="title--uppercase" title="Регулярная лига" />
              <div className="gap gap--0">
                <FilterLeagueList
                  leagues={leagues}
                  leaguesErrorStatus={leaguesErrorStatus}
                  filteredLeagues={createProcessedLeagues}
                />
              </div>
            </div>
          </div>
          <LeagueList leagues={currentLeagues} />
        </div>
        {pageCount > 1 && (
          <Pagination
            pageCount={pageCount}
            forcePage={currentPage}
            onPageClick={handlePageClick}
          />
        )}
      </div>
    </div>
  );
}

export default LeaguePage;
