import React from 'react';
import MatchItem from "../match-item/match-item";
import {MatchSeries} from "../../../api";

function MatchList({seriesArray}: { seriesArray: MatchSeries[] }) {
  return (
    <ul className="match__list">
      {seriesArray.map((series) => (
        <MatchItem key={series.series_id} series={series}/>
      ))}
    </ul>
  );
}

export default MatchList;
