import {DesireRolesState} from "../../types/state";
import {createSlice} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {fetchDesireRoles} from "../api-actions";

const initialState: DesireRolesState = {
  desireRolesLoading: false,
  desireRolesError: false,
  desireRoles: [],
};

export const desireRolesData = createSlice({
  name: NameSpace.DesireRoles,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDesireRoles.pending, (state) => {
        state.desireRolesLoading = true;
      })
      .addCase(fetchDesireRoles.fulfilled, (state, action) => {
        state.desireRoles = action.payload;
        state.desireRolesLoading = false;
      })
      .addCase(fetchDesireRoles.rejected, (state) => {
        state.desireRolesError = true;
        state.desireRolesLoading = false;
      })
  }
});