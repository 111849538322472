import React from "react";
import {Link} from "react-router-dom";
import {HdbkDocument} from "../../../api";

function DocumentsItem({document}: { document: HdbkDocument }) {
  return (
    <li className="documents__item">
      <Link className="documents__link" to={document._links?.file?.href || "#"}>
        <svg
          className="documents__icon documents__green"
          width="40"
          height="40"
        >
          <use xlinkHref="images/sprite.svg#pdf-green"/>
        </svg>
        <svg
          className="documents__icon documents__black"
          width="40"
          height="40"
        >
          <use xlinkHref="images/sprite.svg#pdf-black"/>
        </svg>
        <div className="documents__texts">
          <p className="documents__text">{document.title}</p>
          <p className="documents__down">открыть</p>
        </div>
      </Link>
    </li>
  );
}

export default DocumentsItem;
