import {RoundTablesInnerState} from "../../types/state"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {NameSpace} from "../../constants/api-constants"
import {SorterOption} from "../../types/common"

const initialState: RoundTablesInnerState = {
  sorterStatus: {
    key: null,
    direction: 0
  }
}

export const roundTablesInnerData = createSlice ({
  name: NameSpace.RoundTablesInner,
  initialState,
  reducers: {
    sortingRoundTablesInner: (
      state,
      action: PayloadAction<{ sorterStatus: SorterOption }>
    ) => {
      const {sorterStatus} = action.payload
      state.sorterStatus = sorterStatus
    }
  }
})

export const {sortingRoundTablesInner} = roundTablesInnerData.actions
