import * as Yup from "yup";

const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d).+$/;

export const loginFormValidationSchema = Yup.object({
  email: Yup.string()
    .required("Email не может быть пустым")
    .email("Введите корректный email"),
  password: Yup.string().required("Пароль не может быть пустым"),
});

export const registrationFormValidationSchema = Yup.object({
  firstName: Yup.string().required("Имя не может быть пустым"),
  lastName: Yup.string().required("Фамилия не может быть пустым"),
  email: Yup.string()
    .email("Введите корректный email")
    .required("Email не может быть пустым"),
  phone: Yup.string().required("Телефон не может быть пустым"),
  password: Yup.string()
    .min(8, "Пароль должен быть больше 8 символов")
    .matches(
      passwordRegExp,
      "Пароль должен включать цифру и только латинские буквы",
    )
    .required("Пароль не может быть пустым"),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref("password")], "Пароли должны совпадать")
    .min(8, "Пароль должен быть больше 8 символов")
    .matches(
      passwordRegExp,
      "Пароль должен включать цифру и только латинские буквы",
    )
    .required("Пароль не может быть пустым"),
  reCaptcha: Yup.string().required("reCAPTCHA верификация обязательна"),
});

export function validateSearchForm(searchString: string): {
  [key: string]: string;
} {
  const newErrors: { [key: string]: string } = {};
  const searchStringFilled = searchString !== null && searchString !== "";
  if (!searchStringFilled) {
    newErrors["search"] = "Поисковой запрос не может быть пустым";
  }
  return newErrors;
}

export const registrationCommandFormValidationSchema = Yup.object().shape({
  full_name: Yup.string()
    .required("Полное название команды обязательно")
    .max(64, "Полное название команды должно быть не более 64 символов"),
  city_id: Yup.object().required('Выбор города обязателен'),
  club: Yup.string()
    .required("Название клуба обязательно")
    .max(64, "Название клуба должно быть не более 64 символов"),
  club_address: Yup.string()
    .required("Адрес клуба обязателен")
    .max(255, "Адрес клуба должен быть не более 255 символов"),
  zone_id: Yup.object().required('Выбор зоны обязателен'),
  court_coverage: Yup.string()
    .required("Покрытие корта обязательно")
    .max(64, "Покрытие корта должно быть не более 64 символов"),
  players: Yup.array()
    .of(
      Yup.object({
        first_name: Yup.string()
          .required("Имя игрока обязательно")
          .max(64, "Имя игрока должно быть не более 64 символов"),
        last_name: Yup.string()
          .required("Фамилия игрока обязательна")
          .max(64, "Фамилия игрока должна быть не более 64 символов"),
        birthday: Yup.date()
        .required("Дата рождения обязательна")
        .max(new Date(), "Дата рождения не может быть в будущем")
          .test('age', 'Игрок должен быть старше 16 лет', function(value) {
            const today = new Date();
            const birthDate = new Date(value);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }
            return age >= 16;
          }),
        gender: Yup.object().required('Укажите пол игрока'),
        ntrp: Yup.string().max(
          10,
          "Рейтинг NTRP должен быть не более 10 символов",
        ),
      }),
    )
    .min(1, "В команде должно быть минимум 4 игрока")
    .max(15, "В команде не может быть больше 15 игроков"),
});

export const registrationTournamentFormValidationSchema = Yup.object().shape({
  full_name: Yup.string()
    .required("Полное название обязательно")
    .max(64, "Полное название должно быть не более 64 символов"),
  short_name: Yup.string()
    .required("Короткое название обязательно")
    .max(10, "Короткое название должно быть не более 10 символов"),
  club: Yup.string()
    .required("Название клуба обязательно")
    .max(64, "Название клуба должно быть не более 64 символов"),
  club_address: Yup.string()
    .required("Адрес клуба обязателен")
    .max(255, "Адрес клуба должен быть не более 255 символов"),
  start_dt:Yup.date().required("Дата начала турнира обязательна"),
  end_dt:Yup.date().required("Дата окончания турнира обязательна"),
  city_id:Yup.object().required("Выбор города обязателен"),
  zone_id:Yup.object().required("Выбор зоны обязателен"),
  court_coverage: Yup.string()
    .max(64, "Покрытие корта должно быть не более 64 символов"),
  official_ball: Yup.string()
    .max(64, "Название мяча должно быть не более 64 символов"),
  contact_info: Yup.string()
    .max(255, "Контактная информация должна быть не более 255 символов"),
});