import React from "react";
import { Link } from "react-router-dom";
import Share from "../share/share";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { MatchSeries } from "../../../api";
import { tournamentTypes } from "../../../core/constants/common";

function MatchesHead({ series }: { series: MatchSeries | null }) {
  const startDateTime = series?.start_dt ? new Date(series.start_dt) : "";
  const startDate = startDateTime
    ? simpleDateFormat(startDateTime, "dd MMMM yyyy")
    : "дата не указана";

  return (
    <div className="matches">
      <div className="matches__wrapper">
        <svg className="matches__background" width="2000" height="850">
          <use xlinkHref="images/sprite.svg#background-logo" />
        </svg>
        <div className="matches__container">
          <div className="matches__content">
            <div className="matches__block">
              <svg className="matches__icon" width="20" height="20">
                <use xlinkHref="images/sprite.svg#calendar" />
              </svg>
              <p className="matches__data">{startDate}</p>
            </div>
            <div className="matches__block">
              <svg className="matches__icon" width="15" height="15">
                <use xlinkHref="images/sprite.svg#location" />
              </svg>
              <p className="matches__data">
                {series?.club_address || "адрес не указан"}
              </p>
            </div>
          </div>
          <div className="matches__wrap">
            <Link className="matches__player" to="#">
              <img
                className="matches__img"
                width="140"
                height="140"
                src={
                  series?.team1?.avatar.includes("logo_placeholder_100x100.svg")
                    ? "/images/default/team.svg"
                    : series?.team1?.avatar
                }
                alt={series?.team1?.full_name}
              />
              <p className="matches__name">{series?.team1?.full_name}</p>
            </Link>
            <div className="matches__scores">
              <p className="matches__number">
                {series?.score1 === null ? "-" : series?.score1}
              </p>
              <p className="matches__colon">:</p>
              <p className="matches__number">
                {series?.score2 === null ? "-" : series?.score2}
              </p>
            </div>
            <Link className="matches__player matches__player--right" to="#">
              <img
                className="matches__img"
                width="140"
                height="140"
                src={
                  series?.team2?.avatar.includes("logo_placeholder_100x100.svg")
                    ? "/images/default/team.svg"
                    : series?.team2?.avatar
                }
                alt={series?.team2?.full_name}
              />
              <p className="matches__name">{series?.team2?.full_name}</p>
            </Link>
          </div>
          <div className="matches__content">
            <p className="matches__title">{series?.tournament?.full_name}</p>
            <p className="matches__subtitle">
              {tournamentTypes[series?.tournament?.type || "undefined"]}
            </p>
          </div>
        </div>
      </div>
      <Share />
    </div>
  );
}

export default MatchesHead;
