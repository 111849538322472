import {combineReducers} from "@reduxjs/toolkit"
import {NameSpace} from "../constants/api-constants"
import {playersData} from "./players-data/players-data"
import {teamsData} from "./teams-data/teams-data"
import {postsData} from "./posts-data/posts-data"
import {albumsData} from "./albums-data/albums-data"
import {videosData} from "./videos-data/videos-data"
import {bannersData} from "./banners-data/banners-data"
import {pagesData} from "./pages-data/pages-data"
import {socialsData} from "./socials-data/socials-data"
import {tournamentsData} from "./tournaments-data/tournaments-data"
import {leaguesData} from "./leagues-data/leagues-data"
import {seasonsData} from "./seasons-data/seasons-data"
import {regionsData} from "./regions-data/regions-data"
import {faqsData} from "./faq-data/faq-data"
import {provisionsData} from "./basic-provisions-data/basic-provisions-data"
import {roundsData} from "./rounds-data/rounds-data"
import {matchesData} from "./matches-data/matches-data"
import {matchSeriesData} from "./match-series-data/match-series-data"
import {teamPlayersData} from "./team-players-data/team-players-data"
import {authorizationData} from "./authorization-data/authorization-data"
import {usersData} from "./users-data/users-data"
import {desireRolesData} from "./desire-roles-data/desire-roles-data"
import { citiesData } from "./cities-data/cities-data"
import { zonesData } from "./zones-data/zones-data"
import { tournamentsTempsData } from "./tournaments-temps-data/tournaments-temps-data"
import { teamsTempsData } from "./teams-temps-data/teams-temps-data"
import {modalsData} from "./modals-data/modals-data"
import {calendarData} from "./calendar-data/calendar-data";
import {teamMatchSeriesData} from "./team-match-series-data/team-match-series-data";
import {teamsRatingData} from "./teams-rating-data/teams-rating-data";
import {playersRatingData} from "./players-rating-data/players-rating-data";
import {playersStatsData} from "./players-stats-data/players-stats-data";
import {playerMatchesData} from "./player-matches-data/player-matches-data";
import {teamsStatsData} from "./teams-stats-data/teams-stats-data";
import {roundTablesInnerData} from "./round-tables-inner-data/round-tables-inner-data";
import {playerTeamsData} from "./player-teams-data/player-teams-data"
import {playerInfoData} from "./player-info-data/player-info-data"
import { createTeamData } from './create-team-data/create-team-data'
import { createTournamentData } from './create-tournament-data/create-tournament-data'

export const rootReducer = combineReducers({
  [NameSpace.Players]: playersData.reducer,
  [NameSpace.Teams]: teamsData.reducer,
  [NameSpace.Posts]: postsData.reducer,
  [NameSpace.Pages]: pagesData.reducer,
  [NameSpace.Albums]: albumsData.reducer,
  [NameSpace.Videos]: videosData.reducer,
  [NameSpace.Banners]: bannersData.reducer,
  [NameSpace.Socials]: socialsData.reducer,
  [NameSpace.Tournaments]: tournamentsData.reducer,
  [NameSpace.Leagues]: leaguesData.reducer,
  [NameSpace.Seasons]: seasonsData.reducer,
  [NameSpace.Regions]: regionsData.reducer,
  [NameSpace.Faqs]: faqsData.reducer,
  [NameSpace.Provisions]: provisionsData.reducer,
  [NameSpace.Rounds]: roundsData.reducer,
  [NameSpace.Matches]: matchesData.reducer,
  [NameSpace.MatchSeries]: matchSeriesData.reducer,
  [NameSpace.TeamPlayers]: teamPlayersData.reducer,
  [NameSpace.Authorization]: authorizationData.reducer,
  [NameSpace.Users]: usersData.reducer,
  [NameSpace.DesireRoles]: desireRolesData.reducer,
  [NameSpace.Cities]: citiesData.reducer,
  [NameSpace.Zones]: zonesData.reducer,
  [NameSpace.TournamentsTemps]: tournamentsTempsData.reducer,
  [NameSpace.TeamsTemps]: teamsTempsData.reducer,
  [NameSpace.Modals]: modalsData.reducer,
  [NameSpace.Calendar]: calendarData.reducer,
  [NameSpace.TeamMatchSeries]: teamMatchSeriesData.reducer,
  [NameSpace.PlayerMatches]: playerMatchesData.reducer,
  [NameSpace.TeamsRating]: teamsRatingData.reducer,
  [NameSpace.PlayersRating]: playersRatingData.reducer,
  [NameSpace.PlayersStats]: playersStatsData.reducer,
  [NameSpace.TeamsStats]: teamsStatsData.reducer,
  [NameSpace.RoundTablesInner]: roundTablesInnerData.reducer,
  [NameSpace.PlayerTeams]: playerTeamsData.reducer,
  [NameSpace.PlayerInfo]: playerInfoData.reducer,
  [NameSpace.CreateTeamTemps]: createTeamData.reducer,
  [NameSpace.CreateTournamentTemps]: createTournamentData.reducer,
})
