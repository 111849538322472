import React from "react";
import { TournamentTemp, TeamTemp } from "../../../api";
import RequestApplicationItem from '../request-application-item/request-application-item'

function RequestApplicationList({
  temps,
  type,
}: {
  temps: (TournamentTemp | TeamTemp)[];
  type: "tournament" | "team";
}) {
  return (
    <ul className='gap gap--20'>
      {temps.map((temp, index) => (
        <li key={index}>
          <RequestApplicationItem temp={temp} type={type} />
        </li>
      ))}
    </ul>
  );
}

export default RequestApplicationList;
