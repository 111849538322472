import { createSlice } from '@reduxjs/toolkit'
import { TeamTempsState } from '../../types/state'
import { NameSpace } from '../../constants/api-constants'
import { fetchTeamsRequestsMine } from '../api-actions'

const initialState:TeamTempsState = {
  isTeamTempsLoading: false,
  isTeamTempsError: false,
  teamTemps:[] ,
}

export const teamsTempsData = createSlice({
  name:NameSpace.TeamsTemps,
  initialState,
  reducers:{

  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsRequestsMine.pending, (state) => {
        state.isTeamTempsError = true;
      })
      .addCase(fetchTeamsRequestsMine.fulfilled, (state, action) => {
        state.teamTemps = action.payload;
        state.isTeamTempsLoading = false;
      })
      .addCase(fetchTeamsRequestsMine.rejected, (state) => {
        state.isTeamTempsError = true;
        state.isTeamTempsLoading = false;
      });
    },
})