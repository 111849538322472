import {UsersState} from "../../types/state";
import {createSlice} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {fetchUser} from "../api-actions";

const initialState: UsersState = {
  userLoading: false,
  userError: false,
  user: null
};

export const usersData = createSlice({
  name: NameSpace.Users,
  initialState,
  reducers: {
    resetUser: (state) => {
      state.userLoading = initialState.userLoading;
      state.userError = initialState.userError;
      state.user = initialState.user;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userLoading = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.userError = true;
        state.userLoading = false;
      });
  }
});

export const {resetUser} = usersData.actions;