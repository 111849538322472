import {  ZonesState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { HdbkZone } from '../../../api'

export const getZonesLoadingStatus = (state: State): boolean =>
  state[NameSpace.Zones].isZonesLoading;
export const getZonesErrorStatus = (
  state: State
): ZonesState["isZonesError"] => state[NameSpace.Zones].isZonesError;
export const getZones = (state: State): HdbkZone[] =>
  state[NameSpace.Zones].zones;
