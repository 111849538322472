import React, {useEffect} from "react";
import {RoundPlayoffHtml} from "../../../api";
import $ from "jquery"
import "./../../../assets/css/playoff.css";
import "./../../../assets/js/playoff"

const Grids = ({ roundPlayoffHtml }: { roundPlayoffHtml?: RoundPlayoffHtml }) => {
  // eslint-disable-next-line
  const playoff = ($ as any).playoff
  useEffect(() => {
    if (playoff && typeof playoff.destroy === 'function') {
      playoff.destroy();
    }
    playoff.init();
    return () => {
      if (playoff && typeof playoff.destroy === 'function') {
        playoff.destroy();
      }
    };
  }, [roundPlayoffHtml]);

  return (
    <div className="gap gap--40">
      <div dangerouslySetInnerHTML={{ __html: roundPlayoffHtml?.html || '' }} />
    </div>
  );
};

export default Grids;
