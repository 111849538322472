import React from "react"
import {TeamPlayer} from "../../../api"
import {simpleDateFormat} from "../../../core/utils/date-time-helpers"
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes"

function HistoryStatsRow({playerTeam}: {playerTeam: TeamPlayer}) {
  const inDate = playerTeam.in
    ? simpleDateFormat(new Date(playerTeam.in), "dd.MM.yyyy")
    : null
  const outDate = playerTeam.out
    ? simpleDateFormat(new Date(playerTeam.out), "dd.MM.yyyy")
    : null

  return (
    <tr className="table__row table__row--border">
      <td className="table__cell">
        <Link
          className="table__link table__start"
          to={`${AppRoute.CommandCalendarPage.url}/${playerTeam.team_id}`}
        >
          <img
            className="table__img"
            src={
              playerTeam.team?.avatar.includes("logo_placeholder_100x100.svg")
                ? "/images/default/team.svg"
                : playerTeam.team?.avatar
            }
            width="25"
            height="25"
            alt={playerTeam.team?.full_name}
          />
          <p className="table__name">{playerTeam.team?.full_name}</p>
        </Link>
      </td>
      <td className="table__cell">
        <span className={`table__span ${inDate ? '' : 'hidden'}`}>{`c ${inDate}`}</span>
        <span className={`table__span ${outDate ? '' : 'hidden'}`}>{` по ${outDate}`}</span>
      </td>
    </tr>
  )
}

export default HistoryStatsRow
