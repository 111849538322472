import {TeamPlayersState} from "../../types/state";
import {createSlice,} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {fetchTeamPlayers} from "../api-actions";

const initialState: TeamPlayersState = {
  isTeamPlayersLoading: false,
  isTeamPlayersError: false,
  teamPlayers: [],
};

export const teamPlayersData = createSlice({
  name: NameSpace.TeamPlayers,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamPlayers.pending, (state) => {
        state.isTeamPlayersLoading = true;
      })
      .addCase(fetchTeamPlayers.fulfilled, (state, action) => {
        state.teamPlayers = action.payload;
        state.isTeamPlayersLoading = false;
      })
      .addCase(fetchTeamPlayers.rejected, (state) => {
        state.isTeamPlayersError = true;
        state.isTeamPlayersLoading = false;
      })
  }
});