import React, {useEffect} from "react"
import CommandList from "../command-list/command-list"
import {
  fetchTeam,
  fetchTeamPlayers
} from "../../../core/store/api-actions"
import {
  useAppDispatch, useAppSelector
} from "../../../core/hooks"
import {useParams} from "react-router-dom"
import {getTeam} from "../../../core/store/teams-data/selectors"
import {getTeamPlayers} from "../../../core/store/team-players-data/selectors"
import {getCaptain} from "../../../core/utils/array-prepare-helpers"

function CommandsItem() {
  const dispatch = useAppDispatch()
  const {id: teamId} = useParams()
  const team = useAppSelector(getTeam)
  const teamPlayers = useAppSelector(getTeamPlayers)
  const captain = getCaptain(teamPlayers)

  useEffect(() => {
    dispatch(fetchTeam(Number(teamId)))
    dispatch(fetchTeamPlayers(Number(teamId)))
  }, [dispatch])

  return (
    <div className="background background--command">
      <div className="background__wrapper">
        <svg className="background__logo" width="1295" height="745">
          <use xlinkHref="images/sprite.svg#background-logo-big" />
        </svg>
      </div>
      <div className="command">
        <h2 className="command__title">{team?.full_name}</h2>
        <div className="command__wrap">
          <img
            className="command__img"
            src={
              team?.avatar.includes("logo_placeholder_100x100.svg")
                ? "/images/default/team.svg"
                : team?.avatar
            }
            width="150"
            height="150"
            alt={team?.full_name}
          />
          <CommandList team={team} captain={captain} />
          <div className="command__block">
            <div className="command__content">
              <svg className="command__icon" width="36" height="36">
                <use xlinkHref="images/sprite.svg#ball" />
              </svg>
              <div className="command__texts">
                <p className="command__number">-</p>
                <p className="command__subtitle">игры</p>
              </div>
            </div>
            <div className="command__content">
              <svg className="command__icon" width="36" height="36">
                <use xlinkHref="images/sprite.svg#star" />
              </svg>
              <div className="command__texts">
                <p className="command__number">-</p>
                <p className="command__subtitle">победы в сезоне</p>
              </div>
            </div>
            <div className="command__content">
              <svg className="command__icon" width="36" height="36">
                <use xlinkHref="images/sprite.svg#cup" />
              </svg>
              <div className="command__texts">
                <p className="command__number">-</p>
                <p className="command__subtitle"> победы в регионе</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommandsItem
