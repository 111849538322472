import React from "react";
import {Link} from "react-router-dom";
import {FooterDocumentType} from "../../../core/types/common";

function FooterItem({footerLinkData}: { footerLinkData: FooterDocumentType }) {
  return (
    <li className="footer__item">
      <Link className="footer__link" to={footerLinkData.url}>{footerLinkData.title}</Link>
    </li>
  );
}

export default FooterItem;
