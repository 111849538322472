import React from "react";
import FooterItem from "../../ordinary/footer-item/footer-item";
import { FooterDocumentType } from "../../../core/types/common";

function FooterList({
  footerLinksData,
}: {
  footerLinksData: FooterDocumentType[];
}) {
  return (
    <ul className="footer__list">
      {footerLinksData.map((footerLinkData, index) => (
        <FooterItem
          footerLinkData={footerLinkData}
          key={`${footerLinkData.url}-${index}`}
        />
      ))}
    </ul>
  );
}

export default FooterList;
