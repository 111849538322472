import {PlayerInfoState} from "../../types/state"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {NameSpace} from "../../constants/api-constants"
import {FilterOption} from "../../types/common"

const initialState: PlayerInfoState = {
  filterSeasonStatus: {
    value: "all",
    label: "все сезоны"
  }
}

export const playerInfoData = createSlice({
  name: NameSpace.PlayerInfo,
  initialState,
  reducers: {
    filteringPlayerInfoBySeason: (
      state,
      action: PayloadAction<{ filterSeasonStatus: FilterOption }>
    ) => {
      const {filterSeasonStatus} = action.payload
      state.filterSeasonStatus = filterSeasonStatus
    }
  }
})

export const {filteringPlayerInfoBySeason} = playerInfoData.actions
