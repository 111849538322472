import React, { useEffect } from "react";
import Layout from "./components/smart/layout/layout";
import RoutesComponent from "./core/routes/routes";
import "overlayscrollbars/styles/overlayscrollbars.css";
import "./assets/css/custom-fancybox.css";
import "./assets/css/redactor-styles.css";
import "./assets/css/styles.css";
import "./assets/css/styles-1180.css";
import "./assets/css/styles-780.css";
import "./assets/css/styles-480.css";
import "./assets/css/crop.css";
import { useAppDispatch } from "./core/hooks";
import { setLogin } from "./core/store/authorization-data/authorization-data";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storedToken =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const storedUserId =
      localStorage.getItem("userId") || sessionStorage.getItem("userId");
    const storedUserRole =
      localStorage.getItem("userRole") || sessionStorage.getItem("userRole");

    if (storedToken && storedUserId && storedUserRole !== null) {
      dispatch(
        setLogin({
          token: storedToken,
          userId: storedUserId,
          userRole: storedUserRole,
        })
      );
    }
  }, [dispatch]);

  return (
    <Layout>
      <RoutesComponent />
    </Layout>
  );
}

export default App;
