import React from "react";
import { FilterOption } from "../../../core/types/common";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  SelectPlaceholders,
  SelectTypes,
} from "../../../core/constants/selects";

function FormSelect({
  options,
  type,
  formik,
  value,
  name,
}: {
  options: FilterOption[];
  type: string;
  // eslint-disable-next-line
  formik: any;
  value?: FilterOption;
  name: string;
}) {
  const createPlaceholder = (type: string) => {
    switch (type) {
      case SelectTypes.DesireRoles:
        return SelectPlaceholders.DesireRoles;
      case SelectTypes.Cities:
        return SelectPlaceholders.Cities;
      case SelectTypes.Zones:
        return SelectPlaceholders.Zones;
      case SelectTypes.Gender:
        return SelectPlaceholders.Gender;
      default:
        SelectTypes.Undefined;
    }
  };

  const handleSelectChange = (
    selectedOption: SingleValue<FilterOption>,
    actionMeta: ActionMeta<FilterOption>
  ) => {
    const event = {
      target: {
        name: actionMeta.name,
        value: selectedOption ? selectedOption : null,
      },
    };
    formik.handleChange(event);
  };

  return (
    <Select
      options={options}
      isSearchable={false}
      placeholder={createPlaceholder(type)}
      onChange={(selectedOption, actionMeta) =>
        handleSelectChange(selectedOption, actionMeta)
      }
      classNamePrefix="select"
      className="select select--form"
      value={value}
      name={name}
      defaultValue={value}
    />
  );
}

export default FormSelect;
