import React from "react";
import ContentItem from "../content-item/content-item";
import { Album, Post, Video } from "../../../api";

function ContentList({
  items,
  className,
}: {
  items: Video[] | Album[] | Post[];
  className?: string;
}) {
  return (
    <ul className={`content ${className}`}>
      {items.map((item, index) => (
        <ContentItem key={index} data={item} />
      ))}
    </ul>
  );
}

export default ContentList;
